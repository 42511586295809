import { makeStyles, TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { useEffect, useState } from 'react';
import {
    AutocompleteInput,
    NumberInput,
    ReferenceInput,
    required,
    SelectInput,
    useMutation,
    useNotify,
    useRefresh,
    useTranslate,
} from 'react-admin';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { METADATA_AVATAR_TYPES, TRACEABILITY_NOTES_THRESHOLD_EXECUTIONS } from '../../../../../providers/resources';
import { selectThresholds } from '../../../../../redux/actions/thresholds';
import { CustomReducerState } from '../../../../../redux/reducers';
import { ThresholdsFormStyles } from '../../../../../theme/customTheme';
import { getNameWithoutPrefixFromJson } from '../../../../../utils/resources/MetadataAvatarType';
import { MAINTENANCE, SLUDGE_EMPTYING } from '../../../../../utils/rikutec';
import {
    convertDaysValuesInMonth,
    DURATION_TYPE_DAY,
    DURATION_TYPE_MONTH,
} from '../../../../../utils/threshold/duractionType';
import {
    extractThresholdValues,
    InputManagementThreshold,
    thresholdColorField,
    thresholdDurationTypeField,
    thresholdMaxValueField,
    thresholdMinValueField,
    thresholdNameField,
    thresholdOperationTypeField,
} from '../../../../../utils/threshold/grouping';
import { getColorByLevel, ThresholdsColorReferenceByNameType } from '../../../../../utils/threshold/setPropertyByLevel';
import { TraceabilityNotesThresholdExecutions } from '../../../../../utils/types';
import {
    getCodeFromViolationMessage,
    getViolations,
    ViolationResolved,
} from '../../../../../utils/validations/parseValidations';
import { ButtonSubmit } from '../../../../forms/buttonSubmit';
import TypographyFormSubtitle from '../../../../forms/typographyFormSubtitle';
import TypographyFormTitle from '../../../../forms/typographyFormTitle';
import { thresholdsNameChoices } from '../util';

// @ts-ignore
const useStyles = makeStyles(() => ({
    ...ThresholdsFormStyles,
}));

type ManagementThresholdsFormProps = {
    open: boolean;
    handleClose: () => void;
};

const LIMIT_ADDING = 3;

const validationRules = (value: any, translate: any) => {
    const errors = {};
    const values = Object.entries(value);
    const minValues = values
        .filter((item) => item[0].includes('min'))
        .map((item) => [item[0] as string, item[1] as number]);
    const maxValues = values
        .filter((item) => item[0].includes('max'))
        .map((item) => [item[0] as string, item[1] as number]);
    minValues.forEach((item, index) => {
        if (index > 0) {
            const refVal = maxValues[index - 1]?.[1];
            Object.assign(errors, {
                [`min_${index}`]:
                    typeof refVal === 'number' && item[1] > refVal
                        ? undefined
                        : translate('app.ancs.error.minMustExceedPreviousMax'),
            });
        } else {
            if (typeof item[1] === 'number') {
                Object.assign(errors, {
                    [`min_${index}`]: item[1] >= 0 ? undefined : translate('app.ancs.error.greaterThanZero'),
                });
            } else {
                Object.assign(errors, {
                    [`min_${index}`]: translate('app.ancs.error.requiredField'),
                });
            }
        }
    });
    maxValues.forEach((item, index) => {
        Object.assign(errors, {
            [`max_${index}`]:
                typeof item[1] === 'number' && item[1] > minValues[index][1]
                    ? undefined
                    : translate('app.ancs.error.maxMustExceedPreviousMin'),
        });
    });
    return errors;
};

const ManagementThresholdsEditForm = ({ open, handleClose }: ManagementThresholdsFormProps) => {
    const translate = useTranslate();
    const [_open, setOpen] = useState<boolean>(open);

    const refresh = useRefresh();
    const dispatch = useDispatch();
    const notify = useNotify();

    const [colorReferenceByName, setColorReferenceByName] = useState<ThresholdsColorReferenceByNameType>({});

    const [inputsManagementThresholds, setInputsManagementThresholds] = useState<InputManagementThreshold[] | any[]>(
        []
    );

    const [totalItemsToRemove, setTotalItemsToRemove] = useState<number>(inputsManagementThresholds.length);

    const classes = useStyles();

    const {
        rikutec: {
            thresholds: { selected: thresholdSelected },
        },
    } = useSelector((state: CustomReducerState) => state);

    const initializeInputsManagementThresholds = () => {
        const propertyCreatedAtMetadata: InputManagementThreshold[] | undefined = resolvePropertyCreatedAtMetadata(
            thresholdSelected
        );

        propertyCreatedAtMetadata && setInputsManagementThresholds(propertyCreatedAtMetadata);
    };

    useEffect(
        () => {
            let newColorByLevel: ThresholdsColorReferenceByNameType = {};

            thresholdSelected?.appliedOnAtPropertyCreatedAtMetadata.forEach((propertyCreatedAtMetadata, index) => {
                const colorByLevel: ThresholdsColorReferenceByNameType = getColorByLevel(
                    {
                        target: {
                            name: propertyCreatedAtMetadata.name,
                            value: propertyCreatedAtMetadata.name,
                        },
                    },
                    index,
                    {}
                );
                newColorByLevel = {
                    ...colorReferenceByName,
                    ...colorByLevel,
                    ...newColorByLevel,
                };
            });
            thresholdSelected?.appliedOnAtPropertyCreatedAtMetadata && setColorReferenceByName(newColorByLevel);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const init = () => {
        if (thresholdSelected && inputsManagementThresholds.length === 0) {
            initializeInputsManagementThresholds();
        }
    };
    useEffect(
        init,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [thresholdSelected]
    );

    const _handleClose = () => {
        setOpen(false);
        handleClose();
    };

    const createNewInputManagementThresholds = (): InputManagementThreshold => {
        return {
            name: '',
            max: -1,
            min: -1,
            durationType: DURATION_TYPE_DAY,
            color: '',
        };
    };

    const addNewInputManagementThresholds = (newInput: InputManagementThreshold) => {
        const newInputsManagementThresholds = [...inputsManagementThresholds, newInput];
        setInputsManagementThresholds(newInputsManagementThresholds);
    };

    const removeInput = (position: number) => {
        inputsManagementThresholds[position] = null;
        setTotalItemsToRemove(totalItemsToRemove - 1);
        setInputsManagementThresholds([...inputsManagementThresholds]);
    };

    const [updateTraceabilityNotesThresholdExecutions] = useMutation({
        type: 'update',
        resource: TRACEABILITY_NOTES_THRESHOLD_EXECUTIONS,
    });

    const resolveInputsManagementThresholdsDataForSubmitting = (values: any) => {
        groupingInputValues(values);

        const traceabilityNotesThresholdExecutions: TraceabilityNotesThresholdExecutions = {
            businessProcessIds: ['UpdateThresholdColor'],
            businessLogicMetadata: [],
            metadataAvatarType: values.metadataAvatarType.id,
            appliedOn: 'note',
            appliedOnNoteAtTag: values[thresholdOperationTypeField],
            appliedOnAtProperty: 'createdAt',
            appliedOnAtPropertyCreatedAtComparingType: 'duration',
            appliedOnToLastItem: true,
            appliedOnAtPropertyCreatedAtMetadata: inputsManagementThresholds
                .map((inputsManagement, index) => {
                    inputsManagement[thresholdColorField] = getColorByLevel(
                        {
                            target: {
                                name: thresholdColorField,
                                value: inputsManagement[thresholdNameField],
                            },
                        },
                        0,
                        {}
                    )[0].value;

                    return (
                        inputsManagement && {
                            id: index,
                            // min: inputsManagement[thresholdMaxValueField],
                            // max: inputsManagement[thresholdMaxValueField],
                            ...inputsManagement,
                        }
                    );
                })
                .filter((c) => c !== null),
        };

        updateTraceabilityNotesThresholdExecutions(
            {
                payload: {
                    id: thresholdSelected?.id,
                    data: traceabilityNotesThresholdExecutions,
                },
            },
            {
                onSuccess: ({ data }) => {
                    _handleClose();
                    dispatch(selectThresholds.fn(data));
                    refresh();
                },
                onFailure: (...args: any[]) => {
                    const violations: ViolationResolved[] = getViolations(args[0]);

                    if (violations && violations.length > 0) {
                        const violation = violations[0];
                        notify(`app.${getCodeFromViolationMessage(violation?.message)}`, 'warning');
                    } else {
                        notify('app.traceabilityNotesThreshold.error.server', 'warning');
                    }
                },
            }
        );
    };

    const groupingInputValues = (values: { [key: string]: string | number }) => {
        setInputsManagementThresholds([...extractThresholdValues(values, [...inputsManagementThresholds])]);
    };

    const resolvePropertyCreatedAtMetadata = (
        thresholdsSelected: TraceabilityNotesThresholdExecutions | undefined
    ): any[] | undefined => {
        return thresholdsSelected?.appliedOnAtPropertyCreatedAtMetadata.map((propertyCreatedAtMetadata) => ({
            [thresholdNameField]: propertyCreatedAtMetadata.name,
            [thresholdMaxValueField]: propertyCreatedAtMetadata.max,
            [thresholdMinValueField]: propertyCreatedAtMetadata.min,
            [thresholdColorField]: propertyCreatedAtMetadata.color,
            [thresholdDurationTypeField]: propertyCreatedAtMetadata.durationType,
        }));
    };

    const resolvePropertyCreatedAtMetadataInitialValues = (
        thresholdsSelected: TraceabilityNotesThresholdExecutions | undefined
    ): any[] => {
        const result: any = [];
        thresholdsSelected?.appliedOnAtPropertyCreatedAtMetadata.forEach((propertyCreatedAtMetadata, index) => {
            result[`${thresholdNameField}_${index}`] = propertyCreatedAtMetadata.name;

            result[`${thresholdColorField}_${index}`] = propertyCreatedAtMetadata.color;

            result[`${thresholdDurationTypeField}_${index}`] = propertyCreatedAtMetadata.durationType;

            if (propertyCreatedAtMetadata.durationType === DURATION_TYPE_MONTH) {
                const { min, max } = convertDaysValuesInMonth(
                    propertyCreatedAtMetadata.min,
                    propertyCreatedAtMetadata.max
                );
                result[`${thresholdMaxValueField}_${index}`] = max;
                result[`${thresholdMinValueField}_${index}`] = min;
            } else {
                result[`${thresholdMaxValueField}_${index}`] = propertyCreatedAtMetadata.max;
                result[`${thresholdMinValueField}_${index}`] = propertyCreatedAtMetadata.min;
            }
        });
        return result;
    };

    const onChangeNameValue = (
        _value: {
            target: { name: string; value: any };
        },
        index: number
    ) => {
        setColorReferenceByName(getColorByLevel(_value, index, colorReferenceByName));
    };

    return (
        <Dialog
            // open={_open}
            open={_open && !!thresholdSelected}
            onClose={_handleClose}
            fullWidth
            maxWidth={'md'}
        >
            <DialogContent>
                {/*// @ts-ignore*/}
                <div className={classes.gridItemTitle}>
                    <TypographyFormTitle
                        title={translate('app.traceabilityNotesThreshold.actions.managementThresholds')}
                        noWrap={true}
                        color={'initial'}
                    />
                </div>

                <Form
                    keepDirtyOnReinitialize
                    initialValues={{
                        metadataAvatarType: {
                            id: thresholdSelected?.metadataAvatarType,
                        },
                        [thresholdOperationTypeField]: thresholdSelected?.appliedOnNoteAtTag,
                        ...resolvePropertyCreatedAtMetadataInitialValues(thresholdSelected),
                    }}
                    onSubmit={(values) => {
                        resolveInputsManagementThresholdsDataForSubmitting(values);
                    }}
                    validate={(values) => validationRules(values, translate)}
                    render={({ handleSubmit }) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid container direction={'column'}>
                                    <Grid item>
                                        <ReferenceInput
                                            validate={[required()]}
                                            variant={'standard'}
                                            label={translate('app.traceabilityNotesThreshold.objectType')}
                                            source='metadataAvatarType.id'
                                            reference={METADATA_AVATAR_TYPES}
                                            filterToQuery={(searchText: string) => ({
                                                name: searchText,
                                            })}
                                        >
                                            <AutocompleteInput
                                                translateChoice={false}
                                                fullWidth
                                                clearAlwaysVisible
                                                optionText={getNameWithoutPrefixFromJson}
                                                source='id'
                                                optionValue='@id'
                                            />
                                        </ReferenceInput>
                                    </Grid>

                                    <Grid item>
                                        <SelectInput
                                            variant={'standard'}
                                            data-testid='select_operation_type_input'
                                            required
                                            fullWidth
                                            source={thresholdOperationTypeField}
                                            optionText='name'
                                            optionValue='id'
                                            label={translate('app.traceabilityNotesThreshold.operationType')}
                                            defaultValue={SLUDGE_EMPTYING}
                                            choices={[
                                                {
                                                    id: MAINTENANCE,
                                                    name: translate('app.ancs.maintenanceThreshold'),
                                                },
                                                {
                                                    id: SLUDGE_EMPTYING,
                                                    name: translate('app.ancs.sludgeEmptyingThreshold'),
                                                },
                                            ]}
                                        />
                                    </Grid>

                                    <Grid item>
                                        <TypographyFormSubtitle
                                            title={translate('app.traceabilityNotesThreshold.actions.defineThresholds')}
                                            noWrap={false}
                                            color={'initial'}
                                        />
                                    </Grid>

                                    {inputsManagementThresholds
                                        .map((inputManagement, index) =>
                                            null === inputManagement ? null : (
                                                <Grid item key={`grid_container_form_${index}`}>
                                                    <Grid
                                                        container
                                                        spacing={2}
                                                        alignItems='center'
                                                        // @ts-ignore
                                                        className={classes.itemsGroup}
                                                    >
                                                        <Grid item xs>
                                                            <SelectInput
                                                                variant={'standard'}
                                                                data-testid='select_name_input'
                                                                required
                                                                fullWidth
                                                                source={`${thresholdNameField}_${index}`}
                                                                optionText='name'
                                                                optionValue='id'
                                                                label={translate('app.ancs.thresholdName')}
                                                                defaultValue={''}
                                                                choices={thresholdsNameChoices(translate)}
                                                                onChange={(e: any) => {
                                                                    onChangeNameValue(e, index);
                                                                }}
                                                                helperText={false}
                                                            />
                                                        </Grid>

                                                        <Grid item>
                                                            <TextField
                                                                value={colorReferenceByName[index]?.value}
                                                                disabled={true}
                                                                variant={'outlined'}
                                                                type={'color'}
                                                                fullWidth
                                                                label={''}
                                                                data-testid={`color_input`}
                                                                helperText={false}
                                                                // @ts-ignore
                                                                className={classes.inputColor}
                                                            />
                                                        </Grid>

                                                        <Grid item xs>
                                                            <NumberInput
                                                                variant={'standard'}
                                                                required
                                                                fullWidth
                                                                label={translate('app.ancs.thresholdMinTriggerValue')}
                                                                source={`${thresholdMinValueField}_${index}`}
                                                                helperText={false}
                                                            />
                                                        </Grid>

                                                        <Grid item xs>
                                                            <NumberInput
                                                                variant={'standard'}
                                                                required
                                                                fullWidth
                                                                type={'number'}
                                                                label={translate('app.ancs.thresholdMaxTriggerValue')}
                                                                source={`${thresholdMaxValueField}_${index}`}
                                                                helperText={false}
                                                            />
                                                        </Grid>

                                                        <Grid item xs>
                                                            <SelectInput
                                                                variant={'outlined'}
                                                                fullWidth
                                                                source={`${thresholdDurationTypeField}_${index}`}
                                                                optionText='name'
                                                                optionValue='id'
                                                                label={''}
                                                                disabled
                                                                defaultValue={DURATION_TYPE_DAY}
                                                                choices={[
                                                                    {
                                                                        id: DURATION_TYPE_DAY,
                                                                        name: translate('app.ancs.days'),
                                                                    },
                                                                    {
                                                                        id: DURATION_TYPE_MONTH,
                                                                        name: translate('app.ancs.month'),
                                                                    },
                                                                ]}
                                                                helperText={false}
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <IconButton
                                                                aria-label='delete'
                                                                onClick={() => removeInput(index)}
                                                                disabled={
                                                                    totalItemsToRemove ===
                                                                    -(inputsManagementThresholds.length - 1)
                                                                }
                                                            >
                                                                <DeleteIcon
                                                                    fontSize='medium'
                                                                    classes={{
                                                                        root:
                                                                            totalItemsToRemove ===
                                                                            -(inputsManagementThresholds.length - 1)
                                                                                ? // @ts-ignore
                                                                                  classes.deleteIconDisabled
                                                                                : // @ts-ignore
                                                                                  classes.deleteIcon,
                                                                    }}
                                                                />
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            )
                                        )
                                        .filter((c) => c !== null)}

                                    <Grid item>
                                        <Button
                                            size={'medium'}
                                            classes={{
                                                // @ts-ignore
                                                root: classes.addButtonRootText,
                                            }}
                                            onClick={() =>
                                                addNewInputManagementThresholds(createNewInputManagementThresholds())
                                            }
                                            disabled={
                                                inputsManagementThresholds.filter((c) => c !== null).length ===
                                                LIMIT_ADDING
                                            }
                                            color='primary'
                                            // @ts-ignore
                                            endIcon={<AddIcon classes={{ root: classes.addIcon }} />}
                                            variant={'outlined'}
                                        >
                                            {translate('app.ancs.addThreshold')}
                                        </Button>
                                    </Grid>
                                    {/*// @ts-ignore*/}
                                    <Grid container justifyContent={'flex-end'} className={classes.dialogButtons}>
                                        <Button
                                            color={'primary'}
                                            size={'medium'}
                                            classes={{
                                                // @ts-ignore
                                                root: classes.addButtonRoot,
                                                // @ts-ignore
                                                text: classes.addButtonRootText,
                                            }}
                                            onClick={_handleClose}
                                            style={{ marginRight: 12 }}
                                        >
                                            {translate('app.button.cancel')}
                                        </Button>

                                        <ButtonSubmit text={translate('app.button.confirm')} />
                                    </Grid>
                                </Grid>
                            </form>
                        );
                    }}
                />
            </DialogContent>
        </Dialog>
    );
};

export default ManagementThresholdsEditForm;
