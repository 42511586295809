import { MAINTENANCE, SLUDGE_EMPTYING, SPANC } from '../utils/rikutec';

const locale = {
    app: {
        common: {
            after: 'After',
            before: 'Before',
        },
        button: {
            confirm: 'Confirm',
            submit: 'Submit',
            cancel: 'Cancel',
            go: {
                toThresholdPage: 'Go to threshold view',
                toAncsPage: 'Go to Ancs view',
            },
        },
        ancs: {
            level1: 'Level 1',
            level2: 'Level 2',
            level3: 'Level 3',
            level4: 'Level 4',
            level5: 'Level 5',
            postalCode: 'Postal Code',
            name: 'Name',
            articleNumber: 'Item number',
            productTypology: 'Product typology',
            serialNumber: 'Serial number',
            installingDate: 'Installing date',
            maintenanceLastDate: 'Last maintenance',
            sludgeEmptyingLastDate: 'Last sludge emptying',
            maintenanceThreshold: 'Maintenance threshold',
            sludgeEmptyingThreshold: 'Sludge emtying threshold',
            sludgeEmptyingThresholdFilterDate: 'Filter by period and event plotted',
            sludgeEmptyingThresholdFilterLastDate: 'Period per drain',
            sludgeMaintenanceThresholdFilterLastDate: 'Period per maintenance',
            sludgeEmptyingThresholdOrMaintenanceFilterDate: 'Last sludge emptying',
            thresholdName: 'Level label',
            thresholdMinValue: 'Min Value',
            thresholdMaxValue: 'Max Value',
            thresholdTriggerValue: 'Trigger Value',
            thresholdMinTriggerValue: 'Range lower limit',
            thresholdMaxTriggerValue: 'High limit of the interval',
            thresholdMonthValue: 'Month',
            addThreshold: 'Add level',
            processingCapacity: 'Processing capacity',
            sludgeStorageVolume: 'Sludge storage volume',
            totalUsefulVolume: 'Total useful volume',
            nodeCurrentState: 'Current status',
            nodeStateName: 'Status name',
            nodeDateOfTakesPlace: 'Date of the current status',
            model: 'Model',
            weeks: 'Weeks',
            hours: 'Hours',
            days: 'Days',
            years: 'Years',
            month: 'Month',
            accessToFinger: 'Field view',
            [MAINTENANCE]: 'Maintenance',
            [SLUDGE_EMPTYING]: 'Sludge emptying',
            [SPANC]: 'Spanc',
            actions: {
                title: 'Actions',
                managementThresholds: 'Management of thresholds',
                defineThresholds: 'List of alert levels, based on time intervals since last operation',
                export: 'Export',
                statistics: 'Statistics',
                refresh: 'Refresh',
                personalize: 'Personalize',
                editVP: 'Add / Edit VP',
                editWC: 'Add / Edit warranty certificate',
            },
            error: {
                server: 'An error was found. Please contact the administrator',
                requiredField: 'Required field',
                wrongMinAmount: 'Wrong min amount',
                minMustExceedPreviousMax: 'Value must be greater than the previous max',
                wrongMaxAmount: 'Wrong max amount',
                maxMustExceedPreviousMin: 'Value must be greater than previous min',
                greaterThanZero: 'Cannot be negative',
            },
            metadataAvatarType: {
                name: 'Object type name',
            },
            help_text: {
                once_validated:
                    'Once validated, to see the document sent, click on the button in the “Field view” column',
            },
            success: {
                success: 'Data has saved',
                media_uploaded: 'The selected media has been uploaded',
                media_updated: 'The selected media has been updated',
            },
        },
        traceabilityNotesThreshold: {
            name: 'Threshold',
            operationType: 'Operation Type',
            activeEvent: 'Active event',
            objectType: 'Object Type',
            deleted: 'The threshold was well deleted',
            actions: {
                title: 'Actions',
                addEvent: 'Add threshold',
                deleteEvent: 'Delete threshold',
                editEvent: 'Edit threshold',
                managementThresholds: 'Management threshold',
                defineThresholds: 'Define trigger thresholds from the last date of the selected event',
                export: 'Export',
                statistics: 'Statistics',
                refresh: 'Refresh',
                personalize: 'Personalize',
                delete: {
                    title: 'Delete threshold?',
                    message: 'Are you sure you want to delete the threshold?',
                },
            },
            error: {
                server: 'A server error has occurred. Please contact the administrator',
                uniqueMetadataAvatarType: 'There is a notification linked to this type object',
                set: {
                    groupAvatar: 'Object group must be defined',
                    avatar: 'Object must be defined',
                    metadataAvatarType: 'Object type must be defined',
                },
            },
            maintenance: {
                name: 'Maintenance threshold',
            },
            emptying: {
                name: 'Emptying threshold',
            },
        },
        sidebar: {
            anc: 'Ancs monitoring',
            every2Hours: 'Updated every 2 hours',
            realTime: 'Real-time update',
            product_type: 'Product type',
            type: 'Type',
            threshold_settings: 'Threshold settings',
        },
        types: {
            productName: 'Name',
            productType: 'Product Type',
            alpha_id: 'Alpha Id',
            id: 'Id',
            name: 'Name of type',
            processingCapacity: 'Processing Capacity',
            sludgeStorageVolume: 'Sludge Storage Volume',
            totalUsefulVolume: 'Total Useful Volume',
            model: 'Model',
            itemCode: 'Item code',
            typeSerialNumber: 'First 4 digit of serial number',
            create: 'Create Type',
            edit: 'Edit',
            empty: '<empty>',
            addEditInstallingGuide: 'Add / Edit Installing Guide',
            addEditinstalling_guide: 'Add / Edit Installing Guide',
            installing_guide: 'Installing guide',
            user_booklet: "User's booklet",
            user_guide: 'Use and Care Guide',
            reflex_emtying: 'Emptying Reflex Sheet',
            reflex_maintenance: 'Maintenance Reflex Sheet',
            technical_sheet: 'Technical Sheet',
            routine_maintenance: 'Routine Maintenance',
            addReflexEmtying: 'Add Emptying Reflex Sheet',
            addReflexMaintenance: 'Add Maintenance Reflex Sheet',
            addInstallingGuide: 'Add Installing Guide',
            addUserBooklet: 'Add User booklet',
            addUserGuide: 'Add User Guide',
            addTechnicalSheet: 'Add Technical Sheet',
            addRoutineMaintenance: 'Add Routine Maintenance',
            enableApproval: 'Enable accreditation',
            disableApproval: 'Disable accreditation',
            approvalEnabled: 'Accreditation enabled',
            approvalDisabled: 'Accreditation disabled',
            mediaFile: 'Upload a file',
            addEditUserGuide: 'Add / Edit User Guide',
            addEdituser_guide: 'Add / Edit User Guide',
            addEditTechnicalSheet: 'Add / Edit Technical Sheet',
            addEdittechnical_sheet: 'Add / Edit Technical Sheet',
            addEditRoutineMaintenance: 'Add / Edit Routine Maintenance',
            addEditroutine_maintenance: 'Add / Edit Routine Maintenance',
            yes: 'Yes',
            no: 'No',
            messages: {
                success: 'Data has saved',
                error: 'Some error has occurred',
            },
        },
        actions: {
            refresh: 'Refresh',
            addItem: 'Create',
            editItem: 'Edit',
            deleteItem: 'Delete',
            title: 'Actions',
        },
        headers: {
            description: 'Description',
            additional_info: 'Additional Information',
            info: 'Information',
            type: 'Types',
            selectAvatarTypeGroup: 'Select Product Type',
            confirmDelete: 'Remove',
            productTypeData: 'Product Type',
            productType: 'Product Type',
            ancs: 'Ancs monitoring',
            thresholds: 'Threshold Settings',
        },
        validation: {
            required: 'Field is required',
        },
        messages: {
            confirmDelete: 'Do you want to delete this line?',
            confirmOperation: 'You are about to delete an item. Are you sure you want to continue?',
            canSendApprovalLater: 'You can send the approval latter',
            loading: 'Loading, please wait ',
            error: 'Something went wrong, please notify the admin',
            success: 'Operation was successful',
        },
        productType: {
            create: 'Create',
            edit: 'Edit',
            name: 'Product type',
            yes: 'Yes',
            no: 'No',
            approval: 'Approval',
            approvalFile: 'Upload an approval file',
            mediaFile: 'Upload a file',
            addApproval: 'Add an approval',
            editApproval: 'Edit an approval',
            addItem: 'Add',
            editItem: 'Edit',
            deleteItem: 'Delete',
            messages: {
                success: 'Data has saved',
                error: 'Some error has occurred',
            },
            success: {
                updated: 'Product type has been updated',
            },
            error: {
                server: 'An error was found. Please contact the administrator',
            },
        },
        errors: {
            updatingProperty: 'There was an error during the update of a property',
            creatingProperty: 'There was an error during the creating of a property',
            updatingType: 'There was an error during the update of a type',
            creatingType: 'There was an error during the creation of a type',
            gettingLanguage: 'There was an error during the fetching of property language',
        },
        no: 'No',
        yes: 'Yes',
        medias: {
            addApproval: 'Add approval',
            addTechnicalSheet: 'Add technical sheet',
            addRoutineMaintenance: 'Add routine maintenance',
            addUserGuide: 'Add user guide',
            addInstallingGuide: 'Add installing guide',
            mediaFile: 'Upload a media',
            addReflexMaintenance: 'Add the Reflex Maintenance sheet',
            addReflexEmptying: 'Add the Drain Reflex Sheet',
            addUserBooklet: 'Add User booklet',
        },
    },
};

export default locale;
