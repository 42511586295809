export const itemCodeProperty: { en: string; fr: string; es: string } = {
    fr: "Code d'article",
    en: 'Product Code',
    es: 'Código del producto',
};

export const typeSerialNumberProperty: { en: string; fr: string; es: string } = {
    fr: '4 premiers chiffres du numéro de série',
    en: 'First 4 digit of serial number',
    es: 'Primeros 4 dígitos del número de serie',
};

export const enableAppovalProperty: { en: string; fr: string; es: string } = {
    fr: "Activer l'agrément",
    en: 'Enable accreditation',
    es: 'Habilitar acreditación',
};

export const ENABLE_APPROVAL_PROPERTY_NAME = 'enableApproval';
