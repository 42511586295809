import { withStyles } from '@material-ui/core/styles';
import { translate } from 'react-admin';
import compose from 'recompose/compose';
import MenuButtonActions from './MenuButtonActions';

const actionsStyles = (theme) => ({
    icons: {
        marginRight: 5,
        fontSize: 16,
    },
    cardActions: {
        width: '100%',
        marginLeft: -20,
        marginTop: 5,
        marginBottom: 5,
        justifyContent: 'flex-start',
        zIndex: 1,
    },
    menuListRoot: {
        padding: 0,
    },
    menuListItemRoot: {
        marginRight: 5,
    },
    menuRoot: {
        padding: '2px 10px 2px 5px',
    },
    menuIcon: {
        fontSize: 20,
    },
    menuTextRoot: {
        padding: 0,
        textTransform: 'uppercase',
    },
    menuText: {
        fontSize: 13,
        fontWeight: 'bold',
    },
    rootCheckbox: {
        padding: 0,
        marginRight: theme.spacing.unit,
    },
    multiSelectLabel: {
        color: theme.palette.primary.main,
        textTransform: 'uppercase',
        fontSize: 13,
        fontWeight: 500,
    },
    multiSelectLabelCheckbox: {
        padding: 6,
        color: theme.palette.primary.main,
    },
    formControlLabelRoot: {
        marginLeft: -5,
        marginRight: 5,
    },
    dialogTitle: {
        padding: '17px 20px 0 20px',
        paddingBottom: 15,
    },
    dialogTitleText: {
        textTransform: 'uppercase',
        fontWeight: 'bold',
        fontSize: 17,
    },
    container: {
        display: 'grid',
        gridTemplateColumns: '30px auto',
        alignItems: 'center',
        columnGap: '7px',
    },
    dialogActions: {
        marginRight: 20,
        marginBottom: 20,
    },
    button: {
        paddingLeft: 30,
        paddingRight: 30,
        marginLeft: 10,
    },
    circularProgress: {
        marginTop: '20px',
        marginBottom: '20px',
        overflowY: 'hidden',
    },
    dialogContent: {
        paddingBottom: 0,
    },
    dialogText: {
        color: 'black',
        fontSize: 13,
    },
    buttonMenu: {
        paddingLeft: 10,
        fontSize: 13,
    },
});

const enhance = compose(withStyles(actionsStyles), translate);

export default enhance(MenuButtonActions);
