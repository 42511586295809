import DeleteIcon from '@material-ui/icons/Delete';
import NewIcon from '@material-ui/icons/FiberNew';
import SettingsIcon from '@material-ui/icons/Settings';
import React, { Fragment, useState } from 'react';
import { ReduxState, useDelete, useNotify, usePermissions, useRefresh, useTranslate } from 'react-admin';
import { useSelector } from 'react-redux';
import { GROUP_METADATA_AVATAR_TYPES } from '../../../../../providers/resources';
import { CustomReducerState } from '../../../../../redux/reducers';
import ConfirmDlg from '../../../../type/actions/managementTypes/ConfirmDlg';
import MenuButtonActions from '../../../../UI/MenuButtonActions/MenuButtonActionsContainer';
import ProductTypeForm from './ProductTypeForm/ProductTypeForm';
import ProductTypeActionsAddMediaForm from './ProductTypeActionsAddMediaForm/ProductTypeActionsAddMediaForm';

const ProductTypeActionsMenu = () => {
    const t = useTranslate();
    const refresh = useRefresh();
    const notify = useNotify();
    const { permissions } = usePermissions();
    const {
        rikutec: {
            groupsMetadataAvatarType: { selected: selectedGroup },
        },
    } = useSelector((state: CustomReducerState) => state);
    const {
        admin: {
            resources: {
                group_metadata_avatar_types: {
                    list: { ids },
                },
            },
        },
    } = useSelector((state: ReduxState) => state);
    const [, setAnchorElement] = useState(null);
    const [formState, setFormState] = useState<{ isOpen: boolean; action: 'CREATE' | 'EDIT' | 'NONE' }>({
        isOpen: false,
        action: 'NONE',
    });
    const [mediaFormState, setMediaFormState] = useState<{
        isOpen: boolean;
        mediaType: 'user_guide' | 'technical_sheet' | 'routine_maintenance';
    }>({
        isOpen: false,
        mediaType: 'user_guide',
    });
    const [askConfirmation, setAskConfirmation] = useState(false);
    const [deleteOne, { loading: deleting }] = useDelete(GROUP_METADATA_AVATAR_TYPES, selectedGroup.id, selectedGroup, {
        onSuccess: () => {
            refresh();
            toggleAskConfirmation();
        },
        onFailure: () => notify(t('app.ancs.error.server')),
    });

    const handleClose = () => setAnchorElement(null);
    const toggleForm = () => setFormState({ isOpen: false, action: 'NONE' });
    const openCreate = () => {
        setFormState({ isOpen: true, action: 'CREATE' });
        handleClose();
    };
    const openEdit = () => {
        setFormState({ isOpen: true, action: 'EDIT' });
        handleClose();
    };
    const toggleMediaForm = () => {
        setMediaFormState({ isOpen: false, mediaType: 'user_guide' });
    };
    const toggleAskConfirmation = () => {
        setAskConfirmation(!askConfirmation);
        handleClose();
    };

    const [menuState, setMenuState] = useState(false);

    const handleToggle = () => {
        setMenuState(!menuState);
    };
    const menuActions = [
        {
            icon: <NewIcon />,
            text: t('app.productType.addItem'),
            onClick: openCreate,
            disabled:
                permissions?.every((item: string) => item !== 'ROLE_VIEW_GROUP_OBJECT_TYPES_CREATE') ||
                permissions?.every((item: string) => item !== 'ROLE_CREATE_GROUP_OBJECT_TYPE'),
        },
        {
            icon: <SettingsIcon />,
            text: t('app.productType.editItem'),
            onClick: openEdit,
            disabled:
                Object.keys(selectedGroup).length === 0 ||
                !ids.includes(selectedGroup.id) ||
                permissions?.every((item: string) => item !== 'ROLE_VIEW_GROUP_OBJECT_TYPES_UPDATE') ||
                permissions?.every((item: string) => item !== 'ROLE_UPDATE_GROUP_OBJECT_TYPE'),
        },
        // {
        //     icon: <EditFileIcon />,
        //     text: t('app.productType.addEditUserGuide'),
        //     onClick: openUserGuideForm,
        //     disabled: !selectedGroup.id || !ids.includes(selectedGroup.id) || permissions?.every((item: string) => item !== 'ROLE_FM_CLOUD_WRITE'),
        // },
        // {
        //     icon: <EditFileIcon />,
        //     text: t('app.productType.addEditTechnicalSheet'),
        //     onClick: openTechnicalSheetForm,
        //     disabled: !selectedGroup.id || !ids.includes(selectedGroup.id) || permissions?.every((item: string) => item !== 'ROLE_FM_CLOUD_WRITE'),
        // },
        // {
        //     icon: <EditFileIcon />,
        //     text: t('app.productType.addEditRoutineMaintenance'),
        //     onClick: openRoutineMaintenanceForm,
        //     disabled: !selectedGroup.id || !ids.includes(selectedGroup.id) || permissions?.every((item: string) => item !== 'ROLE_FM_CLOUD_WRITE'),
        // },
        {
            icon: <DeleteIcon />,
            text: t('app.productType.deleteItem'),
            onClick: toggleAskConfirmation,
            disabled: true,
        },
    ];

    return (
        <Fragment>
            <MenuButtonActions
                buttonLabel={t('app.actions.title')}
                overwriteButtonLabel
                menuActions={menuActions}
                menuState={menuState}
                onButtonClick={handleToggle}
            />

            <ProductTypeForm isOpen={formState.isOpen} action={formState.action} toggle={toggleForm} />
            <ProductTypeActionsAddMediaForm
                isOpen={mediaFormState.isOpen}
                type={mediaFormState.mediaType}
                toggle={toggleMediaForm}
            />
            <ConfirmDlg isOpen={askConfirmation} toggle={toggleAskConfirmation} apply={deleteOne} deleting={deleting} />
        </Fragment>
    );
};

export default ProductTypeActionsMenu;
