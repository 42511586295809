import { useGetList, useNotify, useTranslate } from 'react-admin';
import { LANGUAGES } from '../providers/resources';
import { Dispatch, SetStateAction, useState } from 'react';
import { enableAppovalProperty, itemCodeProperty, typeSerialNumberProperty } from '../utils/metadataAvatarTypeProperty';

type PropertyLanguages = {
    processingCapacity: {
        en: string;
        fr: string;
        es: string;
    };
    sludgeStorageVolume: {
        en: string;
        fr: string;
        es: string;
    };
    // totalUsefulVolume: {
    //     en: string;
    //     fr: string;
    //     es: string;
    // };
    model: {
        en: string;
        fr: string;
        es: string;
    };
    productCode: {
        en: string;
        fr: string;
        es: string;
    };
    typeserialnumber: {
        en: string;
        fr: string;
        es: string;
    };
    enableApproval: {
        en: string;
        fr: string;
        es: string;
    };
};

export const useGetPropertyLanguages = (): [PropertyLanguages, Dispatch<SetStateAction<PropertyLanguages>>] => {
    const [resData, setResData] = useState<PropertyLanguages>({
        model: { en: '', es: '', fr: '' },
        sludgeStorageVolume: { en: '', es: '', fr: '' },
        // totalUsefulVolume: { en: '', es: '', fr: '' },
        processingCapacity: { en: '', es: '', fr: '' },
        productCode: { en: '', es: '', fr: '' },
        typeserialnumber: { en: '', es: '', fr: '' },
        enableApproval: { en: '', es: '', fr: '' },
    });
    const notify = useNotify();
    const t = useTranslate();

    // PROCESSING CAPACITY
    useGetList(
        LANGUAGES,
        undefined,
        undefined,
        {
            twoLetters: 'en',
            value: 'Processing capacity',
        },
        {
            onSuccess: (response) =>
                setResData((prevState) => ({
                    ...prevState,
                    processingCapacity: { ...prevState.processingCapacity, en: response.data[0]?.['@id'] || '' },
                })),
            onFailure: () => notify(t('app.ancs.error.server')),
        }
    );
    useGetList(
        LANGUAGES,
        undefined,
        undefined,
        {
            twoLetters: 'es',
            value: 'Capacidad de procesamiento',
        },
        {
            onSuccess: (response) =>
                setResData((prevState) => ({
                    ...prevState,
                    processingCapacity: { ...prevState.processingCapacity, es: response.data[0]?.['@id'] || '' },
                })),
            onFailure: () => notify(t('app.ancs.error.server')),
        }
    );
    useGetList(
        LANGUAGES,
        undefined,
        undefined,
        {
            twoLetters: 'fr',
            value: 'Capacité de traitement',
        },
        {
            onSuccess: (response) =>
                setResData((prevState) => ({
                    ...prevState,
                    processingCapacity: { ...prevState.processingCapacity, fr: response.data[0]?.['@id'] || '' },
                })),
            onFailure: () => notify(t('app.ancs.error.server')),
        }
    );

    //SLUDGE STORAGE VOLUME
    useGetList(
        LANGUAGES,
        undefined,
        undefined,
        {
            twoLetters: 'en',
            value: 'Sludge Storage Volume',
        },
        {
            onSuccess: (response) =>
                setResData((prevState) => ({
                    ...prevState,
                    sludgeStorageVolume: { ...prevState.sludgeStorageVolume, en: response.data[0]?.['@id'] || '' },
                })),
            onFailure: () => notify(t('app.ancs.error.server')),
        }
    );
    useGetList(
        LANGUAGES,
        undefined,
        undefined,
        {
            twoLetters: 'es',
            value: 'Volumen dedicado al almacenamiento de lodos',
        },
        {
            onSuccess: (response) =>
                setResData((prevState) => ({
                    ...prevState,
                    sludgeStorageVolume: { ...prevState.sludgeStorageVolume, es: response.data[0]?.['@id'] || '' },
                })),
            onFailure: () => notify(t('app.ancs.error.server')),
        }
    );
    useGetList(
        LANGUAGES,
        undefined,
        undefined,
        {
            twoLetters: 'fr',
            value: 'Volume dédié au stockage des boues',
        },
        {
            onSuccess: (response) =>
                setResData((prevState) => ({
                    ...prevState,
                    sludgeStorageVolume: { ...prevState.sludgeStorageVolume, fr: response.data[0]?.['@id'] || '' },
                })),
            onFailure: () => notify(t('app.ancs.error.server')),
        }
    );

    //MODEL
    useGetList(
        LANGUAGES,
        undefined,
        undefined,
        {
            twoLetters: 'en',
            value: 'Model',
        },
        {
            onSuccess: (response) =>
                setResData((prevState) => ({
                    ...prevState,
                    model: { ...prevState.model, en: response.data[0]?.['@id'] || '' },
                })),
            onFailure: () => notify(t('app.ancs.error.server')),
        }
    );
    useGetList(
        LANGUAGES,
        undefined,
        undefined,
        {
            twoLetters: 'es',
            value: 'Modelo',
        },
        {
            onSuccess: (response) =>
                setResData((prevState) => ({
                    ...prevState,
                    model: { ...prevState.model, es: response.data[0]?.['@id'] || '' },
                })),
            onFailure: () => notify(t('app.ancs.error.server')),
        }
    );
    useGetList(
        LANGUAGES,
        undefined,
        undefined,
        {
            twoLetters: 'fr',
            value: 'Modèle',
        },
        {
            onSuccess: (response) =>
                setResData((prevState) => ({
                    ...prevState,
                    model: { ...prevState.model, fr: response.data[0]?.['@id'] || '' },
                })),
            onFailure: () => notify(t('app.ancs.error.server')),
        }
    );

    //ITEM CODE
    useGetList(
        LANGUAGES,
        undefined,
        undefined,
        {
            twoLetters: 'en',
            value: itemCodeProperty.en,
        },
        {
            onSuccess: (response) =>
                setResData((prevState) => ({
                    ...prevState,
                    productCode: { ...prevState.productCode, en: response.data[0]?.['@id'] || '' },
                })),
            onFailure: () => notify(t('app.ancs.error.server')),
        }
    );
    useGetList(
        LANGUAGES,
        undefined,
        undefined,
        {
            twoLetters: 'es',
            value: itemCodeProperty.es,
        },
        {
            onSuccess: (response) =>
                setResData((prevState) => ({
                    ...prevState,
                    productCode: { ...prevState.productCode, es: response.data[0]?.['@id'] || '' },
                })),
            onFailure: () => notify(t('app.ancs.error.server')),
        }
    );
    useGetList(
        LANGUAGES,
        undefined,
        undefined,
        {
            twoLetters: 'fr',
            value: itemCodeProperty.fr,
        },
        {
            onSuccess: (response) =>
                setResData((prevState) => ({
                    ...prevState,
                    productCode: { ...prevState.productCode, fr: response.data[0]?.['@id'] || '' },
                })),
            onFailure: () => notify(t('app.ancs.error.server')),
        }
    );

    //TYPE SERIAL NUMBER
    useGetList(
        LANGUAGES,
        undefined,
        undefined,
        {
            twoLetters: 'en',
            value: typeSerialNumberProperty.en,
        },
        {
            onSuccess: (response) =>
                setResData((prevState) => ({
                    ...prevState,
                    typeserialnumber: { ...prevState.typeserialnumber, en: response.data[0]?.['@id'] || '' },
                })),
            onFailure: () => notify(t('app.ancs.error.server')),
        }
    );
    useGetList(
        LANGUAGES,
        undefined,
        undefined,
        {
            twoLetters: 'es',
            value: typeSerialNumberProperty.es,
        },
        {
            onSuccess: (response) =>
                setResData((prevState) => ({
                    ...prevState,
                    typeserialnumber: { ...prevState.typeserialnumber, es: response.data[0]?.['@id'] || '' },
                })),
            onFailure: () => notify(t('app.ancs.error.server')),
        }
    );
    useGetList(
        LANGUAGES,
        undefined,
        undefined,
        {
            twoLetters: 'fr',
            value: typeSerialNumberProperty.fr,
        },
        {
            onSuccess: (response) =>
                setResData((prevState) => ({
                    ...prevState,
                    typeserialnumber: { ...prevState.typeserialnumber, fr: response.data[0]?.['@id'] || '' },
                })),
            onFailure: () => notify(t('app.ancs.error.server')),
        }
    );

    // ENABLE APPROVAL
    useGetList(
        LANGUAGES,
        undefined,
        undefined,
        {
            twoLetters: 'en',
            value: enableAppovalProperty.en,
        },
        {
            onSuccess: (response) =>
                setResData((prevState) => ({
                    ...prevState,
                    enableApproval: { ...prevState.enableApproval, en: response.data[0]?.['@id'] || '' },
                })),
            onFailure: () => notify(t('app.ancs.error.server')),
        }
    );
    useGetList(
        LANGUAGES,
        undefined,
        undefined,
        {
            twoLetters: 'es',
            value: enableAppovalProperty.es,
        },
        {
            onSuccess: (response) =>
                setResData((prevState) => ({
                    ...prevState,
                    enableApproval: { ...prevState.enableApproval, es: response.data[0]?.['@id'] || '' },
                })),
            onFailure: () => notify(t('app.ancs.error.server')),
        }
    );
    useGetList(
        LANGUAGES,
        undefined,
        undefined,
        {
            twoLetters: 'fr',
            value: enableAppovalProperty.fr,
        },
        {
            onSuccess: (response) =>
                setResData((prevState) => ({
                    ...prevState,
                    enableApproval: { ...prevState.enableApproval, fr: response.data[0]?.['@id'] || '' },
                })),
            onFailure: () => notify(t('app.ancs.error.server')),
        }
    );

    return [resData, setResData];
};
