import { FileSystemMapper, GroupMetadataAvatarType } from '../../utils/types';
import { MEDIA_TAG_APPROVAL, MEDIA_TAGS } from '../../providers/resources';

export const SELECT_GROUP_METADATA_AVATAR_TYPE = 'select_group_metadata_avatar_type';
export const UNSELECT_GROUP_METADATA_AVATAR_TYPE = 'unselect_group_metadata_avatar_type';
export const ADD_TO_GROUP_METADATA_AVATAR_TYPE_DATA = 'add_to_group_metadata_avatar_type_data';

export type GroupMetadataAvatarTypeData = {
    [id: string]: {
        item: any | undefined;
        medias: {
            [MEDIA_TAG_APPROVAL]: {
                fsm: FileSystemMapper | undefined;
                outdated: boolean;
            };
        };
    };
};

export type GroupMetadataAvatarTypeState = {
    data: GroupMetadataAvatarTypeData;
    selected: any;
};

export interface GroupMetadataAvatarTypeActions {
    type:
        | typeof SELECT_GROUP_METADATA_AVATAR_TYPE
        | typeof UNSELECT_GROUP_METADATA_AVATAR_TYPE
        | typeof ADD_TO_GROUP_METADATA_AVATAR_TYPE_DATA;
    payload: any;
}

export interface AddToGroupMetadataAvatarTypeDataAction extends GroupMetadataAvatarTypeActions {
    type: typeof ADD_TO_GROUP_METADATA_AVATAR_TYPE_DATA;
    payload: {
        item: GroupMetadataAvatarType;
        tag: MEDIA_TAGS;
        fsm: FileSystemMapper;
    };
}

export const selectGroupMetadataAvatarType = (item: any): GroupMetadataAvatarTypeActions => ({
    type: SELECT_GROUP_METADATA_AVATAR_TYPE,
    payload: item,
});

export const unselectGroupMetadataAvatarType = (): GroupMetadataAvatarTypeActions => ({
    type: UNSELECT_GROUP_METADATA_AVATAR_TYPE,
    payload: {},
});

export const addToGroupMetadataAvatarTypeData = (
    item: GroupMetadataAvatarType,
    tag: MEDIA_TAGS,
    fsm: FileSystemMapper
): AddToGroupMetadataAvatarTypeDataAction => ({
    type: ADD_TO_GROUP_METADATA_AVATAR_TYPE_DATA,
    payload: {
        item,
        tag,
        fsm,
    },
});
