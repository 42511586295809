import { User } from "../../utils/types";
import { Record } from "react-admin";

export interface TokenFoundAction {
    type: typeof tokenFound.action;
    payload: {
        token: string;
    };
}

export const tokenFound = {
    action: "360SC_RIKUTEC/TOKEN_FOUND",
    fn: (token: string): TokenFoundAction => ({
        type: tokenFound.action,
        payload: {
            token,
        },
    }),
};

export interface ResolvedUserConnectedAction {
    type: typeof resolvedUserConnected.action;
    payload: {
        user: Record | User;
    };
}

export const resolvedUserConnected = {
    action: "360SC_RIKUTEC/RESOLVED_USER_CONNECTED",
    fn: (user: Record | User): ResolvedUserConnectedAction => ({
        type: resolvedUserConnected.action,
        payload: {
            user,
        },
    }),
};

export interface CompanyLogoUrlFoundOnFileSystemMapperServiceAction {
    type: typeof companyLogoUrlFound.action;
    payload: {
        token: string;
    };
}

export interface CompanyLogoUrlFoundAction {
    type: typeof companyLogoUrlFound.action;
    payload: {
        companyLogoUrl: string;
    };
}

export const companyLogoUrlFound = {
    action:
        "360SC_RIKUTEC/COMPANY_LOGO_URL_FOUND_ON_FILE_SYSTEM_MAPPER_SERVICE",
    fn: (companyLogoUrl: string): CompanyLogoUrlFoundAction => ({
        type: companyLogoUrlFound.action,
        payload: {
            companyLogoUrl,
        },
    }),
};
