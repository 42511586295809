// @ts-ignore
export interface ValidationError {
    body: ValidationErrorBody[];
    name: string;
    status: number;
    message: string;
    stack: string;
}

export const description = 'http://www.w3.org/ns/hydra/core#description';
export const title = 'http://www.w3.org/ns/hydra/core#title';

export interface ValidationErrorBody {
    '@type': string;
    [description]: { '@value': string }[];
    [title]: { '@value': string }[];

    [key: string]: any;
}

export interface ViolationResolved {
    propertyPath: string;
    message: string;
}

export interface ValidationJson {
    code: string;
    message: string;
    propertyPath: string;
}

export interface ValidationJsonError {
    violations: ValidationJson[];
}

/**
 * @param message - CODE_LINK_REPEATEDLY_CREATE_DESTINATION: 5 max links repeatedly
 * @return string - CODE_LINK_REPEATEDLY_CREATE_DESTINATION
 */
export const getCodeFromViolationMessage = (message: string): string => {
    const messageSplit = message.split(':');
    return Array.isArray(messageSplit) && messageSplit.length > 0 ? messageSplit[0] : message;
};

/**
 * @param message
 * @return string - 5
 */
export const getFirstValueAfterCodeFromViolationMessage = (message: string): string => {
    const messageSplit = message.split(':');
    const valueSplit = Array.isArray(messageSplit) && messageSplit.length > 0 ? messageSplit[1].split(' ') : [];

    return valueSplit.length > 0 ? valueSplit[0] : '';
};

export const getViolations = (error: ValidationError): ViolationResolved[] => {
    try {
        const firstBody = error.body?.[0];
        const keys = firstBody && typeof firstBody === 'object' ? Object.keys(firstBody) : [];

        const result = keys.map((key) => {
            if (key.endsWith('violations')) {
                const violations = firstBody[key];
                if (Array.isArray(violations)) {
                    return violations.map((violation) => {
                        const vKeys = Object.keys(violation);
                        // @ts-ignore
                        return vKeys.reduce((acc, vKey) => {
                            if (vKey.endsWith('message'))
                                return {
                                    ...acc,
                                    message: violation[vKey][0]['@value'],
                                };
                            else if (vKey.endsWith('propertyPath'))
                                return {
                                    ...acc,
                                    propertyPath: violation[vKey][0]['@value'],
                                };
                            else {
                                return {};
                            }
                        }, {});
                    });
                }
            }
            return undefined;
        });

        // @ts-ignore
        const filteredValidations = result.filter((r) => !!r);
        // @ts-ignore
        if (filteredValidations && filteredValidations.length > 0) {
            // @ts-ignore
            return filteredValidations[0];
        }
    } catch (e) {
        console.log(e);
    }

    return [];
};

export const getViolationFromJsonError = (error: ValidationJsonError): ValidationJson | null => {
    return error.violations.length > 0 ? error.violations[0] : null;
};
