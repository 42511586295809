import { MetadataAvatarType } from '../types';

export const getNameWithoutPrefix = (nameWithPrefix: string) => {
    if (nameWithPrefix) {
        const r = nameWithPrefix.split('-prefix-');
        const R = nameWithPrefix.split('-PREFIX-');
        if (Array.isArray(r) && r.length > 1) {
            return r.reverse()[0];
        }
        if (Array.isArray(R) && R.length > 1) {
            return R.reverse()[0];
        }
    }
    return nameWithPrefix;
};

export const getNameWithoutPrefixFromJson = (metadataAvatarType: MetadataAvatarType) => {
    if (metadataAvatarType && 'name' in metadataAvatarType) {
        const name = metadataAvatarType.name;
        // return alphaId ? `${getNameWithoutPrefix(name)} (${alphaId})` : getNameWithoutPrefix(name);
        return getNameWithoutPrefix(name);
    }

    return '';
};
