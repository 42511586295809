import { put, select, takeLatest } from 'redux-saga/effects';
import { DataProvider } from '../../../providers/dataProvider';
import { AuthProvider } from '../../../providers/authProvider';
import { resolvedUserConnected, tokenFound } from '../../actions/users';
import { GetListResult, Record, ReduxState } from 'react-admin';
import { USERS } from '../../../providers/resources';
import { CustomReducerState } from '../../reducers';
import { TokenDataDecoded } from '../../../utils/token';
import { User } from '../../../utils/types';

export const resolveUserConnected = (hydraDataProvider: DataProvider, authProvider: AuthProvider) =>
    function* () {
        const tokenData: TokenDataDecoded | null = yield select(
            (state: ReduxState & CustomReducerState) => state.rikutec.users.tokenData
        );
        if (tokenData) {
            const result: GetListResult = yield hydraDataProvider.getList(USERS, {
                filter: {
                    serialization_groups: ['read_user', 'image', 'read_company'],
                    isSemiAnonymous: false,
                    wcCompanyId: tokenData.company_id,
                    username: tokenData.username,
                },
                sort: {
                    field: 'id',
                    order: 'DESC',
                },
                pagination: { page: 1, perPage: 1 },
            });

            if (result && result.total > 0) {
                console.log('data', result.data);

                const userConnected: Record | User | undefined = result.data.find(
                    (item: Record | User) => item?.originId === tokenData.user_id
                );

                if (userConnected) {
                    yield put(resolvedUserConnected.fn(userConnected));
                }
            }
        }
    };

const userProvider = (hydraDataProvider: DataProvider, authProvider: AuthProvider) =>
    function* () {
        yield takeLatest(tokenFound.action, resolveUserConnected(hydraDataProvider, authProvider));
    };

export default userProvider;
