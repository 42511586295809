import React from 'react';
import {
    Filter,
    TextInput,
    useTranslate
} from "react-admin";


const ProductTypeActionsFilters = (props: any) => {
    const t = useTranslate()
    return (
        <Filter {...props} data-testid="ProductTypeActionsFilter">
            <TextInput
                size="small"
                variant="standard"
                label={t('app.productType.name')}
                resettable
                source='name'/>
        </Filter>
    );
}

export default ProductTypeActionsFilters;
