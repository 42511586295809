export const SLUDGE_EMPTYING = 'emptying';
export const MAINTENANCE = 'maintenance';
export const SPANC = 'span';

export const LEVEL_1 = 'level1';
export const LEVEL_2 = 'level2';
export const LEVEL_3 = 'level3';
export const LEVEL_4 = 'level4';
export const LEVEL_5 = 'level5';

export const COLOR_LEVEL_1 = '#00FF00';
export const COLOR_LEVEL_2 = '#FFA500';
export const COLOR_LEVEL_3 = '#FF0000';
export const COLOR_LEVEL_4 = '#ba5426';
export const COLOR_LEVEL_5 = '#be1622';

export const THRESHOLD_LEVEL1_NAME = 'threshold.level.1';
export const THRESHOLD_LEVEL2_NAME = 'threshold.level.2';
export const THRESHOLD_LEVEL3_NAME = 'threshold.level.3';
export const THRESHOLD_LEVEL4_NAME = 'threshold.level.4';
export const THRESHOLD_LEVEL5_NAME = 'threshold.level.5';
