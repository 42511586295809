export const resolveApiSchemaCreator = {
    action: '360SC_RIKUTEC/RESOLVE_API_SCHEMA',
    fn: () => ({
        type: resolveApiSchemaCreator.action,
        payload: {},
    }),
};

export const resolvedApiSchemaCreator = {
    action: '360SC_RIKUTEC/RESOLVED_API_SCHEMA',
    fn: (apiSchema: any, dataProviderKey: string) => ({
        type: resolvedApiSchemaCreator.action,
        payload: { apiSchema, dataProviderKey },
    }),
};
