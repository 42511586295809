import React, { useEffect, useState } from 'react';
import { extractIdFromURI } from '../../../utils/resources/ids';
import { Record, ReduxState, useLoading, useNotify, usePermissions, useTranslate } from 'react-admin';
import { ReactComponent as UploadFileIcon } from '../../../upload_file_black_24dp.svg';
import { ReactComponent as EditIcon } from '../../../edit-icon.svg';
import { LinearProgress, makeStyles } from '@material-ui/core';
import MediaCellForm from './MediaCellForm';
import { MEDIA_TAGS } from '../../../providers/resources';
import { useDispatch, useSelector } from 'react-redux';
import { CustomReducerState } from '../../../redux/reducers';

interface MediaCellProps {
    record: Record; // the object for which to search the media
    mediaTag: MEDIA_TAGS; // the current media tag to search for
    objectType: string; // the object type for which to search the media
    reduxUpdater: (object: any, tag: MEDIA_TAGS, media: any) => any; // a reducer to update the media state
    // in the store
    mediasObjectContainer: string; // property in the object where the medias are located
    storeContainer: string; // the key in the rikutec store where the object's data is saved
}

const useStyles = makeStyles(() => ({
    approvalIcons: {
        minWidth: 'fit-content',
        width: '18px !important',
        height: '18px !important',
        boxSizing: 'border-box',
        marginLeft: '5px',
        color: '#3F51B5',
        cursor: 'pointer',
    },
}));

const getNameWithoutPrefix = (name: string) => {
    return name.split('*_*').pop();
};

const MediaCell = ({
    record,
    mediaTag,
    objectType,
    reduxUpdater,
    mediasObjectContainer,
    storeContainer,
}: MediaCellProps) => {
    const [mediaState, setMediaState] = useState<{ mediaId: string | undefined; mediaName: string | undefined }>({
        mediaId: undefined,
        mediaName: undefined,
    });
    const [dlgState, setDlgState] = useState<{ isOpen: boolean }>({ isOpen: false });
    const knownMedias = useSelector(
        (state: CustomReducerState) =>
            // @ts-ignore
            state.rikutec[storeContainer].data[record['@id']]?.item[mediasObjectContainer]
    );
    const fsm = useSelector(
        (state: CustomReducerState) =>
            // @ts-ignore
            state.rikutec[storeContainer].data[record['@id']]?.medias[mediaTag]?.fsm
    );
    const timestamp = useSelector(
        (state: CustomReducerState) =>
            // @ts-ignore
            state.rikutec[storeContainer].data[record['@id']]?.medias[mediaTag]?.timestamp
    );
    const viewVersion = useSelector(
        (state: ReduxState) =>
            // @ts-ignore
            state.admin.ui.viewVersion
    );
    const t = useTranslate();
    const classes = useStyles();
    const notify = useNotify();
    const raLoading = useLoading();
    const dispatch = useDispatch();
    const { permissions } = usePermissions();
    const [loading, setLoading] = useState(false);
    const token = localStorage.getItem('rikutec_token')!!;
    const fileSystemMapperUrl = `${process.env.REACT_APP_FILE_SYSTEM_MAPPER_ENTRYPOINT}`
        .concat('/smartConnectView/file_system_mappers')
        .concat(`?lvl=5&tags.name=${mediaTag}&isFolder=false`)
        .concat(`&parent.name=system&parent.parent.name=sc:folder-${objectType}:${extractIdFromURI(record['@id'])}`);
    const searchForMedia = () => {
        if (!raLoading && record[mediasObjectContainer] !== knownMedias) {
            setLoading(true);
            fetch(fileSystemMapperUrl, {
                headers: { Authorization: `Bearer ${token}` },
            })
                .then((response) =>
                    response.json().then((media) => {
                        if ('hydra:member' in media && media['hydra:member'].length > 0) {
                            const fileMedia = record[mediasObjectContainer]?.find((item: string) =>
                                media['hydra:member'].some(
                                    (token: any) =>
                                        token.metadata['metadata.extra']['coreapi.mediaid'] === extractIdFromURI(item)
                                )
                            );
                            if (fileMedia) {
                                setMediaState({
                                    mediaId: fileMedia ?? undefined,
                                    mediaName: fileMedia
                                        ? getNameWithoutPrefix(
                                              media['hydra:member']
                                                  .find(
                                                      (item: any) =>
                                                          item.metadata['metadata.extra']['coreapi.mediaid'] ===
                                                          extractIdFromURI(fileMedia)
                                                  )
                                                  .metadata['metadata.cloud'].name.split('*_*')[1]
                                          )
                                        : undefined,
                                });
                                dispatch(
                                    reduxUpdater(
                                        record,
                                        mediaTag,
                                        media['hydra:member'].find(
                                            (item: any) =>
                                                item.metadata['metadata.extra']['coreapi.mediaid'] ===
                                                extractIdFromURI(fileMedia)
                                        )
                                    )
                                );
                            } else {
                                dispatch(reduxUpdater(record, mediaTag, undefined));
                            }
                        } else {
                            dispatch(reduxUpdater(record, mediaTag, undefined));
                        }
                        setLoading(false);
                    })
                )
                .catch((err) => {
                    notify(t('app.messages.error'), 'error');
                    console.log(JSON.stringify(err));
                    setLoading(false);
                });
        } else {
            fsm &&
                setMediaState({
                    mediaId: record[mediasObjectContainer].find(
                        (item: string) => fsm.metadata['metadata.extra']['coreapi.mediaid'] === extractIdFromURI(item)
                    ),
                    mediaName: getNameWithoutPrefix(fsm.metadata['metadata.cloud'].name),
                });
        }
    };
    const toggleDlg = () => setDlgState({ isOpen: false });

    useEffect(
        searchForMedia,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [fileSystemMapperUrl, token, record, mediasObjectContainer, mediaTag, timestamp, viewVersion]
    );
    return (
        <div data-testid='MediaCell'>
            {loading && <LinearProgress color='primary' style={{ width: '60px' }} />}
            {!loading &&
                (mediaState.mediaId ? (
                    <span style={{ display: 'inline-flex', justifyContent: 'center', alignContent: 'center' }}>
                        {t('app.yes')}
                        {permissions?.some((item: string) => item === 'ROLE_FM_CLOUD_WRITE') && (
                            <EditIcon className={classes.approvalIcons} onClick={() => setDlgState({ isOpen: true })} />
                        )}
                    </span>
                ) : (
                    <span style={{ display: 'inline-flex', justifyContent: 'center', alignContent: 'center' }}>
                        {t('app.no')}
                        {permissions?.some((item: string) => item === 'ROLE_FM_CLOUD_WRITE') && (
                            <UploadFileIcon
                                className={classes.approvalIcons}
                                onClick={() => setDlgState({ isOpen: true })}
                            />
                        )}
                    </span>
                ))}
            <MediaCellForm
                isOpen={dlgState.isOpen}
                toggle={toggleDlg}
                toBeReplaced={mediaState.mediaId}
                toBeReplacedName={mediaState.mediaName}
                objectType={objectType}
                mediaTag={mediaTag}
                storeContainer={storeContainer}
                mediasContainer={mediasObjectContainer}
            />
        </div>
    );
};

export default MediaCell;
