import Delete from '@material-ui/icons/Delete';
import FiberNew from '@material-ui/icons/FiberNew';
import Settings from '@material-ui/icons/Settings';
import React, { Fragment, useState } from 'react';
import { ReduxState, useTranslate } from 'react-admin';
import { useSelector } from 'react-redux';
import { CustomReducerState } from '../../../../redux/reducers';
import MenuButtonActions from '../../../UI/MenuButtonActions/MenuButtonActionsContainer';
import ManagementThresholdsForm from './create/form';
import ManagementThresholdsDeleteForm from './delete';
import ManagementThresholdsEditForm from './edit/form';

export default function SimpleActionMenu() {
    const translate = useTranslate();

    const [menuState, setMenuState] = useState(false);

    const [showManagementThresholdsCreateForm, setShowManagementThresholdsCreateForm] = useState<boolean>(false);
    const [showManagementThresholdsEditForm, setShowManagementThresholdsEditForm] = useState<boolean>(false);

    const [showManagementThresholdsDeleteForm, setShowManagementThresholdsDeleteForm] = useState<boolean>(false);

    const [, setAnchorEl] = React.useState(null);

    const handleToggle = () => {
        setMenuState(!menuState);
    };

    const showManagementThresholdsCreateFormFn = () => {
        setShowManagementThresholdsCreateForm(true);
        setAnchorEl(null);
    };

    const showManagementThresholdsEditFormFn = () => {
        setShowManagementThresholdsEditForm(true);
        setAnchorEl(null);
    };

    const showManagementThresholdsDeleteFormFn = () => {
        setShowManagementThresholdsDeleteForm(true);
        setAnchorEl(null);
    };

    const {
        rikutec: {
            thresholds: { selected: thresholdSelected },
        },
    } = useSelector((state: CustomReducerState) => state);
    const {
        admin: {
            resources: {
                traceability_notes_threshold_executions: {
                    list: { ids },
                },
            },
        },
    } = useSelector((state: ReduxState) => state);

    const menuActions = [
        {
            icon: <FiberNew />,
            text: translate('app.traceabilityNotesThreshold.actions.addEvent'),
            onClick: showManagementThresholdsCreateFormFn,
        },
        {
            icon: <Settings />,
            text: translate('app.traceabilityNotesThreshold.actions.editEvent'),
            onClick: showManagementThresholdsEditFormFn,
            disabled: !thresholdSelected || !ids.includes(thresholdSelected!!.id!!),
        },
        {
            icon: <Delete />,
            text: translate('app.traceabilityNotesThreshold.actions.deleteEvent'),
            onClick: showManagementThresholdsDeleteFormFn,
            disabled: !thresholdSelected || !ids.includes(thresholdSelected!!.id!!),
        },
    ];

    return (
        <Fragment>
            <MenuButtonActions
                buttonLabel={translate('app.traceabilityNotesThreshold.actions.title')}
                overwriteButtonLabel
                menuActions={menuActions}
                menuState={menuState}
                onButtonClick={handleToggle}
            />

            {showManagementThresholdsCreateForm && (
                <ManagementThresholdsForm
                    open={showManagementThresholdsCreateForm}
                    handleClose={() => setShowManagementThresholdsCreateForm(false)}
                />
                // <ManagementThresholdsForm
                //     open={showManagementThresholdsCreateForm}
                //     handleClose={() =>
                //         setShowManagementThresholdsCreateForm(false)
                //     }
                // />
            )}
            {showManagementThresholdsEditForm && (
                <ManagementThresholdsEditForm
                    open={showManagementThresholdsEditForm}
                    handleClose={() => setShowManagementThresholdsEditForm(false)}
                />
            )}
            {showManagementThresholdsDeleteForm && (
                <ManagementThresholdsDeleteForm
                    open={showManagementThresholdsDeleteForm}
                    handleClose={() => setShowManagementThresholdsDeleteForm(false)}
                />
            )}
        </Fragment>
    );
}
