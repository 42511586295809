import {createTheme} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

export const APP_COLOR_PRIMARY = '#3F51B5';
// export const APP_COLOR_PRIMARY_CONTRAST_01 = '#2E5D7D';
// export const APP_COLOR_SECONDARY = '#828387';
export const APP_COLOR_BACKGROUND = '#f5f6f7';
export const APP_ITEM_SELECTED_BACKGROUND_COLOR_ON_LIST = '#e0e0e0';
// export const APP_LOGO_COLOR = '#d8a400';

export const APP_BUTTON_COLOR_PRIMARY = '#3F51B5';
export const APP_TEXT_COLOR_PRIMARY = '#1e7cf2';
export const APP_COLOR_PRIMARY_CHIP = APP_COLOR_PRIMARY;

export const APP_BAR_MAX_HEIGHT = 64;

export const hexToRGBA = (hex, opacity) => {
    return (
        'rgba(' +
        (hex = hex.replace('#', ''))
            .match(new RegExp('(.{' + hex.length / 3 + '})', 'g'))
            .map(function (l) {
                return parseInt(hex.length % 2 ? l + l : l, 16);
            })
            .concat(opacity)
            .join(',') +
        ')'
    );
};

const theme = createTheme({
    primary: {
        main: APP_COLOR_PRIMARY,
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '*': {
                    'scrollbar-width': 'thin',
                },
                '*::-webkit-scrollbar': {
                    width: '4px',
                    height: '4px',
                },
            },
        },
        MuiToolbar: {
            root: {
                // outlineWidth: "thin",
                // borderTop: "1px solid #80808066",
                // borderBottom: "1px solid #80808066",
                'regular + &': {
                    marginTop: 'auto',
                    backgroundColor: 'red',
                },
            },
        },
        RaSaveButton: {
            button: {
                color: APP_BUTTON_COLOR_PRIMARY,
                backgroundColor: 'white',
                border: '1px solid rgba(63, 81, 181, 0.5)',
                // fontSize: 'x-small',
                borderColor: APP_BUTTON_COLOR_PRIMARY,
                '&:hover': {
                    color: 'white',
                    backgroundColor: APP_BUTTON_COLOR_PRIMARY,
                },
            },
        },
        RaToolbar: {
            toolbar: {
                backgroundColor: 'white',
                paddingRight: '0px !important',
                paddingLeft: '0px !important',
            },
            defaultToolbar: {
                justifyContent: 'flex-end',
            },
        },
        RaEdit: {
            noActions: {
                marginTop: '0px !important',
            },
        },
        MuiAppBar: {
            root: {
                boxShadow: 'none !important',
            },
            colorPrimary: {
                color: APP_COLOR_PRIMARY,
                backgroundColor: 'white',
            },
        },

        MuiCheckbox: {
            colorPrimary: {
                color: '#828387',
                '&$checked': {
                    color: '#828387',
                    backgroundColor: 'white',
                    // borderColor: "#828387",
                },
            },
        },
        MuiCardContent: {
            root: {
                paddingBottom: '0px !important',
            },
        },
        MuiButton: {
            root: {
                fontWeight: 'bold'
            },
            containedPrimary: {
                // backgroundColor: APP_COLOR_PRIMARY,
                backgroundColor: APP_BUTTON_COLOR_PRIMARY,
                color: 'white',
                boxShadow: 'none',
                // fontSize: 'x-small',
            },
            outlinedPrimary: {
                color: APP_BUTTON_COLOR_PRIMARY,
                borderColor: APP_BUTTON_COLOR_PRIMARY,
                boxShadow: '1px 4px 13px -3px rgba(0,0,0,0.44)',
            },
        },
        MuiTab: {
            root: {
                color: APP_TEXT_COLOR_PRIMARY,
            },
            textColorPrimary: {
                color: `${APP_TEXT_COLOR_PRIMARY} !important`,
            },
        },
        MuiTabs: {
            indicator: {
                backgroundColor: APP_TEXT_COLOR_PRIMARY,
            },
            fixed: {
                // backgroundColor: "white",
                zIndex: 1,
            },
        },
        MuiIconButton: {
            colorPrimary: {
                color: APP_COLOR_PRIMARY,
            },
        },
        MuiIcon: {
            colorPrimary: {
                color: APP_COLOR_PRIMARY,
            },
        },
        MuiSvgIcon: {
            colorPrimary: {
                color: APP_COLOR_PRIMARY,
            },
        },
        MuiLinearProgress: {
            colorPrimary: {
                color: APP_COLOR_PRIMARY,
            },
        },
        MuiChip: {
            colorPrimary: {
                backgroundColor: `${APP_COLOR_PRIMARY_CHIP} !important`,
            },
            clickableColorPrimary: {
                '&:focus': {
                    backgroundColor: APP_COLOR_PRIMARY_CHIP,
                },
                '&:hover': {
                    backgroundColor: APP_COLOR_PRIMARY_CHIP,
                },
            },
        },
        MuiInput: {
            formControl: {
                'label + &': {
                    marginTop: 'auto',
                },
            },
        },
        MuiInputLabel: {
            formControl: {
                position: 'inherit !important',
            },
        },
        MuiListItem: {
            //used for menu filter
            button: {
                '& > span:first-child': {
                    fontWeight: 'bold',
                    color: APP_COLOR_PRIMARY,
                    textTransform: 'uppercase',
                    // fontSize: '0.8125rem',
                    fontSize: '0.875rem',
                    textOverflow: 'ellipsis',
                },
            },
        },
        MuiTableCell: {
            head: {
                fontWeight: 'bold'
            }
        },
        MuiListItemIcon: {
            root: {
                minWidth: 'fit-content',
                marginRight: '5px',
                width: '20px !important',
                height: '20px !important',
                boxSizing: 'border-box'
            }
        },
        MuiTypography: {
            body1: {
                letterSpacing: 'unset',
                lineHeight: '1.46429em'
            }
        },
        MuiMenuItem: {
            root: {
                minHeight: '24px'
            }
        },
        MuiCardActions: {
            root: {
                boxSizing: "border-box"
            }
        },
        MuiDialogActions: {
            root: {
                padding: '20px !important'
            }
        }
    },

});

export const ActionButton = withStyles({
    root: {
        '&:hover': {
            color: 'white',
            boxShadow: 'none',
            backgroundColor: APP_BUTTON_COLOR_PRIMARY,
        },
        padding: '9.5px 14px',
    },
})(Button);

export const TopToolbarButton = withStyles({
    root: {
        fontWeight: 'bold',
        color: APP_BUTTON_COLOR_PRIMARY,
    },
})(Button);

export const ActionFormButtonStyles = {
    root: {
        backgroundColor: 'white',
        paddingLeft: 21,
        paddingRight: 21,
        paddingTop: 10,
        paddingBottom: 10,
        boxShadow: 'none',
    },
    text: {
        color: APP_BUTTON_COLOR_PRIMARY,
        fontWeight: 'bold',
    },
};

export const OutlineVariantInputStyles = {
    inputMarginDense: {
        paddingTop: '6px !important',
        paddingBottom: '6px !important',
        fontSize: 'medium',
    },
    rootInputPropsInBackgroundColorPrimary: {
        paddingTop: '6px !important',
        paddingBottom: '6px !important',
        fontSize: 'medium',
        // backgroundColor: APP_BUTTON_COLOR_PRIMARY,
        color: APP_BUTTON_COLOR_PRIMARY,
    },
    notchedOutline: {
        borderColor: APP_BUTTON_COLOR_PRIMARY,
    },
    rootOutlined: {
        padding: '0px !important',
        color: APP_BUTTON_COLOR_PRIMARY,
        fontSize: 'medium',
    },
    rootOutlinedInBackgroundColorPrimary: {
        color: APP_BUTTON_COLOR_PRIMARY,
        padding: '0px !important',
        fontSize: 'medium',
    },
    rootInputPropsInBackgroundColorWhite: {
        paddingTop: '6px !important',
        paddingBottom: '6px !important',
        fontSize: 'medium',
        color: 'white',
        backgroundColor: 'white',
    },
    notchedOutlineColorWhite: {
        borderColor: 'white',
    },
    rootOutlinedColorWhite: {
        padding: '0px !important',
        color: 'white',
        fontSize: 'medium',
    },
    rootOutlinedInBackgroundColorPrimaryColorWhite: {
        padding: '0px !important',
        color: 'white',
        fontSize: 'medium',
    },
};

export const GridButtonShowViewStyles = {
    gridButtonOpenNewView: {
        position: 'absolute',
        right: 0,
    },
    buttonOpenNewView: {
        backgroundColor: APP_BUTTON_COLOR_PRIMARY,
        paddingLeft: 21,
        paddingRight: 21,
        paddingTop: 10,
        paddingBottom: 10,
        color: 'white',
        fontWeight: 'bold',
        '&:hover': {
            backgroundColor: APP_BUTTON_COLOR_PRIMARY,
            paddingLeft: 21,
            paddingRight: 21,
            paddingTop: 10,
            paddingBottom: 10,
            color: 'white',
            fontWeight: 'bold',
        },
    },
};

export const divContainerList = {
    main: {
        height: `calc(100% - ${APP_BAR_MAX_HEIGHT}px)`,
        marginTop: APP_BAR_MAX_HEIGHT,
        width: '100%',
        padding: '15px',
        paddingBottom: 0
    },
};

export const ThresholdsFormStyles = {
    deleteIcon: {
        color: APP_BUTTON_COLOR_PRIMARY,
    },
    deleteIconDisabled: {
        color: 'gray',
    },
    addButtonRoot: {
        ...ActionFormButtonStyles.root,
    },
    addButtonRootText: {
        ...ActionFormButtonStyles.root,
        fontWeight: 'bold',
    },
    gridItemTitle: {
        marginTop: 4,
        marginBottom: 4,
    },
    itemsGroup: {
        marginBottom: 10,
    },
    dialogButtons: {
        marginBottom: 10,
    },
    inputColor: {
        marginTop: 15,
    },
    addIcon: {
        fontWeight: 'bold',
    },
};

export const DescriptionBar = (theme) => ({
    backDiv: {
        borderTop: '2px solid #C4D600',
        height: '80vh',
        width: '100%',
        overflow: 'auto',
    },
    backDivWithoutHeight: {
        borderTop: '2px solid #C4D600',
        // height: '50vh',
        width: '100%',
        // overflow: 'auto',
    },
    backDivWhite: {
        backgroundColor: 'white',
    },
    backDivWithShadow: {
        backgroundColor: '#f1f1f1 !important',
        boxShadow:
            '0px 1px 3px 0px rgba(0, 0, 0, 0.2),0px 1px 1px 0px rgba(0, 0, 0, 0.14),0px 2px 1px -1px rgba(0, 0, 0, 0.12)',
    },
    backHistoricDiv: {
        backgroundColor: '#f1f1f1',
    },
    root: {
        overflowY: 'auto',
        boxShadow:
            '0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)',
    },
    gridContainer: {
        backgroundColor: 'white',
        padding: '10px',
    },
    containerTitle: {
        // marginBottom: '8px',
        cursor: 'pointer',
    },
    typographyTitle: {
        textTransform: 'uppercase',
        fontWeight: '600',
        fontSize: theme.typography.pxToRem(13),
        color: 'rgba(0, 0, 0, 0.64)',
    },
    cardTitle: {
        fontSize: theme.typography.pxToRem(13),
        fontWeight: 'bold',
        marginBottom: 3,
    },
    cardSubheader: {
        fontSize: theme.typography.pxToRem(13),
        wordBreak: 'break-word',
    },
    cardRoot: {
        paddingTop: 7,
        paddingBottom: 5,
        paddingLeft: 3,
        paddingRight: 3,
    },
    fingersTableCellRoot: {
        padding: '5px',
    },
    links: {
        color: `${theme.palette.primary.main} !important`,
        margin: theme.spacing.unit,
        marginLeft: theme.spacing.unit * 3,
    },
    image: {
        marginLeft: theme.spacing.unit * 3,
    },
    imageButton: {
        width: 'fit-content',
        marginTop: theme.spacing.unit * 2,
        marginLeft: theme.spacing.unit * 3,
    },
    mcTableRoot: {
        // marginLeft: theme.spacing.unit*3,
        // width: 'auto',
        backgroundColor: theme.palette.grey[200],

        width: '100%',
        marginLeft: '0px',
    },
    mcEditButton: {
        width: '35px',
        padding: '0',
    },
    mcTableHeader: {
        paddingLeft: '8px !important',
        paddingRight: '4px !important',
    },
    mcTableCell: {
        paddingLeft: '8px !important',
        paddingRight: '4px !important',
        fontSize: theme.typography.pxToRem(12),
        textTransform: 'uppercase',
    },
    textTrunc: {
        color: 'rgba(0, 0, 0, 0.64)',
        textTransform: 'uppercase',
        overflow: 'hidden',
        position: 'relative',
        lineHeight: '1.2em',
        maxHeight: '2.4em',
        textAlign: 'justify',
        // marginRight: '-1em',
        // paddingRight: '1em',

        marginRight: '0px',
        paddingRight: '0px',

        '&:before': {
            content: "'...'",
            position: 'absolute',
            right: 0,
            bottom: 0,
        },
        '&:after': {
            content: "''",
            position: 'absolute',
            right: 0,
            width: '1em',
            height: '1em',
            marginTop: '0.2em',
            background: theme.palette.grey[200],
        },
    },
    expand: {
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
        marginLeft: 'auto',
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    collapsedContainer: {
        maxHeight: '300px',
        overflowY: 'auto',
        width: '100%',
    },
    gridItemWithSize: {
        paddingTop: '0px !important',
        display: 'flow-root',
        overflowX: 'hidden',
    },
    divHistoric: {
        overflowX: 'hidden',
    },
    removeFlexbasis: {
        flexBasis: 'unset !important',
    },
    tableRowSelected: {
        backgroundColor: '#aaaaaaa6 !important',
    },
});

/*export const DialogExtraData = (theme) => ({
    cardTitle: {
        fontSize: theme.typography.pxToRem(13),
        fontWeight: 'bold',
    },
    cardSubheader: {
        fontSize: theme.typography.pxToRem(13),
        wordBreak: 'break-word',
    },
    cardRoot: {
        paddingTop: '20px',
        paddingBottom: '5px',
        paddingLeft: '5px',
    },
    root: {
        overflowY: 'auto',
    },
    gridItem: {
        paddingTop: '20px !important',
    },
    simpleShowLayout: {
        paddingTop: '0px !important',
        paddingBottom: '0px !important',
        card: {
            fontWeight: 'bold',
            wordBreak: 'break-word',
        },
    },
});*/

export const ContentContainer = () => ({
    root: {
        margin: '0',
        WebkitFlexGrow: 1,
        flexGrow: 1,
    },
    rootOverflow: {
        overflowX: 'auto',
    },
    gridItem: {
        paddingTop: '0px !important',
    },
    gridItemWithSize: {
        paddingTop: '0px !important',
        display: 'flow-root',
        overflowX: 'hidden',
    },
});

export const tableStyles = () => ({
    tableRowSelected: {
        backgroundColor: '#aaaaaaa6 !important',
    },
    cardList: {
        backgroundColor: 'none',
        borderRadius: 0,
        boxShadow: 'none',
        minHeight: 400,
    },
    list: {
        height: 35,
    },
    checkbox: {
        padding: '0px 0px 0px 12px',
    },
    backDiv: {
        borderTop: '2px solid #C4D600',
        backgroundColor: 'white',
        height: '80vh',
        overflowY: 'scroll',
    },
});

export const actionsStyles = (theme) => ({
    icons: {
        marginRight: theme.spacing.unit,
        fontSize: `${theme.spacing.unit * 2}px`,
    },
    cardActions: {
        width: '100%',
        marginTop: 5,
        marginBottom: 5,
        justifyContent: 'flex-start',
        zIndex: 1,
    },
    menuListRoot: {
        padding: 0,
    },
    menuListItemRoot: {
        marginRight: 5,
    },
    menuRoot: {
        padding: '2px 10px 2px 5px',
    },
    menuIcon: {
        fontSize: 20,
    },
    menuTextRoot: {
        padding: 0,
        textTransform: 'uppercase',
        fontWeight: 'bold'
    },
    menuText: {
        fontSize: 13,
        fontWeight: 500,
    },
    rootCheckbox: {
        padding: 0,
        marginRight: theme.spacing.unit,
    },
    multiSelectLabel: {
        color: theme.palette.primary.main,
        textTransform: 'uppercase',
        fontSize: 13,
        fontWeight: 500,
    },
    multiSelectLabelCheckbox: {
        padding: 6,
        color: theme.palette.primary.main,
    },
    formControlLabelRoot: {
        marginLeft: -5,
        marginRight: 5,
    },
    dialogTitle: {
        padding: '17px 20px 0 20px',
        paddingBottom: 15,
    },
    dialogTitleText: {
        textTransform: 'uppercase',
        fontWeight: 'bold',
        fontSize: 17,
    },
    container: {
        display: 'grid',
        gridTemplateColumns: '30px auto',
        alignItems: 'center',
        columnGap: '7px',
    },
    dialogActions: {
        marginRight: 20,
        marginBottom: 20,
    },
    button: {
        paddingLeft: 30,
        paddingRight: 30,
        marginLeft: 10,
    },
    circularProgress: {
        marginTop: '20px',
        marginBottom: '20px',
        overflowY: 'hidden',
    },
    dialogContent: {
        paddingBottom: 0,
    },
    dialogText: {
        color: 'black',
        fontSize: 13,
    },
    buttonMenu: {
        paddingLeft: 10,
    },
});

export default theme;
