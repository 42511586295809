import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ProductTypePage from '../components/ProductTypePage/ProductTypePage';
import Layout from '../components/UI/Layout/Layout';
import useApiSchema from '../hooks/useApiSchema';
import AvatarAncEvery2HoursPage from './AvatarAncEvery2HoursPage';
import AvatarAncPage from './AvatarAncPage';
import { ancs, ancsEvery2Hours, index, product_types, thresholds, types } from './paths';
import RefreshTokenPage from './RefreshTokenPage';
import ThresholdPage from './ThresholdPage';
import TypesPage from './TypesPage';

const RootPage = () => {
    const isApiSchemaLoading = useApiSchema();

    return (
        <Layout>
            {(!isApiSchemaLoading && (
                <Switch>
                    <Route exact path={index} component={(props: any) => <RefreshTokenPage {...props} />} />
                    <Route exact path={thresholds} component={(props: any) => <ThresholdPage {...props} />} />
                    <Route exact path={ancs} component={(props: any) => <AvatarAncPage {...props} />} />
                    <Route
                        exact
                        path={ancsEvery2Hours}
                        component={(props: any) => <AvatarAncEvery2HoursPage {...props} />}
                    />
                    <Route exact path={types} component={(props: any) => <TypesPage {...props} />} />
                    <Route exact path={product_types} component={(props: any) => <ProductTypePage {...props} />} />
                    <Route exact path='/unauthorized' />
                </Switch>
            )) || <></>}
        </Layout>
    );
};

export default RootPage;
