import {
    registerMetadataAvatarTypePropertyRequestExecution
} from "../actions/sync";

export interface SyncState {
    metadataAvatarTypePropertyRequestExecution: {
        // name-metadataAvatarType : index
        [key: string]: number
    }
}

const InitialState: SyncState = {
    metadataAvatarTypePropertyRequestExecution: {}
}

const reducer = (state: SyncState = InitialState, action: any) => {
    switch (action.type) {
        case registerMetadataAvatarTypePropertyRequestExecution.action: {
            const key = `${action.payload.propertyName}-${action.payload.metadataAvatarType}`
            if (!(key in state.metadataAvatarTypePropertyRequestExecution)) {
                return {
                    ...state,
                    metadataAvatarTypePropertyRequestExecution: {
                        ...state.metadataAvatarTypePropertyRequestExecution,
                        [key]: action.payload.index
                    }
                }
            }

            return state
        }
        default:
            return state;
    }
}

export default reducer