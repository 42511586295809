import { TraceabilityNotesThresholdExecutions } from "../../utils/types";
import { Record } from "react-admin";

export interface SelectThresholdsAction {
    type: typeof selectThresholds.action;
    payload: Record | TraceabilityNotesThresholdExecutions | undefined;
}

export const selectThresholds = {
    action: "360SC_RIKUTEC/SELECT_THRESHOLDS",
    fn: (record: Record | undefined): SelectThresholdsAction => ({
        type: selectThresholds.action,
        payload: record
    }),
};
