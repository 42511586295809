import React, { useEffect, useRef, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useDelete, useNotify, useRefresh, useTranslate, useUpdate } from 'react-admin';
import { ReactComponent as UploadFileIcon } from '../../../upload_file_black_24dp.svg';
import { CircularProgress, Input, makeStyles } from '@material-ui/core';
import { postUploadMedia } from '../../../utils/medias';
import { extractIdFromURI } from '../../../utils/resources/ids';
import { FILES, MEDIA_TAG_ROUTINE_MAINTENANCE, MEDIA_TAGS, METADATA_AVATAR_TYPES } from '../../../providers/resources';
import { useSelector } from 'react-redux';
import { CustomReducerState } from '../../../redux/reducers';

interface MediaCellFormProps {
    isOpen: boolean;
    toggle: () => void;
    toBeReplaced?: string;
    toBeReplacedName?: string;
    objectType: string;
    mediaTag: MEDIA_TAGS;
    storeContainer: string;
    mediasContainer: string;
}

const useStyles = makeStyles(() => ({
    dialogTitle: {
        padding: '17px 20px 0 20px',
        paddingBottom: 10,
    },
    container: {
        display: 'grid',
        gridTemplateColumns: '25px auto',
        alignItems: 'center',
        columnGap: '7px',
    },
    dialogTitleText: {
        textTransform: 'uppercase',
        fontWeight: 'bold',
        fontSize: 17,
    },
    fileWrapper: {
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'center',
    },
    fileInput: {
        width: '100%',
    },
    filePreview: {
        marginTop: '15px',
        width: '100%',
    },
    circularProgress: {
        width: '16px !important',
        height: '16px !important',
        marginRight: '7px',
    },
    buttonsContainer: {
        marginTop: '20px',
        textAlign: 'right',
    },
    buttons: {
        '&:first-child': {
            marginRight: '7px',
        },
    },
    helperMessage: {
        fontWeight: 'bold',
        marginTop: '15px',
        marginBottom: '5px',
    },
}));
const MediaCellForm = (props: MediaCellFormProps) => {
    const fileInput = useRef<HTMLInputElement>(null);
    const [dlgState, setDlgState] = useState<{ fileName: string | undefined; selectedFile: File | undefined }>({
        fileName: props.toBeReplacedName,
        selectedFile: undefined,
    });
    const userConnected = useSelector((state: CustomReducerState) => state.rikutec.users.userConnected);
    // @ts-ignore
    const selectedObject = useSelector((state: CustomReducerState) => state.rikutec[props.storeContainer].selected);
    const t = useTranslate();
    const classes = useStyles();
    const refresh = useRefresh();
    const notify = useNotify();
    const [uploadingMedia, setUploadingMedia] = useState(false);
    const [updateObject, { loading: updatingObject }] = useUpdate();
    const [deleteMedia] = useDelete(FILES, props.toBeReplaced, { id: props.toBeReplaced });
    const acceptedMT = props.mediaTag === MEDIA_TAG_ROUTINE_MAINTENANCE ? 'image/*' : 'application/pdf';
    const token = localStorage.getItem('rikutec_token')!!;
    const onFileChange = (fileList: FileList | null) => {
        fileList &&
            fileList.length > 0 &&
            setDlgState((prevState) => ({
                ...prevState,
                fileName: fileList[0].name,
                selectedFile: fileList[0],
            }));
    };
    const postMedia = () => {
        if (dlgState.selectedFile) {
            setUploadingMedia(true);
            const reader = new FileReader();
            reader.onload = async (e) => {
                notify('app.messages.loading', 'info');
                const response = await postUploadMedia(
                    e.target?.result as ArrayBuffer,
                    dlgState.selectedFile!!.name,
                    props.objectType,
                    extractIdFromURI(selectedObject['@id'])!!,
                    token,
                    'FILE',
                    [props.mediaTag],
                    userConnected?.company['@id']
                )
                    .then((response) => ({ result: response, error: null }))
                    .catch((error) => ({ error, result: null }))
                    .finally(() => setUploadingMedia(false));
                if (response.result) {
                    const updatedObject = { ...selectedObject };
                    if (updatedObject[props.mediasContainer] && updatedObject[props.mediasContainer].length > 0) {
                        updatedObject[props.mediasContainer] = selectedObject[props.mediasContainer].filter(
                            (item: string) => item !== props.toBeReplaced
                        );
                        updatedObject[props.mediasContainer].push(response.result['@id']);
                    } else {
                        Object.assign(updatedObject, { [props.mediasContainer]: [response.result['@id']] });
                    }
                    Object.assign(updatedObject, { customFields: {}, identifiers: [] });
                    updateObject(
                        METADATA_AVATAR_TYPES,
                        selectedObject.id,
                        // updatedObject,
                        {
                            [props.mediasContainer]: updatedObject[props.mediasContainer],
                            customFields: updatedObject.customFields,
                            identifiers: updatedObject.identifiers,
                            alphaId: selectedObject.id,
                        },
                        selectedObject,
                        {
                            onSuccess: () => {
                                props.toBeReplaced && deleteMedia();
                                refresh();
                                notify('app.messages.success', 'success');
                                toggle();
                            },
                            onFailure: () => notify('app.messages.error', 'error'),
                        }
                    );
                } else {
                    notify('app.messages.error', 'error');
                }
            };
            reader.readAsDataURL(dlgState.selectedFile);
        }
    };
    const toggle = () => {
        setDlgState({ fileName: undefined, selectedFile: undefined });
        props.toggle();
    };
    useEffect(() => {
        setDlgState((prevState) => ({ ...prevState, fileName: props.toBeReplacedName }));
    }, [props.toBeReplacedName, props.isOpen]);
    return (
        <Dialog open={props.isOpen} onClose={toggle} fullWidth maxWidth='sm' data-testid='MediasForm'>
            <DialogTitle disableTypography className={`${classes.dialogTitle} ${classes.container}`}>
                <UploadFileIcon color='primary' />
                <Typography color='primary' className={classes.dialogTitleText}>
                    {t(
                        `app.medias.add${props.mediaTag
                            .split('_')
                            .map((item) => `${item.slice(0, 1).toUpperCase()}${item.slice(1)}`)
                            .join('')}`
                    )}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <div className={classes.fileWrapper}>
                    <Input
                        className={classes.fileInput}
                        type='text'
                        placeholder={t('app.medias.mediaFile')}
                        value={dlgState.fileName}
                        disabled={uploadingMedia || updatingObject}
                        onClick={() => fileInput.current?.click()}
                    />
                    <input
                        type='file'
                        hidden
                        ref={fileInput}
                        accept={acceptedMT}
                        onChange={({ target: { files } }) => onFileChange(files)}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant='text' color='primary' onClick={toggle}>
                    {t('app.button.cancel')}
                </Button>
                <Button
                    variant='contained'
                    color='primary'
                    disabled={!dlgState.selectedFile || uploadingMedia || updatingObject}
                    onClick={() => postMedia()}
                >
                    {(uploadingMedia || updatingObject) && (
                        <CircularProgress className={classes.circularProgress} color='primary' />
                    )}
                    {t('app.button.confirm')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default MediaCellForm;
