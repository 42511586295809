import {withStyles} from "@material-ui/core/styles";
import compose from "recompose/compose";
import CollapseMenu from "./CollapseMenu";
import {DescriptionBar as stylesDescription} from "../../../theme/customTheme";

const styles = (theme) => ({
    ...stylesDescription(theme),
    iconInfo: {
        color: "#000000",
    },
    iconItem: {
        padding: '0 !important',
        margin: 0,
        display: 'inline-flex'
    },
    expandButton: {
        padding: 5,
    },
    gridContainer: {
        backgroundColor: "white",
        paddingTop: 5,
        paddingLeft: 5,
        paddingBottom: 5,
        // borderBottom: "2px solid black",
    },
    collapsedContainer: {
        ...stylesDescription(theme).collapsedContainer,
        maxHeight: "none",
    },
    defaultCursor: {
        cursor: "auto",
    },
    divContainer: {
        margin: 8,
    },
});

const enhance = compose(withStyles(styles));

export default enhance(CollapseMenu);
