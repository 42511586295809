import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(() => ({
    dialogContentRoot: {
        overflowX: 'hidden',
        padding: '0 20px 0px 20px !important',
    },
    header: {
        '& h2': {
            color: '#3F51B5',
            display: 'flex'
        }
    },
    headerIcon: {
        marginRight: '5px'
    },
    headerText: {
        textTransform: "uppercase",
        fontWeight: 'bold'
    }
}))

export default useStyles