import { CardHeader, CircularProgress, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDataProvider, useLoading, useLocale, useNotify, useTranslate } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import { LANGUAGES } from '../../../providers/resources';
import { storeLanguage } from '../../../redux/actions/languages.actions';
import { CustomReducerState } from '../../../redux/reducers';
import { MetadataAvatarTypeProperty } from '../../../utils/types';

const TypeProperty = (props: { property: MetadataAvatarTypeProperty; classes: any }) => {
    const data = useSelector((state: CustomReducerState) => state.rikutec.languages.data);

    const t = useTranslate();
    const locale = useLocale();
    const loading = useLoading();
    const notify = useNotify();
    const [propertyLabel, setPropertyLabel] = useState(props.property.name);
    const dataProvider = useDataProvider();
    const dispatch = useDispatch();
    const getLanguage = () => {
        if (props.property.languages) {
            for (let languageId of props.property.languages) {
                if (!data.some((item) => item.id === languageId)) {
                    dataProvider.getOne(
                        LANGUAGES,
                        {
                            id: languageId,
                        },
                        {
                            onSuccess: (response) => {
                                dispatch(storeLanguage(response.data));
                                response.data.twoLetters === locale && setPropertyLabel(response.data.value);
                            },
                            onFailure: () => notify(t('app.errors.languages'), 'error'),
                        }
                    );
                } else {
                    const token = data.find((item) => item.id === languageId && item.twoLetters === locale);
                    token && setPropertyLabel(token.value);
                }
            }
        }
    };
    const PropertyHeader = () => (
        <span>
            {propertyLabel}
            {loading && (
                <CircularProgress style={{ width: '10px', height: '10px', marginLeft: '7px' }} color='primary' />
            )}
        </span>
    );
    //eslint-disable-next-line
    useEffect(getLanguage, [props.property]);

    const renderSubheader = () => {
        if (props.property.hasOwnProperty('values') && props.property.values.length > 0) {
            if (props.property.fieldType !== 'boolean') {
                return (
                    <Typography className={props.classes.cardSubheader} color={'textSecondary'}>
                        {props.property.values[0]} {props.property.unit}
                    </Typography>
                );
            } else {
                return (
                    <Typography className={props.classes.cardSubheader} color={'textSecondary'}>
                        {props.property.values[0] ? t('app.yes') : t('app.no')}
                    </Typography>
                );
            }
        } else {
            return t('app.types.empty');
        }
    };
    return (
        <CardHeader
            classes={{
                root: props.classes.cardRoot,
                title: props.classes.cardTitle,
                subheader: props.classes.cardSubheader,
            }}
            title={<PropertyHeader />}
            subheader={renderSubheader()}
        />
    );
};
export default TypeProperty;
