import { put, takeLatest } from 'redux-saga/effects';
import { DataProvider } from '../../../providers/dataProvider';
import { AuthProvider } from '../../../providers/authProvider';
import { resolveApiSchemaCreator, resolvedApiSchemaCreator } from '../../actions/hydraDataProvider';

export const resolveApiSchema = (hydraDataProvider: DataProvider, authProvider: AuthProvider) =>
    // @ts-ignore
    function* () {
        // @ts-ignore
        const [dataProviderResult, ssDataProviderResult] = yield hydraDataProvider.introspect();

        if (dataProviderResult && 'data' in dataProviderResult) {
            yield put(resolvedApiSchemaCreator.fn(dataProviderResult.data, 'dataProvider'));
        } else {
            console.log('Error requesting api schema for dataProvider');
        }

        if (ssDataProviderResult && 'data' in ssDataProviderResult) {
            yield put(resolvedApiSchemaCreator.fn(ssDataProviderResult.data, 'ssDataProvider'));
        } else {
            console.log('Error requesting api schema for ssDataProvider');
        }
    };

const hydraProdiver = (hydraDataProvider: DataProvider, authProvider: AuthProvider) =>
    function* () {
        yield takeLatest(resolveApiSchemaCreator.action, resolveApiSchema(hydraDataProvider, authProvider));
    };

export default hydraProdiver;
