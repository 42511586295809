import React, {useEffect, useState} from 'react';
import {Avatar, MCNote} from "../../utils/types";
import MAvatar from "@material-ui/core/Avatar";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {useSelector} from "react-redux";
import {CustomReducerState} from "../../redux/reducers";

interface ShowLastNoteIconProps {
	record: Avatar,
	tag: string
}

const useStyles = makeStyles((theme) => ({
	small: {
		width: theme.spacing(3),
		height: theme.spacing(3),
	},
	center: {
		marginLeft: 'auto',
		marginRight: 'auto'
	}
}));

const DEFAULT_COLOR = '#808080'

const makeColorUseStyles = (color: string) => makeStyles((theme) => ({
	color: {
		color: theme.palette.getContrastText(color),
		backgroundColor: color
	},
}));

const ShowLastNoteIcon = (props: ShowLastNoteIconProps) => {
	const [color, setColor] = useState(DEFAULT_COLOR);

	const ancLastNote: MCNote|null = useSelector((state: CustomReducerState) => {
		const key = `${props.tag}-${props.record.id}`;
		if (key in state.rikutec.ancs.helps.lastNotes) {
			const value = state.rikutec.ancs.helps.lastNotes[key];
			const notes = value.notes;

			const noteIds = typeof notes === 'object' ? Object.keys(notes) : [];
			const noteId = noteIds.length > 0 ? noteIds[0] : null

			return noteId ? notes[noteId] : null
		}

		return null;
	})

	useEffect(() => {
		const color = ancLastNote?.data.customFields?.thresholdColor;

		if (color) setColor(color);
	}, [ancLastNote])

	const classes = useStyles();
	const colorClasses = makeColorUseStyles(color ? color : DEFAULT_COLOR)();

	return <MAvatar className={`${classes.small} ${colorClasses.color} ${classes.center}`}>
		<Typography variant="h1" component="h2"/>
	</MAvatar>
}

export default ShowLastNoteIcon;