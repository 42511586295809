import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useEffect } from 'react';
import {
    AutocompleteInput,
    DateInput,
    Filter,
    FunctionField,
    Query,
    ReferenceInput,
    TextInput,
    useListContext,
    useTranslate,
} from 'react-admin';
import { useSelector } from 'react-redux';
import { GROUP_METADATA_AVATAR_TYPES, METADATA_AVATAR_TYPES, RUNNER_TEMPLATES } from '../../../providers/resources';
import { CustomReducerState } from '../../../redux/reducers';
import { getNameWithoutPrefixFromJson as getGroupMetadataAvatarTypeNameWithoutPrefixFromJson } from '../../../utils/resources/GroupMetadataAvatarType';
import { getNameWithoutPrefixFromJson } from '../../../utils/resources/MetadataAvatarType';
import { getNodes } from '../../../utils/traceability';
import { RunnerTemplate, User } from '../../../utils/types';
import PeriodInput from './PeriodInput';

const filterArray = (data: RunnerTemplate[]) => {
    if (Array.isArray(data)) {
        const nodeNames = data
            .map((runnerTemplate) => getNodes(runnerTemplate.node))
            .reduce((accumulate, nodeList) => {
                return [...accumulate, ...nodeList];
            }, [])
            .reduce((accumulate: any, node: any) => {
                return new Set([...Array.from(accumulate.values()), node.name]);
            }, new Set([]));

        const compareSensitivity = (a: any, b: any) => {
            return typeof a === 'string' && typeof b === 'string'
                ? a.localeCompare(b, 'en', { sensitivity: 'base' }) === 0
                : a === b;
        };
        let nodeNameArray = Array.from(nodeNames);

        let nodeNameArrayClear: any[] = [];
        for (let name of nodeNameArray) {
            if (
                name &&
                typeof name === 'string' &&
                name.trim().length > 0 &&
                !nodeNameArrayClear.some((elem) => compareSensitivity(name, elem))
            ) {
                nodeNameArrayClear = [...nodeNameArrayClear, name];
            }
        }

        /**
         * Needed because ReferenceInput have resource equal to RUNNER_TEMPLATES and need an id
         */
        // const fakeId = data.length > 0 ? data[0].id : null;

        return Array.from(nodeNameArrayClear.values()).map((name) => ({
            id: name,
            name,
        }));
    } else {
        return [];
    }
};

const styles = () => ({
    form: {
        marginLeft: 23,
    },
});
const useStyles = makeStyles(styles);

const SimpleListFilter = (props: any) => {
    const t = useTranslate();
    const userConnected: User | null = useSelector((state: CustomReducerState) => state.rikutec.users.userConnected);
    const classes = useStyles();

    const { setFilters, displayedFilters, filterValues } = useListContext();

    useEffect(() => {
        if (!('note_v0' in displayedFilters)) {
            let newFilters = { ...filterValues };
            delete newFilters['note_v0__createdAt_after'];
            delete newFilters['note_v0__createdAt_before'];
            delete newFilters['note_v0__tags'];
            setFilters(newFilters, displayedFilters);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [displayedFilters]);

    return (
        <Filter
            {...props}
            classes={{
                form: classes.form,
            }}
        >
            {userConnected && (
                <FunctionField
                    alwaysOn={true}
                    source='currentNode.name'
                    label={t('app.ancs.nodeStateName')}
                    render={() => {
                        return (
                            <Query
                                type={'getList'}
                                resource={RUNNER_TEMPLATES}
                                payload={{
                                    pagination: { page: 1, perPage: 100 },
                                    sort: { field: 'id', order: 'DESC' },
                                    filter: {
                                        isDeployed: true,
                                        companyId: userConnected?.company.id,
                                    },
                                }}
                            >
                                {(props) => {
                                    return (
                                        <CustomAutocompleteInputCurrentNode
                                            id={'CustomAutocompleteInputCurrentNode'}
                                            {...props}
                                        />
                                    );
                                }}
                            </Query>
                        );
                    }}
                />
            )}
            <TextInput
                size={'small'}
                variant={'standard'}
                // label={t("app.ancs.serialNumber")}
                label={t('app.ancs.serialNumber')}
                source='palphaId'
                alwaysOn={false}
            />
            <DateInput
                size={'medium'}
                variant={'standard'}
                // label={t("app.ancs.installingDate")}
                source='creationDate'
                label={t('app.ancs.installingDate')}
            />
            <ReferenceInput
                size={'medium'}
                variant={'standard'}
                label={t('app.ancs.name')}
                // source='metadataAvatarType.name'
                source='metadataAvatarType.id'
                reference={METADATA_AVATAR_TYPES}
                alwaysOn={false}
                filterToQuery={(searchText) => ({ name: searchText })}
            >
                <AutocompleteInput optionText={getNameWithoutPrefixFromJson} />
            </ReferenceInput>
            <ReferenceInput
                size={'medium'}
                variant={'standard'}
                label={t('app.ancs.productTypology')}
                source='metadataAvatarType.groupMetadataAvatarType.id'
                reference={GROUP_METADATA_AVATAR_TYPES}
                alwaysOn={false}
                filterToQuery={(searchText) => ({ name: searchText })}
            >
                <AutocompleteInput optionText={getGroupMetadataAvatarTypeNameWithoutPrefixFromJson} />
            </ReferenceInput>

            <PeriodInput source='note_v0' label={t('app.ancs.sludgeEmptyingThresholdFilterDate')} />
            {/*<RadioButtonGroupInput*/}
            {/*    helperText={false}*/}
            {/*    label={"app.traceabilityNotesThreshold.name"}*/}
            {/*    source={encodeURI(`${props.source}[customFieldsThresholdName]`)}*/}
            {/*    choices={thresholdsNameChoices(t)}*/}
            {/*/>*/}

            {/*<ThresholdNameSelectInput*/}
            {/*    source={encodeURI(`${props.source}[customFieldsThresholdName]`)}*/}
            {/*    label={t('app.traceabilityNotesThreshold.name')}*/}
            {/*/>*/}
            {/*{userConnected && (*/}
            {/*    <ReferenceInput*/}
            {/*        filter={{*/}
            {/*            isDeployed: true,*/}
            {/*            companyId: userConnected?.company.id,*/}
            {/*        }}*/}
            {/*        size={'medium'}*/}
            {/*        variant={'standard'}*/}
            {/*        label={t('app.ancs.nodeStateName')}*/}
            {/*        source='currentNode.name'*/}
            {/*        reference={RUNNER_TEMPLATES}*/}
            {/*        alwaysOn={true}*/}
            {/*    >*/}
            {/*        <CustomAutocompleteInputCurrentNode id={'customAutocompleteInputCurrentNode'} />*/}
            {/*    </ReferenceInput>*/}
            {/*)}*/}
        </Filter>
    );
};
const CustomAutocompleteInputCurrentNode = (props: any | undefined) => {
    // const choices = props.hasOwnProperty('choices') ? props.choices : [];
    const choices = props.hasOwnProperty('data') ? props.data : [];
    const t = useTranslate();
    return (
        <AutocompleteInput
            source={'currentNode.name'}
            label={t('app.ancs.nodeStateName')}
            allowEmpty={false}
            clearAlwaysVisible={true}
            resettable={true}
            alwaysOn={true}
            size={'medium'}
            variant={'standard'}
            choices={filterArray(choices)}
            optionText='name'
        />
    );
};
export default SimpleListFilter;
