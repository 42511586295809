import { CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useAuthProvider } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { AuthProvider } from '../providers/authProvider';
import { tokenFound } from '../redux/actions/users';
import { CustomReducerState } from '../redux/reducers';
import { help_no_token_word, redirect_to_ancs_every_2_hours, REDIRECT_TO_QUERY_PARAMS } from './paths';

const RefreshTokenPage = () => {
    const [tokenResolved, setTokenResolved] = useState<boolean>(false);
    const [redirectTo, setRedirectTo] = useState<string | null>(null);
    const user = useSelector((state: CustomReducerState) => state.rikutec.users.userConnected);

    // @ts-ignore
    const authProvider: AuthProvider = useAuthProvider();
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    // @ts-ignore
    let { refreshToken } = useParams();

    useEffect(
        () => {
            if (user && redirectTo) {
                history.push(`/p/${redirectTo}`);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [user, redirectTo]
    );

    const resolveToken = async () => {
        if (!refreshToken || refreshToken === help_no_token_word) {
            refreshToken = localStorage.getItem(
                // @ts-ignore
                process.env.REACT_APP_LOCALSTORAGE_REFRESH_TOKEN_VARIABLE
            );
        }

        if (refreshToken && refreshToken !== help_no_token_word) {
            const tokens = await authProvider.refreshToken({
                refreshToken,
            });
            if (tokens && 'token' in tokens) {
                setTokenResolved(true);
                const searchParams = new URLSearchParams(location.search ? location.search.substring(1) : '');
                const redirectTo = searchParams.has(REDIRECT_TO_QUERY_PARAMS)
                    ? searchParams.get(REDIRECT_TO_QUERY_PARAMS)
                    : redirect_to_ancs_every_2_hours;

                setRedirectTo(redirectTo);

                dispatch(tokenFound.fn(tokens.token));
            }
        } else {
            history.push('/unauthorized');
        }
    };

    useEffect(
        () => {
            resolveToken().finally();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return <div style={{ textAlign: 'center' }}>{!tokenResolved && <CircularProgress />}</div>;
};

export default RefreshTokenPage;
