import { DURATION_TYPE_DAY, DURATION_TYPE_MONTH } from './duractionType';

export const thresholdNameField = 'name';
export const thresholdMinValueField = 'min';
export const thresholdMaxValueField = 'max';
export const thresholdDurationTypeField = 'durationType';
export const thresholdColorField = 'color';
export const thresholdOperationTypeField = 'operation_type';

export interface InputManagementThreshold {
    name: string;
    min: number;
    max: number;
    color: string;
    durationType: string;
}
export const repetitiveThresholdFormFieldNames = ['min', 'max', 'durationType', 'name', 'color'];

/**
 * @param values
 *  keys should be <string>_xxx where xxx is 0,1,2,...n
 * @param restoreThresholds
 */
const extractThresholdValues = (
    values: { [key: string]: string | any },
    restoreThresholds: InputManagementThreshold[]
): InputManagementThreshold[] => {
    const inputKeys = Object.keys(values);
    const restoreThresholdMaxIndex = restoreThresholds.length - 1;

    inputKeys.forEach((inputKey: string) => {
        if (!inputKey) return;

        const splits = inputKey.split('_');

        if (!splits || splits.length !== 2 || !repetitiveThresholdFormFieldNames.includes(splits[0])) {
            return;
        }

        const inputVariable = splits[0];
        const inputIndex: number = parseInt(splits[1]);

        if (inputIndex > restoreThresholdMaxIndex) {
            throw new Error(`RestoreThresholdMaxIndex exceeded`);
        }

        if (restoreThresholds[inputIndex] === null) {
            return;
        }

        // @ts-ignore
        restoreThresholds[inputIndex][inputVariable] = values[inputKey];

        // duration type month
        if (
            restoreThresholds[inputIndex] &&
            (inputVariable === thresholdMinValueField || inputVariable === thresholdMaxValueField) &&
            values[`${thresholdDurationTypeField}_${inputIndex}`] === DURATION_TYPE_MONTH
        ) {
            // @ts-ignore
            restoreThresholds[inputIndex][thresholdMaxValueField] =
                parseInt(values[`${thresholdMaxValueField}_${inputIndex}`]) * 30;

            // @ts-ignore
            restoreThresholds[inputIndex][thresholdMinValueField] =
                parseInt(values[`${thresholdMinValueField}_${inputIndex}`]) * 30;
        }

        // duration type day
        if (
            restoreThresholds[inputIndex] &&
            (inputVariable === thresholdMinValueField || inputVariable === thresholdMaxValueField) &&
            values[`${thresholdDurationTypeField}_${inputIndex}`] === DURATION_TYPE_DAY
        ) {
            // @ts-ignore
            restoreThresholds[inputIndex][thresholdMinValueField] = parseInt(
                values[`${thresholdMinValueField}_${inputIndex}`]
            );

            // @ts-ignore
            restoreThresholds[inputIndex][thresholdMaxValueField] = parseInt(
                values[`${thresholdMaxValueField}_${inputIndex}`]
            );
        }
    });

    return restoreThresholds;
};

export { extractThresholdValues };
