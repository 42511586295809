import { Typography, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

const symbol = '/';

const Breadcrumbs = ({ items, lastItem, classes, subtitle }) => {
    const renderItem = (item) => (
        <Typography className={classes.item} color={'primary'} noWrap>
            {item}
        </Typography>
    );

    const renderSymbol = () => (
        <Typography className={classes.item} color={'primary'} noWrap>
            {symbol}
        </Typography>
    );

    return (
        <div
            style={{
                display: 'flex',
                columnGap: 7,
                marginBottom: 7,
                alignItems: 'end',
            }}
        >
            {items.map((item, index) => (
                <Fragment key={index}>
                    {renderItem(item)}
                    {index + 1 !== items.length && renderSymbol(symbol)}
                </Fragment>
            ))}

            {lastItem && (
                <Fragment>
                    {renderSymbol()}
                    {renderItem(lastItem)}
                </Fragment>
            )}

            {subtitle && (
                <Typography
                    style={{
                        fontSize: 11,
                        marginBottom: 3,
                        marginLeft: 3,
                    }}
                >
                    {subtitle}
                </Typography>
            )}
        </div>
    );
};

Breadcrumbs.propTypes = {
    items: PropTypes.array,
    classes: PropTypes.any,
    lastItem: PropTypes.any,
    subtitle: PropTypes.any,
};

Breadcrumbs.defaultProps = {
    items: [],
};

const styles = () => ({
    root: {
        display: 'grid',
        alignItems: 'end',
        justifyContent: 'start',
        gridTemplateColumns: 'auto auto auto auto auto',
        // marginTop: 9,
        marginBottom: 9,
        columnGap: 7,
    },
    item: {
        fontSize: 18,
        fontWeight: 500,
    },
});

export default withStyles(styles)(Breadcrumbs);
