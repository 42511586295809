import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { cloneElement, useState } from 'react';

const CollapseMenu = ({
    icon,
    title,
    classes,
    headerDataTestSC,
    containerDataTestSC,
    children,
    withCollapse,
    withMargin,
    expanded: expandedProp,
}) => {
    const [expanded, setExpanded] = useState(!withCollapse || expandedProp);

    return (
        <Grid
            container
            className={classes.gridContainer}
            {...(containerDataTestSC ? { 'data-test-sc': containerDataTestSC } : {})}
        >
            <Grid
                container
                spacing={8}
                alignItems='center'
                className={classnames(classes.containerTitle, {
                    [classes.defaultCursor]: !withCollapse,
                })}
                {...(withCollapse ? { onClick: () => setExpanded(!expanded) } : {})}
                {...(headerDataTestSC ? { 'data-test-sc': headerDataTestSC } : {})}
                style={{ margin: 0 }}
            >
                <Grid item className={classes.iconItem}>
                    {cloneElement(icon, {
                        ...icon.props,
                        className: classes.iconInfo,
                        'data-test-sc': 'collapseMenuIcon',
                    })}
                </Grid>
                <Grid item style={{ margin: '0 0 0 10px', padding: 0, display: 'inline-flex' }}>
                    <Typography className={classes.typographyTitle} data-test-sc='collapseMenuTitle'>
                        {title}
                    </Typography>
                </Grid>
                {withCollapse && (
                    <IconButton
                        className={classnames(classes.expand, classes.expandButton, {
                            [classes.expandOpen]: expanded,
                        })}
                        aria-expanded={expanded}
                        aria-label='Show more'
                        data-test-sc='collapseMenuButton'
                        data-testid='collapseMenuButton'
                    >
                        <ExpandMoreIcon />
                    </IconButton>
                )}
            </Grid>

            <Grid container>
                <Grid xs={12}>
                    <Collapse
                        in={expanded}
                        timeout='auto'
                        unmountOnExit
                        classes={{
                            container: classes.collapsedContainer,
                        }}
                    >
                        <div className={classnames({ [classes.divContainer]: withMargin })}>{children}</div>
                    </Collapse>
                </Grid>
            </Grid>
        </Grid>
    );
};

CollapseMenu.propTypes = {
    icon: PropTypes.any,
    title: PropTypes.string,
    headerDataTestSC: PropTypes.string,
    containerDataTestSC: PropTypes.string,
    classes: PropTypes.any,
    children: PropTypes.any,
    withCollapse: PropTypes.bool,
    withMargin: PropTypes.bool,
    expanded: PropTypes.bool,
};

CollapseMenu.defaultProps = {
    withCollapse: true,
    withMargin: false,
    expanded: false,
};

export default CollapseMenu;
