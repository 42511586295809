import { extractIdFromURI } from '../resources/ids';

const keyUris = ['groupMetadataAvatarTypeId', 'metadataAvatarTypeId'];
const uniformFilter = (filterParams: any): any => {
    Object.keys(filterParams).forEach((key: string) => {
        if (keyUris.includes(key)) {
            filterParams[key] = extractIdFromURI(filterParams[key]);
        }
    });

    return filterParams;
};

export { uniformFilter };
