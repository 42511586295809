import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const restHeight = 140;

const BackDiv = ({ classes, children, style, className }) => {
    const [divHeight, setDivHeight] = useState(300);
    const [render, setRender] = useState(false);

    useEffect(() => {
        window.addEventListener('resize', () => setDivHeight(window.innerHeight - restHeight));
        if (typeof window !== 'undefined') setDivHeight(window.innerHeight - restHeight);
        setRender(true);
    }, []);

    return (
        <div
            style={{ height: divHeight, ...(style ? style : {}) }}
            className={classnames(classes.backDiv, {
                [className]: className,
            })}
        >
            {render && typeof children === 'function' ? children({ divHeight }) : children}
        </div>
    );
};

BackDiv.propTypes = {
    classes: PropTypes.any,
    className: PropTypes.any,
    children: PropTypes.any,
    style: PropTypes.any,
};

export default BackDiv;
