import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import DialogTemplate from './DialogTemplate';

const styles = (theme) => ({
    dialogTitle: {
        padding: '17px 20px 15px 20px',
    },
    dialogTitleWithoutBottomPadding: {
        padding: '17px 20px 0 20px',
    },
    dialogTitleText: {
        textTransform: 'uppercase',
        fontWeight: 'bold',
        fontSize: 17,
        marginTop: 2,
    },
    container: {
        display: 'grid',
        alignItems: 'center',
        columnGap: '7px',
    },
    containerWithIcon: {
        gridTemplateColumns: '25px auto',
    },
    containerWithoutIcon: {
        gridTemplateColumns: 'auto',
    },
    dialogActions: {
        marginRight: 20,
        marginBottom: 20,
        marginTop: 20,
    },
    button: {
        paddingLeft: 30,
        paddingRight: 30,
        marginLeft: 10,
    },
    circularProgress: {
        marginTop: '20px',
        marginBottom: '20px',
        overflowY: 'hidden',
    },
    dialogContent: {
        paddingBottom: 0,
        paddingLeft: 21,
        paddingRight: 21,
    },
    dialogText: {
        color: 'black',
        fontSize: 13,
        marginBottom: 10,
    },
    icon: {
        marginLeft: -2,
    },
    overflowHidden: {
        overflow: 'hidden',
    },
});

const enhance = compose(withStyles(styles));

export default enhance(DialogTemplate);
