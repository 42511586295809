import React from "react";
import Typography from "@material-ui/core/Typography";

import {Avatar, makeStyles, Tooltip} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

interface UserInformationProps {
    companyName: string | null;
    userName: string | null;
    userImageSrc: string | null;
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: "grid",
        gridTemplateColumns: "60px auto 60px",
        alignItems: "center",
        backgroundColor: theme.palette.grey[300],
        margin: '10px 9px 0',
        padding: '7px',
    },
    title: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        fontWeight: "bold",
        maxWidth: 200,
        color: "black",
    },
    left: {
        justifySelf: "center",
    },
    center: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        fontWeight: "bold",
        // maxWidth: "130px",
    },
}));

const UserInformation = (props: UserInformationProps) => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <div className={classes.left}>
                {props.userImageSrc && <Avatar src={props.userImageSrc}/>}
            </div>
            <div className={classes.center}>
                {props.userName && (
                    <Grid item>
                        <Tooltip title={props.userName}>
                            <Typography noWrap className={classes.title}>
                                {props.userName}
                            </Typography>
                        </Tooltip>
                    </Grid>
                )}
                {props.companyName && (
                    <Grid item className={classes.title}>
                        <Typography noWrap>{props.companyName}</Typography>
                    </Grid>
                )}
            </div>
            {/*<div className={classes.right}></div>*/}
        </div>
    );
};

export default UserInformation;
