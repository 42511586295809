import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button, makeStyles, Typography
} from "@material-ui/core";
import {useTranslate} from "react-admin";
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles(() => ({
    circularProgress: {
        width: '16px !important',
        height: '16px !important',
        color: 'white',
        marginRight: '7px'
    },
    header: {
        '& h2': {
            display: 'flex',
            color: '#3F51B5'
        }
    },
    headerIcon: {
        marginRight: '5px'
    },
    headerText: {
        textTransform: "uppercase"
    }
}))
const ConfirmDlg = (props: { isOpen: boolean, toggle: () => void, apply: () => void, deleting: boolean }) => {
    const t = useTranslate()
    const classes = useStyles()
    return (
        <Dialog open={props.isOpen} onClose={props.toggle} fullWidth maxWidth="sm">
            <DialogTitle className={classes.header}>
                <DeleteIcon className={classes.headerIcon}/>
                <Typography className={classes.headerText}>{t('app.headers.confirmDelete')}</Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>{t('app.messages.confirmDelete')}</DialogContentText>
                <DialogActions>
                    <Button variant="text" color="primary" onClick={props.toggle}>{t('app.button.cancel')}</Button>
                    <Button variant="contained" color="primary" onClick={props.apply}>
                        {props.deleting &&
                        <CircularProgress className={classes.circularProgress}/>} {t('app.button.confirm')}
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
}

export default ConfirmDlg