import { CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import PropTypes from 'prop-types';
import React from 'react';

const DialogTemplateButtons = ({
    classes,
    buttons,
    withCancel,
    translate,
    withEdit,
    withCreate,
    withBack,
    withNext,
    isSubmitting,
    isValidForm,
    onCancelClick,
    onDeleteClick,
    withDelete,
    createButtonLabel,
    editButtonLabel,
    backButtonLabel,
    deleteButtonLabel,
    nextButtonLabel,
    cancelButtonLabel,
    withBackOnStep,
    withNextOnStep,
    withCreateOnStep,
    withEditOnStep,
    withDeleteOnStep,
    onNextClick,
    onBackClick,
    step,
}) => {
    const renderButton = (onClick, label, props, index) => (
        <div className={classes.wrapper} {...(index !== undefined ? { key: index } : {})}>
            <Button onClick={onClick} className={classes.button} {...props}>
                {label}
            </Button>
            {props.hasOwnProperty('disabled') &&
                props.disabled &&
                props.hasOwnProperty('withLoading') &&
                props.withLoading && (
                    <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                        data-test-sc='dialogTemplateButtonsLoading'
                    />
                )}
        </div>
    );

    const submitProps = {
        color: 'primary',
        variant: 'contained',
        disabled: isSubmitting || !isValidForm,
        type: 'submit',
        withLoading: isSubmitting,
    };

    const disabledProps = {
        disabled: isSubmitting,
    };

    const normalProps = {
        color: 'primary',
        variant: 'contained',
        disabled: isSubmitting || !isValidForm,
        withLoading: isSubmitting,
    };

    const renderCancelButton = () =>
        renderButton(onCancelClick, cancelButtonLabel ? cancelButtonLabel : translate('app.button.cancel'), {
            color: 'primary',
            ...disabledProps,
            'data-test-sc': 'dialogTemplateButtonsCancel',
        });

    const renderDeleteButton = () =>
        renderButton(onDeleteClick, deleteButtonLabel ? deleteButtonLabel : translate('app.main_buttons.delete'), {
            ...normalProps,
            'data-test-sc': 'dialogTemplateButtonsDelete',
        });

    const renderEditButton = () =>
        renderButton(null, editButtonLabel ? editButtonLabel : translate('app.main_buttons.edit'), {
            ...submitProps,
            'data-test-sc': 'dialogTemplateButtonsEdit',
        });

    const renderCreateButton = () =>
        renderButton(null, createButtonLabel ? createButtonLabel : translate('app.main_buttons.create'), {
            ...submitProps,
            'data-test-sc': 'dialogTemplateButtonsCreate',
        });

    const renderNextButton = () =>
        renderButton(onNextClick, nextButtonLabel ? nextButtonLabel : translate('app.main_buttons.next'), {
            ...normalProps,
            'data-test-sc': 'dialogTemplateButtonsNext',
        });

    const renderBackButton = () =>
        renderButton(onBackClick, backButtonLabel ? backButtonLabel : translate('app.main_buttons.back'), {
            ...disabledProps,
            color: 'primary',
            variant: 'contained',
            'data-test-sc': 'dialogTemplateButtonsBack',
        });

    return (
        <DialogActions className={classes.dialogActions}>
            {withCancel && onCancelClick && renderCancelButton()}
            {buttons.map((item, index) => {
                if (!item.hasOwnProperty('step')) {
                    if (item.step === step) {
                        return renderButton(item.onClick, item.label, item.props, index);
                    } else return null;
                } else {
                    return renderButton(item.onClick, item.label, item.props, index);
                }
            })}
            {((withBackOnStep !== undefined && withBackOnStep === step) || withBack) &&
                onBackClick &&
                renderBackButton()}
            {((withNextOnStep !== undefined && withNextOnStep === step) || withNext) &&
                onNextClick &&
                renderNextButton()}
            {((withDeleteOnStep !== undefined && withDeleteOnStep === step) || withDelete) &&
                onDeleteClick &&
                renderDeleteButton()}
            {((withEditOnStep !== undefined && withEditOnStep === step) || withEdit) && renderEditButton()}
            {((withCreateOnStep !== undefined && withCreateOnStep === step) || withCreate) && renderCreateButton()}
        </DialogActions>
    );
};

DialogTemplateButtons.propTypes = {
    classes: PropTypes.any.isRequired,
    buttons: PropTypes.array.isRequired,
    withCancel: PropTypes.bool,
    onCancelClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
    onNextClick: PropTypes.func,
    onBackClick: PropTypes.func,
    withEdit: PropTypes.bool,
    withEditOnStep: PropTypes.number,
    withCreate: PropTypes.bool,
    withCreateOnStep: PropTypes.number,
    withDelete: PropTypes.bool,
    withDeleteOnStep: PropTypes.number,
    withNext: PropTypes.bool,
    withNextOnStep: PropTypes.number,
    withBack: PropTypes.bool,
    withBackOnStep: PropTypes.number,
    step: PropTypes.number,
    translate: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool,
    isValidForm: PropTypes.bool,
    disabled: PropTypes.bool,
    withLoading: PropTypes.bool,
    withRightMargin: PropTypes.bool,
    cancelButtonLabel: PropTypes.string,
    createButtonLabel: PropTypes.string,
    editButtonLabel: PropTypes.string,
    deleteButtonLabel: PropTypes.string,
    nextButtonLabel: PropTypes.string,
    backButtonLabel: PropTypes.string,
};

DialogTemplateButtons.defaultProps = {
    buttons: [],
    onClick: () => null,
    isSubmitting: false,
    isValidForm: true,
};

export default DialogTemplateButtons;
