import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import { MetadataAvatarType, MetadataAvatarTypeProperty } from '../../../utils/types';

interface Props {
    record: MetadataAvatarType;
}

// const useStyles = makeStyles(() => ({
//     approvalIcons: {
//         minWidth: 'fit-content',
//         width: '18px !important',
//         height: '18px !important',
//         boxSizing: 'border-box',
//         marginLeft: '5px',
//         color: '#3F51B5',
//         cursor: 'pointer',
//     },
// }));

const EnableApprovalPropertyField = ({ record }: Props) => {
    const translate = useTranslate();
    // const dataProvider = useDataProvider();

    const [property] = useState<MetadataAvatarTypeProperty | undefined>(undefined);

    // const { loading, data } = useGetList(
    //     METADATA_AVATAR_TYPE_PROPERTIES,
    //     { perPage: 100, page: 1 },
    //     { field: 'id', order: 'asc' },
    //     { metadataAvatarType: record.id, name: ENABLE_APPROVAL_PROPERTY_NAME },
    //     {
    //         onSuccess: (response: GetListResult) => {
    //             if (!response || !('data' in response)) return;
    //             if (response.data.length === 0) return;
    //             setProperty(response.data[0] as MetadataAvatarTypeProperty);
    //         },
    //         onFailure: () => notify(translate('app.types.messages.error'), 'error'),
    //     }
    // );

    // const [updateProperty] = useMutation({
    //     type: 'update',
    //     resource: METADATA_AVATAR_TYPE_PROPERTIES,
    // });
    //
    // const [postProperty] = useMutation({
    //     type: 'create',
    //     resource: METADATA_AVATAR_TYPE_PROPERTIES,
    // });

    const isEnabled = property && property.values.length > 0 && property.values[0];
    // const handleEnable = () => {
    //     updateProperty(
    //         {
    //             payload: {
    //                 id: property?.id,
    //                 data: {
    //                     values: [!isEnabled],
    //                 },
    //             },
    //         },
    //         {
    //             onSuccess: (result: UpdateResult) => {
    //                 notify(translate('app.messages.success'), 'success');
    //                 if (result && result.data) {
    //                     getMetadataAvatarTypeProperty(result.data);
    //                 }
    //             },
    //             onFailure: () => {
    //                 notify(translate('app.errors.updatingProperty'), 'error');
    //             },
    //         }
    //     );
    // };

    // const getMetadataAvatarTypeProperty = (record: Record) => {
    //     dataProvider
    //         .getOne(METADATA_AVATAR_TYPE_PROPERTIES, {
    //             id: record.id,
    //         })
    //         .then((result: GetOneResult) => {
    //             if (result && result.data) {
    //                 setProperty(result.data as MetadataAvatarTypeProperty);
    //             }
    //         });
    // };

    return (
        <>
            {property ? (
                <span style={{ display: 'inline-flex', justifyContent: 'center', alignContent: 'center' }}>
                    {isEnabled ? translate('app.yes') : translate('app.no')}
                    {/*<Tooltip*/}
                    {/*    title={*/}
                    {/*        !isEnabled ? translate('app.types.enableApproval') : translate('app.types.disableApproval')*/}
                    {/*    }*/}
                    {/*>*/}
                    {/*    <EditIcon color={'primary'} className={classes.approvalIcons} onClick={handleEnable} />*/}
                    {/*</Tooltip>*/}
                </span>
            ) : (
                translate('app.no')
            )}
        </>
    );
};

export default EnableApprovalPropertyField;
