import { DataProviderProxy, Identifier } from 'react-admin';
import { MEDIAS } from '../../providers/resources';
import { Media } from '../types';
import { getDataJson } from '../data';

export const FILE_SYSTEM_MAPPER_SOURCE = 'FILE_SYSTEM_MAPPER';

export const getImage = async (media: Media, resourceId: string): Promise<string | null> => {
    const parentName = `sc:folder-${media.customFields.resourceSupported}:${resourceId}`;
    const lvl = 5;

    const entrypoint = process.env.REACT_APP_FILE_SYSTEM_MAPPER_ENTRYPOINT;
    const entrypointUri = process.env.REACT_APP_FILE_SYSTEM_MAPPER_URI_ENTRYPOINT;

    const paramsUrl = `parent.parent.name=${parentName}&lvl=${lvl}&name=${media.name}`;
    let url = `${entrypoint}/smartConnectView/file_system_mappers`;
    const tokenAccess = media.contentUrlTokenAccess;
    url = `${url}?bearer=${tokenAccess}&${paramsUrl}`;

    const fileSystemMapperPromise = getDataJson(url, false, undefined)
        .then((data: any) => {
            const hydraMember =
                data && data.hasOwnProperty('hydra:member') && Array.isArray(data['hydra:member'])
                    ? data['hydra:member']
                    : [];
            return hydraMember.length > 0 ? hydraMember[0] : null;
        })
        .catch(() => {
            return new Error();
        });

    return await fileSystemMapperPromise.then((fileSystemMapper) => {
        if (fileSystemMapper && '@id' in fileSystemMapper) {
            return `${entrypointUri}${fileSystemMapper['@id']}/download?bearer=${tokenAccess}`;
        }
        return null;
    });
};

export const resolveMedia = (
    mediaUri: Identifier,
    dataProvider: DataProviderProxy
    // callbackSuccess: (response: any) => void,
    // callbackFail: (response: any) => void
): Promise<any> =>
    dataProvider.getOne(MEDIAS, { id: mediaUri }).catch(() => ({
        data: {
            id: null,
        },
    }));

export const mediaCameFromToFileSystemMapperService = (media: Media) => {
    if (!media) return false;
    return (
        'source' in media &&
        media.source === FILE_SYSTEM_MAPPER_SOURCE &&
        'contentUrlTokenAccess' in media &&
        media.contentUrlTokenAccess
    );
};

export function postUploadMedia(
    base64: Blob | ArrayBuffer,
    fileName: string,
    resourceSupported: string,
    resourceId: string,
    token: string,
    mediaType: 'IMAGE' | 'FILE' | 'AUDIO' = 'FILE',
    tags: string[],
    destinationUriCompany: string | undefined = undefined
) {
    if (!resourceSupported) throw new Error('You must specify a resource');
    if (!fileName) throw new Error('You must specify a file name');
    if (!base64) throw new Error('The file in base64 format is not present');
    if (!resourceId) throw new Error('You must specify the resource id');

    const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;
    const urlToPost = `${entrypoint}/medias`;

    let body = {
        mediaType,
        resourceSupported,
        resourceId,
        fileName,
        base64data: base64,
        tags,
    };

    destinationUriCompany && Object.assign(body, { destinationCompany: destinationUriCompany });

    return postDataJsonLd(urlToPost, body, token).then((response) => response.json());
}

const postDataJsonLd = (urlToPost: string, body: any = {}, token: string) => {
    return fetch(urlToPost, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
            accept: 'application/ld+json',
            'content-type': 'application/json',
        },
        body: JSON.stringify(body),
    });
};
