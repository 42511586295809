import React, { Children, cloneElement, ReactElement, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useGetList, useRecordContext } from 'react-admin';
import { MC_NOTES } from '../../providers/resources';
import { MC } from '../../utils/types';
import { extractIdFromURI } from '../../utils/resources/ids';
import { useDispatch } from 'react-redux';
import { saveAncsLastNoteFound } from '../../redux/actions/ancs';

interface AvatarNoteReferenceFieldProps {
    label: string;
    tags: string[];
    componentIdentification: string;
    children?: ReactElement;
    headerClassName?: string;
}

const AvatarNoteReferenceField = (props: AvatarNoteReferenceFieldProps) => {
    if (React.Children.count(props.children) !== 1) {
        throw new Error('<AvatarNoteReferenceField> only accepts a single child');
    }

    const record = useRecordContext(props);
    const dispatch = useDispatch();

    const { data } = useGetList(
        MC_NOTES,
        { page: 1, perPage: 1 },
        { field: 'createdAt', order: 'DESC' },
        {
            globalNote_tags: [...props.tags],
            itemId: [
                ...record.mcs
                    .map((mc: MC | string) => {
                        if (typeof mc === 'string') return extractIdFromURI(mc);
                        if (typeof mc === 'object' && 'id' in mc) return mc.id;
                        return null;
                    })
                    .filter((id: string) => !!id),
            ],
        }
    );

    useEffect(
        () => {
            if (data) {
                dispatch(
                    saveAncsLastNoteFound.fn(
                        props.componentIdentification,
                        record.id,
                        props.tags,
                        // @ts-ignore
                        data
                    )
                );
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [data]
    );

    return (
        <AvatarNoteReferenceFieldView
            componentIdentification={props.componentIdentification}
            tags={props.tags}
            record={record}
            notes={data}
            headerClassName={props.headerClassName}
        >
            {props.children}
        </AvatarNoteReferenceFieldView>
    );
};

AvatarNoteReferenceField.propTypes = {
    label: PropTypes.string,
    componentIdentification: PropTypes.string,
    record: PropTypes.object,
    tags: PropTypes.arrayOf(PropTypes.string),
    children: PropTypes.element,
};

export default AvatarNoteReferenceField;

export const AvatarNoteReferenceFieldView = (props: any) => {
    return cloneElement(Children.only(props.children), {
        record: props.record,
        tags: props.tags,
        componentIdentification: props.componentIdentification,
        notes: props.notes,
        headerClassName: props.headerClassName,
    });
};

AvatarNoteReferenceFieldView.propTypes = {
    children: PropTypes.element,
    headerClassName: PropTypes.string,
    record: PropTypes.object.isRequired,
    componentIdentification: PropTypes.string.isRequired,
    tags: PropTypes.arrayOf(PropTypes.string).isRequired,
    notes: PropTypes.object,
};
