import {Identifier} from "react-admin";
import {MCNote} from "../../utils/types";

export const SELECT_ANC = '360SC_RIKUTEC/SELECT_ANC'

export interface SaveAncsLastNoteFoundAction {
    type: typeof saveAncsLastNoteFound.action,
    payload: {
        saveIdentification: string,
        avatarId: Identifier
        tags: string[]
        notes: { [key: Identifier]: MCNote }
    }
}

export interface SelectAncActionType {
    type: typeof SELECT_ANC;
    payload: any;
}

export const saveAncsLastNoteFound = {
    action: '360SC_RIKUTEC/SAVE_ANCS_LAST_NOTE_FOUND',
    fn: (saveIdentification: string, avatarId: Identifier, tags: string[], notes: { [key: Identifier]: MCNote }): SaveAncsLastNoteFoundAction => ({
        type: saveAncsLastNoteFound.action,
        payload: {
            tags, notes, saveIdentification, avatarId
        }
    })
}

export const selectAnc = (anc: any): SelectAncActionType => ({
    type: SELECT_ANC,
    payload: anc
})