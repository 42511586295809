import { Identifier } from "react-admin";

export interface RegisterMetadataAvatarTypePropertyRequestExecution {
    readonly type: typeof registerMetadataAvatarTypePropertyRequestExecution.action,
    payload: {
        propertyName: string,
        metadataAvatarType: Identifier,
        index: number
    }
}

export const registerMetadataAvatarTypePropertyRequestExecution = {
    action: '360SC_RIKUTEC/REGISTER_METADATAAVATARTYPEPROPERTY_REQUEST_EXECUTION',
    fn: (propertyName: string, metadataAvatarType: Identifier, index: number): RegisterMetadataAvatarTypePropertyRequestExecution => ({
        type: registerMetadataAvatarTypePropertyRequestExecution.action,
        payload: {
            propertyName, metadataAvatarType, index
        }
    })
}