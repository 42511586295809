import React from "react";
import DatagridRowCustom from "./DatagridRow";
import { Datagrid, DatagridBody } from "react-admin";
import PropTypes from "prop-types";

const DatagridBodyCustom = ({ toFindSelected, ...props }) => (
    <DatagridBody {...props} row={<DatagridRowCustom toFindSelected={toFindSelected} />} />
);
DatagridBodyCustom.propTypes = {
    toFindSelected: PropTypes.object.isRequired
};
const DataGridCustom = ({ toFindSelected, ...props }) => {
    return <Datagrid {...props} body={<DatagridBodyCustom toFindSelected={toFindSelected} />} />;
};
DataGridCustom.propTypes = {
    toFindSelected: PropTypes.arrayOf(PropTypes.string).isRequired
};
export default DataGridCustom;
