import { Input, makeStyles, Tooltip, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import EditFileIcon from '@material-ui/icons/Edit';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useRef, useState } from 'react';

import {
    FunctionField,
    Identifier,
    List,
    Pagination,
    Record,
    ReduxState,
    TextField,
    useDataProvider,
    useLoading,
    useMutation,
    useNotify,
    useRefresh,
    useTranslate,
    useUpdateLoading,
} from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import { ancs } from '../../pages/paths';
import { AVATARS, MEDIAS, SOURCE_BOARDS, YOURLS } from '../../providers/resources';
import { selectAnc } from '../../redux/actions/ancs';
import { CustomReducerState } from '../../redux/reducers';
import { tableStyles } from '../../theme/customTheme';
import { ReactComponent as UploadFileIcon } from '../../upload_file_black_24dp.svg';
import { toFormatDate } from '../../utils/date';
import { postUploadMedia } from '../../utils/medias';
import { extractIdFromURI } from '../../utils/resources/ids';
import { Avatar, MC, MCNote, MetadataAvatarType, SourceBoard, User } from '../../utils/types';
import BackDiv from '../UI/BackDiv/BackDivContainer';
import Breadcrumbs from '../UI/Breadcrumbs/Breadcrumbs';
import DataGridCustom from '../UI/DataGrid';
import DialogTemplate from '../UI/Dialog/DialogTemplate/DialogTemplateContainer';
import DialogTemplateButtons from '../UI/Dialog/DialogTemplateButtons/DialogTemplateButtonsContainer';
import ActionList from './actions/';
import Filters from './filters/simpleListFilter1';
import ShowLastNoteIcon1 from '../showLastNoteIcon/showLastNoteIcon1';
import { MAINTENANCE } from '../../utils/rikutec';
import { getNameWithoutPrefix } from '../../utils/resources/MetadataAvatarType';

const ListPagination = (props: any) => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

const listProps = {
    resource: SOURCE_BOARDS,
    hasCreate: false,
    hasEdit: false,
    hasList: true,
    hasShow: false,
    basePath: ancs,
    perPage: 50,
    filter: {},
};

export interface EnabledColumns {
    processingCapacity: boolean;
    sludgeStorageVolume: boolean;
    totalUsefulVolume: boolean;
    currentState: boolean;
    model: boolean;
}

type AvatarActions = 'ADD_VP' | 'EDIT_VP' | 'ADD_WC' | 'EDIT_WC' | 'NONE';

const styles = () => ({
    ...tableStyles(),
    dialogContentRoot: {
        overflowX: 'hidden',
        padding: '0 20px 0px 20px !important',
    },
    dialogTitle: {
        padding: '17px 20px 0 20px',
        paddingBottom: 10,
    },
    container: {
        display: 'grid',
        gridTemplateColumns: '25px auto',
        alignItems: 'center',
        columnGap: '7px',
    },
    dialogTitleText: {
        textTransform: 'uppercase',
        fontWeight: 'bold',
        fontSize: 17,
    },
    fileWrapper: {
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'center',
    },
    fileInput: {
        width: '100%',
    },
    filePreview: {
        marginTop: '15px',
        width: '100%',
    },
    circularProgress: {
        width: '16px !important',
        height: '16px !important',
        marginRight: '7px',
    },
    buttonsContainer: {
        marginTop: '20px',
        textAlign: 'right',
    },
    buttons: {
        '&:first-child': {
            marginRight: '7px',
        },
    },
    helperMessage: {
        fontWeight: 'bold',
        marginTop: '15px',
        marginBottom: '5px',
    },
});
//@ts-ignore
const useStyles = makeStyles(styles);
export const AvatarAncList = () => {
    const acceptedMT = 'application/pdf';
    const t = useTranslate();
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const loading = useLoading();
    const { startLoading, stopLoading } = useUpdateLoading();
    const notify = useNotify();
    const userConnected: User | null = useSelector((state: CustomReducerState) => state.rikutec.users.userConnected);
    const selectedAnc: Avatar | Record | null = useSelector((state: ReduxState & CustomReducerState) => {
        const selectedAncUri = (state.rikutec.ancs.selected as Avatar)['@id'];
        return selectedAncUri && selectedAncUri in state.admin.resources[AVATARS].data
            ? state.admin.resources[AVATARS].data[selectedAncUri]
            : null;
    });

    const fileInput = useRef<HTMLInputElement>(null);
    const dispatch = useDispatch();
    const refresh = useRefresh();
    const [dlgState, setDlgState] = useState<{
        isOpen: boolean;
        fileName: string;
        selectedFile: File | undefined;
        oldMedia: any;
    }>({
        isOpen: false,
        fileName: '',
        selectedFile: undefined,
        oldMedia: undefined,
    });
    const [currentAction, setCurrentAction] = useState<AvatarActions>('NONE');
    const [updateAvatarAnc] = useMutation({
        type: 'update',
        resource: AVATARS,
    });
    const token = localStorage.getItem('rikutec_token')!!;
    const fileSystemMapperUrl = `${process.env.REACT_APP_FILE_SYSTEM_MAPPER_ENTRYPOINT}`
        .concat('/smartConnectView/file_system_mappers')
        .concat('?lvl=5&tags.name=&isFolder=false&parent.name=system&parent.parent.name=sc:folder-avatar:');

    setInterval(() => {
        const muiToolbarRoot = document.querySelector('div.list-page div:first-child');
        const form = document.querySelector('div.list-page form:first-child');

        // form has one child by default, so, if there is another child, muiToolbarRoot is setted
        if (form) {
            if (form.children.length > 1 && muiToolbarRoot) {
                //@ts-ignore
                form.style.minHeight = '80px';
                //@ts-ignore
                form.style.alignItems = 'center';
            }
            if (form.children.length === 1) {
                //@ts-ignore
                form.style.minHeight = 'auto';
            }
        }
        if (muiToolbarRoot) {
            //@ts-ignore
            muiToolbarRoot.style.marginLeft = '8px';
            //@ts-ignore
            muiToolbarRoot.style.marginRight = '8px';
            //@ts-ignore
            muiToolbarRoot.style.flexDirection = 'column-reverse';
        }
    }, 500);

    const [enabledColumns, setEnabledColumns] = useState<EnabledColumns>({
        processingCapacity: false,
        sludgeStorageVolume: false,
        totalUsefulVolume: false,
        model: false,
        currentState: true,
    });

    const toggleColumn = (column: string, enabled: boolean) =>
        setEnabledColumns({
            ...enabledColumns,
            [column]: enabled,
        });
    // All Mc Finger of Avatar point to same address
    const simulateFingerMc = (avatarId: string) => {
        dataProvider
            .getList(AVATARS, {
                filter: { id: `/api/avatars/${avatarId}`, serialization_groups: ['read_mc'] },
                pagination: { page: 1, perPage: 1 },
                sort: { field: 'id', order: 'ASC' },
            })
            .then(({ data }: any) => {
                console.log('DATA', data);
                if (Array.isArray(data) && data.length > 0) {
                    const avatar: Avatar = data[0];
                    const mcs = avatar && 'mcs' in avatar ? avatar.mcs : [];
                    if (mcs.length >= 1) {
                        const mc: MC | string = mcs[0];
                        if (typeof mc !== 'string' && 'yourls' in mc) {
                            dataProvider
                                .getOne(YOURLS, { id: mc.yourls })
                                .then(({ data }) => {
                                    data &&
                                        'mcUrl' in data &&
                                        data.mcUrl &&
                                        window.open(data.mcUrl, '_blank', 'width=411px,height=731px');
                                })
                                .catch(() => notify(t('app.ancs.error.server'), 'error'));
                        }
                    }
                }
            })
            .catch(() => notify(t('app.ancs.error.server'), 'error'));
    };

    const toggleDlgIsOpen = () => {
        setDlgState((prevState) => ({
            isOpen: !prevState.isOpen,
            fileName: '',
            selectedFile: undefined,
            oldMedia: undefined,
        }));
    };

    const refreshList = () => {
        refresh();
        notify(t('app.ancs.success.success'), 'success');
        toggleDlgIsOpen();
    };

    const deleteOldMedia = async () => {
        if (dlgState.oldMedia) {
            notify(t('app.messages.loading'), 'info');
            await dataProvider
                .delete(MEDIAS, {
                    id: dlgState.oldMedia,
                    previousData: dlgState.oldMedia,
                })
                .then(() => {
                    notify(t('app.ancs.success.media_updated'), 'success');
                })
                .catch(() => notify(t('app.ancs.error.server'), 'error'));
        }
    };

    const startAction = (action: AvatarActions) => {
        setCurrentAction(action);
        toggleDlgIsOpen();
        if (action.includes('EDIT')) {
            fetch(
                fileSystemMapperUrl
                    .replace('folder-avatar:', `folder-avatar:${extractIdFromURI((selectedAnc as Avatar)['@id'])}`)
                    .replace(
                        '&tags.name=',
                        `&tags.name=${action.includes('VP') ? 'verbal_process' : 'warranty_certificate'}`
                    ),
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            )
                .then((response) =>
                    response.json().then((media) => {
                        if (media['hydra:member'].length > 0) {
                            const fM = (selectedAnc as Avatar).files.find(
                                (item: string) =>
                                    extractIdFromURI(item) ===
                                    media['hydra:member'][0].metadata['metadata.extra']['coreapi.mediaid']
                            );
                            fM &&
                                setDlgState((prevState) => ({
                                    ...prevState,
                                    oldMedia: fM,
                                    fileName: media['hydra:member'][0].name.split('*_*')[1],
                                }));
                        }
                    })
                )
                .catch((err) => {
                    notify(t('app.ancs.error.server'));
                    console.log(JSON.stringify(err));
                });
        }
    };
    const postMedia = (tags: string[], action: 'ADD' | 'REPLACE') => {
        if (dlgState.selectedFile) {
            const reader = new FileReader();
            reader.onload = async (e) => {
                startLoading();
                notify(t('app.messages.loading'), 'info');

                const response = await postUploadMedia(
                    e.target?.result as ArrayBuffer,
                    dlgState.fileName,
                    'avatar',
                    extractIdFromURI((selectedAnc as Avatar)['@id'])!!,
                    token,
                    'FILE',
                    tags,
                    userConnected?.company['@id']
                )
                    .then((response) => {
                        stopLoading();
                        return { result: response, error: null };
                    })
                    .catch((error) => {
                        stopLoading();
                        return { error, result: null };
                    });
                if (response.result) {
                    const updatedAnc = { ...selectedAnc };
                    updatedAnc.files.push(response.result['@id']);
                    updatedAnc.metadataAvatarType = ((selectedAnc as Avatar).metadataAvatarType as MetadataAvatarType)[
                        '@id'
                    ];
                    updateAvatarAnc(
                        { payload: { id: (selectedAnc as Avatar).id, data: updatedAnc } },
                        {
                            onSuccess: async () => {
                                if (action === 'REPLACE') {
                                    await deleteOldMedia();
                                }

                                refreshList();
                            },
                            onFailure: () => notify(t('app.ancs.error.server'), 'error'),
                        }
                    );
                } else {
                    notify(t('app.ancs.error.server'), 'error');
                    console.error(JSON.stringify(response.error));
                }
            };
            reader.readAsDataURL(dlgState.selectedFile!!);
        } else {
            toggleDlgIsOpen();
        }
    };

    const runAction = () => {
        switch (currentAction) {
            case 'EDIT_VP':
                postMedia(['verbal_process'], 'REPLACE');
                break;
            case 'EDIT_WC':
                postMedia(['warranty_certificate'], 'REPLACE');
                break;
            case 'NONE':
                break;
        }
    };
    const dataGridProps = {
        rowClick: (id: string, basePath: string, record: Record) => {
            dispatch(selectAnc(record));
            return null;
        },
        toFindSelected: {
            resource: 'ancs',
            key: 'selected',
        },
        hasBulkActions: null,
        classes: {
            checkbox: classes.checkbox,
            row: classes.list,
        },
    };
    const onFileChange = (fileList: FileList | null) => {
        fileList &&
            fileList.length > 0 &&
            setDlgState((prevState) => ({
                ...prevState,
                fileName: fileList[0].name,
                selectedFile: fileList[0],
            }));
    };
    return (
        <Grid container direction='column'>
            <Grid item>
                <Breadcrumbs items={[t('app.headers.ancs')]} subtitle={t('app.sidebar.every2Hours')} />
            </Grid>

            <BackDiv>
                <Grid item className='ancs-list'>
                    <List
                        {...listProps}
                        pagination={<ListPagination />}
                        filters={<Filters />}
                        actions={
                            <ActionList
                                toggleColumn={toggleColumn}
                                enabledColumns={enabledColumns}
                                runAction={startAction}
                            />
                        }
                        bulkActionButtons={false}
                        empty={false}
                    >
                        <DataGridCustom {...dataGridProps}>
                            <TextField label={t('app.ancs.productTypology')} source='productTypology' />
                            <TextField label={t('app.ancs.articleNumber')} source='productCode' />
                            <FunctionField
                                label={t('app.ancs.metadataAvatarType.name')}
                                // @ts-ignore
                                render={(record: SourceBoard) => (
                                    <span>{getNameWithoutPrefix(record.productTypeName)}</span>
                                )}
                            />

                            {/*<TextField label={t('app.ancs.metadataAvatarType.name')} source='productTypeName' />*/}
                            <TextField label={t('app.ancs.serialNumber')} source='productSerialNumber' />
                            <TextField label={t('app.ancs.postalCode')} source='productPostalCode' />

                            <FunctionField
                                label={t('app.ancs.installingDate')}
                                // @ts-ignore
                                render={(record: SourceBoard) => {
                                    return (
                                        <span>
                                            {record.productInstallationDate
                                                ? toFormatDate(record.productInstallationDate)
                                                : ''}
                                        </span>
                                    );
                                }}
                            />

                            <FunctionField
                                label={t('app.ancs.maintenanceLastDate')}
                                // @ts-ignore
                                render={(record: SourceBoard) => {
                                    return (
                                        <span>
                                            {record.productLastMaintenanceDate
                                                ? toFormatDate(record.productLastMaintenanceDate)
                                                : ''}
                                        </span>
                                    );
                                }}
                            />

                            <FunctionField
                                label={t('app.ancs.sludgeEmptyingLastDate')}
                                // @ts-ignore
                                render={(record: SourceBoard) => {
                                    return (
                                        <span>
                                            {record.productLastEmptyingDate
                                                ? toFormatDate(record.productLastEmptyingDate)
                                                : ''}
                                        </span>
                                    );
                                }}
                            />

                            <FunctionField
                                label={t('app.ancs.maintenanceThreshold')}
                                // @ts-ignore
                                render={(record: SourceBoard) => {
                                    return (
                                        <ShowLastNoteIcon1
                                            record={record.productLastMaintenanceData}
                                            tag={MAINTENANCE}
                                        />
                                    );
                                }}
                            />

                            <FunctionField
                                label={t('app.ancs.sludgeEmptyingThreshold')}
                                // @ts-ignore
                                render={(record: SourceBoard) => {
                                    return (
                                        <ShowLastNoteIcon1 record={record.productLastEmptyingData} tag={MAINTENANCE} />
                                    );
                                }}
                            />

                            {enabledColumns.currentState && (
                                <TextField
                                    sortByOrder={'DESC'}
                                    source={'productCurrentStatusName'}
                                    label={t('app.ancs.nodeCurrentState')}
                                />
                            )}
                            {enabledColumns.currentState && (
                                <FunctionField
                                    sortByOrder={'DESC'}
                                    source={'productCurrentStatusDate'}
                                    label={t('app.ancs.nodeDateOfTakesPlace')}
                                    // @ts-ignore
                                    render={(record: any) => {
                                        return toFormatDate(record.productCurrentStatusDate);
                                    }}
                                />
                            )}

                            {enabledColumns.processingCapacity && (
                                <TextField
                                    sortByOrder={'DESC'}
                                    source={'processingCapacity'}
                                    label={t('app.ancs.processingCapacity')}
                                />
                            )}

                            {enabledColumns.sludgeStorageVolume && (
                                <TextField
                                    sortByOrder={'DESC'}
                                    source={'sludgeStorageVolume'}
                                    label={t('app.ancs.sludgeStorageVolume')}
                                />
                            )}

                            {enabledColumns.totalUsefulVolume && (
                                <TextField
                                    sortByOrder={'DESC'}
                                    source={'totalUsefulVolume'}
                                    label={t('app.ancs.totalUsefulVolume')}
                                />
                            )}

                            {enabledColumns.model && (
                                <TextField sortByOrder={'DESC'} source={'model'} label={t('app.ancs.model')} />
                            )}

                            <FunctionField
                                label={t('app.ancs.accessToFinger')}
                                // @ts-ignore
                                render={(record: SourceBoard) => {
                                    return (
                                        <IconButton onClick={() => simulateFingerMc(record.avatarId)}>
                                            <Tooltip title={t('app.ancs.accessToFinger')}>
                                                <PhoneAndroidIcon />
                                            </Tooltip>
                                        </IconButton>
                                    );
                                }}
                            />
                        </DataGridCustom>
                    </List>
                </Grid>
            </BackDiv>

            <DialogTemplate
                title={t(
                    currentAction.includes('ADD')
                        ? currentAction.includes('VP')
                            ? 'app.ancs.actions.addVP'
                            : 'app.ancs.actions.addWC'
                        : currentAction.includes('VP')
                        ? 'app.ancs.actions.editVP'
                        : 'app.ancs.actions.editWC'
                )}
                open={dlgState.isOpen}
                onClose={toggleDlgIsOpen}
                icon={currentAction.includes('ADD') ? <UploadFileIcon /> : <EditFileIcon />}
                buttons={
                    <DialogTemplateButtons
                        withCancel
                        onCancelClick={toggleDlgIsOpen}
                        buttons={[
                            {
                                onClick: runAction,
                                props: {
                                    color: 'primary',
                                    variant: 'contained',
                                    disabled: (!dlgState.selectedFile && dlgState.fileName === '') || loading,
                                },
                                label: t('app.button.confirm'),
                                withLoading: true,
                            },
                        ]}
                    />
                }
            >
                <div className={classes.fileWrapper}>
                    <Input
                        className={classes.fileInput}
                        type='text'
                        value={dlgState.fileName}
                        data-testid='file-input'
                        onClick={() => fileInput.current?.click()}
                    />
                    <input
                        type='file'
                        hidden
                        ref={fileInput}
                        accept={acceptedMT}
                        onChange={({ target: { files } }) => onFileChange(files)}
                    />
                    <Typography style={{ marginTop: '10px' }}>{t('app.ancs.help_text.once_validated')}</Typography>
                </div>
            </DialogTemplate>
        </Grid>
    );
};

interface DateFieldWrapperProp {
    record?: Avatar;
    notes?: {
        [key: Identifier]: MCNote;
    };
    headerClassName?: string;
}

const DateFieldWrapper = (props: DateFieldWrapperProp) => {
    const noteIds = 'notes' in props && typeof props.notes === 'object' ? Object.keys(props.notes) : [];
    const noteId = noteIds.length > 0 ? noteIds[0] : null;

    return <>{noteId && props.notes ? toFormatDate(props.notes[noteId].createdAt) : ''}</>;
};

DateFieldWrapper.propTypes = {
    record: PropTypes.object,
    notes: PropTypes.object,
};
