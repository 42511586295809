import { CardHeader, CircularProgress, Divider, makeStyles, Theme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import InfoIcon from '@material-ui/icons/Info';
import React, { useState } from 'react';
import { GetListResult, ReduxState, useGetList, useNotify, useTranslate } from 'react-admin';
import { useSelector } from 'react-redux';
import { METADATA_AVATAR_TYPE_PROPERTIES, METADATA_AVATAR_TYPES } from '../../../providers/resources';
import { CustomReducerState } from '../../../redux/reducers';
import { DescriptionBar } from '../../../theme/customTheme';
import { extractIdFromURI } from '../../../utils/resources/ids';
import { getNameWithoutPrefix } from '../../../utils/resources/MetadataAvatarType';
import { MetadataAvatarType, MetadataAvatarTypeProperty } from '../../../utils/types';
import BackDiv from '../../UI/BackDiv/BackDivContainer';
import CollapseMenu from '../../UI/CollapseMenu/CollapseMenuContainer';
import Tabs from '../../UI/Tabs/Tabs';
import TypeProperty from './TypeProperty';

const styles = (theme: Theme) => DescriptionBar(theme);
//@ts-ignore
const useStyles = makeStyles(styles);

const TypesInfoContainer = () => {
    const t = useTranslate();

    const ids = useSelector((state: ReduxState) => state.admin.resources[METADATA_AVATAR_TYPES].list.ids);

    // @ts-ignore
    const selectedType: MetadataAvatarType = useSelector((state: ReduxState & CustomReducerState) => {
        const selectedMDATUri = (state.rikutec.types.selected as MetadataAvatarType)['@id'];
        return selectedMDATUri && selectedMDATUri in state.admin.resources[METADATA_AVATAR_TYPES].data
            ? state.admin.resources[METADATA_AVATAR_TYPES].data[selectedMDATUri]
            : null;
    });

    return (
        <Tabs tabs={[{ label: t('app.headers.info') }]}>
            <BackDiv>
                <Grid item>
                    {selectedType && ids && ids.includes((selectedType as MetadataAvatarType)['@id']) && (
                        <TypesInfo selectedType={selectedType} />
                    )}
                </Grid>
            </BackDiv>
        </Tabs>
    );
};

export default TypesInfoContainer;

interface Props {
    selectedType: MetadataAvatarType;
}

const TypesInfo = ({ selectedType }: Props) => {
    const t = useTranslate();
    const notify = useNotify();
    const classes = useStyles();

    const [properties, setProperties] = useState<MetadataAvatarTypeProperty[]>([]);
    const { loading } = useGetList(
        METADATA_AVATAR_TYPE_PROPERTIES,
        { perPage: 100, page: 1 },
        { field: 'id', order: 'asc' },
        { metadataAvatarType: (selectedType as MetadataAvatarType).id },
        {
            onSuccess: (response: GetListResult) => {
                if (!response || !('data' in response)) return;
                setProperties(response.data as MetadataAvatarTypeProperty[]);
            },
            onFailure: () => notify(t('app.types.messages.error'), 'error'),
        }
    );
    const getValue = (value: any) => (value !== null && value !== undefined ? value : '...');
    const gridWithCard = (title: string, subheader?: any) => (
        <Grid item>
            <CardHeader
                classes={{
                    root: classes.cardRoot,
                    title: classes.cardTitle,
                    subheader: classes.cardSubheader,
                }}
                title={title}
                subheader={getValue(subheader)}
            />
        </Grid>
    );
    // @ts-ignore
    return (
        <>
            <CollapseMenu
                icon={<InfoIcon />}
                title={t('app.headers.description')}
                headerDataTestSC='headerTypeInfo'
                containerDataTestSC='typeInfo'
                expanded='false'
            >
                <div style={{ margin: 8 }} data-testid='info-description-wrapper'>
                    {gridWithCard(
                        t('app.types.alpha_id'),
                        (selectedType as MetadataAvatarType).alphaId
                            ? (selectedType as MetadataAvatarType).alphaId
                            : '...'
                    )}
                    {gridWithCard(
                        t('app.types.id'),
                        // @ts-ignore
                        extractIdFromURI((selectedType as MetadataAvatarType).id)
                    )}
                    {gridWithCard(t('app.types.name'), getNameWithoutPrefix((selectedType as MetadataAvatarType).name))}
                </div>
            </CollapseMenu>
            <Divider />
            <CollapseMenu
                icon={<InfoIcon />}
                title={t('app.headers.additional_info')}
                headerDataTestSC='headerTypeAdditionalInfo'
                containerDataTestSC='typeAdditionalInfo'
                expanded={false}
            >
                {Object.keys(selectedType as MetadataAvatarType).length > 0 &&
                    ((loading && (
                        <CircularProgress
                            color='primary'
                            style={{
                                width: '24px',
                                height: '24px',
                            }}
                        />
                    )) || (
                        <div style={{ margin: '8px' }}>
                            <Grid item>
                                {properties.length > 0 &&
                                    properties.map((item) => <TypeProperty property={item} classes={classes} />)}
                                {properties.length === 0 && '...'}
                            </Grid>
                        </div>
                    ))}
            </CollapseMenu>
        </>
    );
};
