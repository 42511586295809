export const DURATION_TYPE_MONTH = "month";
export const DURATION_TYPE_DAY = "day";

export const convertMonthValuesInDays = (
    min: number,
    max: number
): { min: number; max: number } => ({
    min: min * 30,
    max: max * 30,
});

export const convertDaysValuesInMonth = (
    min: number,
    max: number
): { min: number; max: number } => ({
    min: min / 30,
    max: max / 30,
});
