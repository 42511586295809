import React from 'react';
import Authentication from '../components/auth/Authentication';
import { redirect_to_types } from './paths';
import { User } from '../utils/types';
import { useSelector } from 'react-redux';
import { CustomReducerState } from '../redux/reducers';
import TypesList from '../components/type/list';
import Grid from '@material-ui/core/Grid';
import TypesInfo from '../components/type/info/TypesInfo';
import { makeStyles } from '@material-ui/core';
import { ContentContainer } from '../theme/customTheme';

const containerStyles = ContentContainer();
//@ts-ignore
const useStyles = makeStyles(() => ({ ...containerStyles }));
const TypesPage = () => {
    const classes = useStyles();
    const userConnected: User | null = useSelector((state: CustomReducerState) => state.rikutec.users.userConnected);

    return (
        <Authentication redirectTo={redirect_to_types}>
            {userConnected && (
                <>
                    <Grid container className={classes.root} spacing={2} data-cy-sc='types'>
                        <Grid item xs={12} sm={8} className={classes.gridItem}>
                            <TypesList />
                        </Grid>
                        <Grid item xs={12} sm={4} className={classes.gridItem}>
                            <TypesInfo />
                        </Grid>
                    </Grid>
                </>
            )}
        </Authentication>
    );
};

export default TypesPage;
