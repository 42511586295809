import { AuthProvider as RAAuthProvider } from 'react-admin';
import { getTokenData } from '../utils/token';

interface AuthProviderLocalState {
    user: {
        data: any;
        refreshToken: string | null;
        loggedIn: boolean;
    };
    loaded: boolean;
}

export const localState: AuthProviderLocalState = {
    user: {
        data: null,
        refreshToken: null,
        loggedIn: false,
    },
    loaded: false,
};

/*export const loadLocalStorageInLocalState = () => {
    localState.user.refreshToken = localStorage.getItem(
        // @ts-ignore
        process.env.REACT_APP_LOCALSTORAGE_REFRESH_TOKEN_VARIABLE
    );
    localState.loaded = true;
};*/

export type AuthProvider = RAAuthProvider & {
    refreshToken: (params: { refreshToken: string }) => Promise<{ token: string; refreshToken: string } | undefined>;
};

const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;

const authProvider: AuthProvider = {
    login: (params: any): Promise<any> => {
        return Promise.resolve();
    },
    refreshToken: (params: { refreshToken: string }): Promise<{ token: string; refreshToken: string } | undefined> => {
        return fetch(
            new Request(`${entrypoint}/token/refresh`, {
                method: 'POST',
                headers: new Headers({ 'Content-Type': 'application/json' }),
                body: JSON.stringify({ refresh_token: params.refreshToken }),
            })
        )
            .then((response: any) => {
                return response.json();
            })
            .then((data) => {
                if (data) {
                    const { token, refresh_token } = data;
                    if (token && refresh_token) {
                        localStorage.setItem(
                            // @ts-ignore
                            process.env.REACT_APP_LOCALSTORAGE_TOKEN_VARIABLE,
                            token
                        );
                        localStorage.setItem(
                            // @ts-ignore
                            process.env.REACT_APP_LOCALSTORAGE_REFRESH_TOKEN_VARIABLE,
                            refresh_token
                        );

                        return Promise.resolve({
                            refreshToken: refresh_token,
                            token,
                        });
                    } else {
                        return undefined;
                    }
                }

                return undefined;
            });
    },
    logout: (params: any): Promise<void | string> => {
        localStorage.removeItem(
            // @ts-ignore
            process.env.REACT_APP_LOCALSTORAGE_TOKEN_VARIABLE
        );
        return Promise.resolve();
    },

    checkAuth: async (params: any): Promise<void> => {
        // @ts-ignore
        // const tokenVariable = localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN_VARIABLE);
        // if (tokenVariable) {
        //     const decodedToken = decodeJwt(tokenVariable);
        //     console.log("tokenVariable", tokenVariable);
        // }

        // @ts-ignore
        // return localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN_VARIABLE) ? Promise.resolve() : Promise.reject();
        return Promise.resolve();
    },
    checkError: (error: any): Promise<void> => {
        const status =
            'status' in error
                ? error.status
                : 'response' in error && 'status' in error.response
                ? error.response.status
                : null;
        if (status) {
            if (status === 401 || status === 403) {
                localState.user.loggedIn = false;

                return Promise.reject({ redirectTo: '/unauthorized' });
            }
        }

        return Promise.resolve();
    },
    getPermissions: async (params: any): Promise<any> => {
        if (localState.loaded && !localState.user.loggedIn) {
            return [];
        }

        const token = localStorage.getItem(
            // @ts-ignore
            process.env.REACT_APP_LOCALSTORAGE_TOKEN_VARIABLE
        );

        // @ts-ignore
        const tokenData = token ? getTokenData(token) : null;

        return tokenData ? Object.values(tokenData.roles) : [];
    },
    // getIdentity: () => {
    //     return getUserConnected().then((user: DUser) => user);
    // }
};

export default authProvider;
