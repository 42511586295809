import {combineReducers} from "redux";
import hydraDataProviderReducer, {
    HydraDataProviderState,
} from "./hydraDataProvider";
import AncsReducers, {AncsState} from "./ancs";
import UsersReducers, {UsersState} from "./users";
import ThresholdsReducers, {ThresholdsState} from "./thresholds";
import SyncReducer, {SyncState} from "./sync";
import {typesReducer} from "./types.reducer";
import {TypesState} from "../actions/types.actions";
import groupMetadataAvatarTypeReducer from "./group.metadata.avatar.type.reducer";
import {GroupMetadataAvatarTypeState} from "../actions/group.metadata.avatar.type.actions";
import languagesReducer, {StoredLanguages} from "./languages.reducers";

export interface State {
    hydraDataProvider: HydraDataProviderState;
    ancs: AncsState;
    thresholds: ThresholdsState;
    sync: SyncState;
    users: UsersState;
    types: TypesState;
    groupsMetadataAvatarType: GroupMetadataAvatarTypeState,
    languages: StoredLanguages;
}

export interface CustomReducerState {
    rikutec: State;
}

export default combineReducers({
    hydraDataProvider: hydraDataProviderReducer,
    ancs: AncsReducers,
    thresholds: ThresholdsReducers,
    sync: SyncReducer,
    users: UsersReducers,
    types: typesReducer,
    groupsMetadataAvatarType: groupMetadataAvatarTypeReducer,
    languages: languagesReducer
});
