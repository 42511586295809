export const AVATARS = 'avatars';
export const AVATAR_ANCS = 'avatar_ancs';
export const GROUP_METADATA_AVATAR_TYPES = 'group_metadata_avatar_types';
export const GROUP_METADATA_AVATAR_TYPE = 'group_metadata_avatar_type';
export const MC_NOTES = 'm_c_notes';
export const METADATA_AVATAR_TYPES = 'metadata_avatar_types';
export const METADATA_AVATAR_TYPE = 'metadata_avatar_type';
export const METADATA_AVATAR_TYPE_PROPERTIES = 'metadata_avatar_type_properties';
export const TRACEABILITY_NOTES_THRESHOLD_EXECUTIONS = 'traceability_notes_threshold_executions';
export const AVATAR_PROPERTY = 'avatar_properties';
export const AVATAR_TRACEABILITY_NOTES = 'avatar_traceability_notes';
export const RUNNER_TEMPLATES = 'runner_templates';
export const COMPANIES = 'companies';
export const USERS = 'users';
export const MEDIAS = 'media';
export const FILES = 'files';
export const YOURLS = 'yourls';
export const LANGUAGES = 'languages';
export const MEDIA_TAG_APPROVAL = 'approval';
export const MEDIA_TAG_INSTALLING_GUIDE = 'installing_guide';
export const MEDIA_TAG_USER_GUIDE = 'user_guide';
export const MEDIA_TAG_USER_BOOKLET = 'user_booklet';
export const MEDIA_TAG_TECHNICAL_SHEET = 'technical_sheet';
export const MEDIA_TAG_ROUTINE_MAINTENANCE = 'routine_maintenance';
export const MEDIA_TAG_REFLEX_MAINTENANCE = 'reflex_maintenance';
export const MEDIA_TAG_REFLEX_EMPTYING = 'reflex_emptying';

export const SOURCE_BOARDS = 'rikutec/source_boards';

export type MEDIA_TAGS =
    | typeof MEDIA_TAG_APPROVAL
    | typeof MEDIA_TAG_INSTALLING_GUIDE
    | typeof MEDIA_TAG_USER_GUIDE
    | typeof MEDIA_TAG_TECHNICAL_SHEET
    | typeof MEDIA_TAG_ROUTINE_MAINTENANCE
    | typeof MEDIA_TAG_REFLEX_MAINTENANCE
    | typeof MEDIA_TAG_REFLEX_EMPTYING
    | typeof MEDIA_TAG_USER_BOOKLET;

export const SS_RESOURCE_LIST: string[] = [SOURCE_BOARDS];
