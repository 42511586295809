import MAvatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState } from 'react';
import { MCNote } from '../../utils/types';

interface ShowLastNoteIconProps {
    record: string | null;
    tag: string;
}

const useStyles = makeStyles((theme) => ({
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    center: {
        marginLeft: 'auto',
        marginRight: 'auto',
    },
}));

const DEFAULT_COLOR = '#808080';

const makeColorUseStyles = (color: string) =>
    makeStyles((theme) => ({
        color: {
            color: theme.palette.getContrastText(color),
            backgroundColor: color,
        },
    }));

const ShowLastNoteIcon = (props: ShowLastNoteIconProps) => {
    const [color, setColor] = useState(DEFAULT_COLOR);

    useEffect(() => {
        const jsonData: MCNote = props.record ? JSON.parse(props.record) : null;
        const color = jsonData ? jsonData.customFields.thresholdColor : DEFAULT_COLOR;

        if (color) setColor(color);
    }, [props.record]);

    const classes = useStyles();
    const colorClasses = makeColorUseStyles(color ? color : DEFAULT_COLOR)();

    return (
        <MAvatar className={`${classes.small} ${colorClasses.color} ${classes.center}`}>
            <Typography variant='h1' component='h2' />
        </MAvatar>
    );
};

export default ShowLastNoteIcon;
