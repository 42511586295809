import { CircularProgress, Input, makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useRef, useState } from 'react';
import { ReduxState, useDelete, useNotify, useRefresh, useTranslate, useUpdate } from 'react-admin';
import { useSelector } from 'react-redux';
import { GROUP_METADATA_AVATAR_TYPES, MEDIAS } from '../../../../../../providers/resources';
import { CustomReducerState } from '../../../../../../redux/reducers';
import { ReactComponent as UploadFileIcon } from '../../../../../../upload_file_black_24dp.svg';
import { postUploadMedia } from '../../../../../../utils/medias';
import { extractIdFromURI } from '../../../../../../utils/resources/ids';
import { getNameWithoutPrefix } from '../../../../../../utils/resources/MetadataAvatarType';

interface ProductTypeActionsAddMediaFormProps {
    isOpen: boolean;
    type: 'user_guide' | 'technical_sheet' | 'routine_maintenance';
    toggle: () => void;
}

const useStyles = makeStyles(() => ({
    dialogTitle: {
        padding: '17px 20px 0 20px',
        paddingBottom: 10,
    },
    container: {
        display: 'grid',
        gridTemplateColumns: '25px auto',
        alignItems: 'center',
        columnGap: '7px',
    },
    dialogTitleText: {
        textTransform: 'uppercase',
        fontWeight: 'bold',
        fontSize: 17,
    },
    fileWrapper: {
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'center',
    },
    fileInput: {
        width: '100%',
    },
    filePreview: {
        marginTop: '15px',
        width: '100%',
    },
    circularProgress: {
        width: '16px !important',
        height: '16px !important',
        marginRight: '7px',
    },
    buttonsContainer: {
        marginTop: '20px',
        textAlign: 'right',
    },
    buttons: {
        '&:first-child': {
            marginRight: '7px',
        },
    },
    helperMessage: {
        fontWeight: 'bold',
        marginTop: '15px',
        marginBottom: '5px',
    },
}));
const ProductTypeActionsAddMediaForm = (props: ProductTypeActionsAddMediaFormProps) => {
    const fileInput = useRef<HTMLInputElement>(null);
    const [dlgState, setDlgState] = useState<{
        mediaId: string;
        fileName: string | undefined;
        selectedFile: File | undefined;
    }>({
        mediaId: '',
        fileName: '',
        selectedFile: undefined,
    });
    const {
        rikutec: {
            users: { userConnected },
            groupsMetadataAvatarType: { selected: selectedGMDAT },
        },
    } = useSelector((state: CustomReducerState) => state);
    const {
        admin: {
            resources: {
                group_metadata_avatar_types: { data: adminData },
            },
        },
    } = useSelector((state: ReduxState) => state);
    const t = useTranslate();
    const classes = useStyles();
    const refresh = useRefresh();
    const notify = useNotify();

    const [uploadingMedia, setUploadingMedia] = useState(false);
    const [searchingMedia, setSearchingMedia] = useState(false);

    const [updateProductType, { loading: updatingProductType }] = useUpdate();
    const [deleteMedia, { loading: deletingMedia }] = useDelete();

    const acceptedMT = props.type === 'routine_maintenance' ? 'image/*' : 'application/pdf';
    const token = localStorage.getItem('rikutec_token')!!;
    const fileSystemMapperUrl = `${process.env.REACT_APP_FILE_SYSTEM_MAPPER_ENTRYPOINT}`
        .concat('/smartConnectView/file_system_mappers')
        .concat(`?lvl=5&tags.name=${props.type.toString()}&isFolder=false`)
        .concat('&parent.name=system&parent.parent.name=sc:folder-group_metadata_avatar_type:');
    const searchForMedia = () => {
        if (props.isOpen) {
            setSearchingMedia(true);
            fetch(fileSystemMapperUrl.concat(`${extractIdFromURI(selectedGMDAT['@id'])}`), {
                headers: { Authorization: `Bearer ${token}` },
            })
                .then((response) =>
                    response.json().then((media) => {
                        if ('hydra:member' in media && media['hydra:member'].length > 0) {
                            const fileMedia = adminData[selectedGMDAT['@id']].medias?.find((item: string) =>
                                media['hydra:member'].some(
                                    (token: any) =>
                                        token.metadata['metadata.extra']['coreapi.mediaid'] === extractIdFromURI(item)
                                )
                            );
                            fileMedia &&
                                setDlgState((prevState) => ({
                                    ...prevState,
                                    mediaId: fileMedia ?? undefined,
                                    fileName: fileMedia
                                        ? getNameWithoutPrefix(
                                              media['hydra:member']
                                                  .find(
                                                      (item: any) =>
                                                          item.metadata['metadata.extra']['coreapi.mediaid'] ===
                                                          extractIdFromURI(fileMedia)
                                                  )
                                                  .metadata['metadata.cloud'].name.split('*_*')[1]
                                          )
                                        : undefined,
                                }));
                        }
                        setSearchingMedia(false);
                    })
                )
                .catch(() => {
                    notify('app.productType.messages.error', 'error');
                    setSearchingMedia(false);
                });
        }
    };
    const onFileChange = (fileList: FileList | null) => {
        fileList &&
            fileList.length > 0 &&
            setDlgState((prevState) => ({
                ...prevState,
                fileName: fileList[0].name,
                selectedFile: fileList[0],
            }));
    };
    const postMedia = () => {
        if (dlgState.selectedFile) {
            setUploadingMedia(true);
            const reader = new FileReader();
            reader.onload = async (e) => {
                notify('app.messages.loading', 'info');
                const response = await postUploadMedia(
                    e.target?.result as ArrayBuffer,
                    dlgState.selectedFile!!.name,
                    'group_metadata_avatar_type',
                    extractIdFromURI(selectedGMDAT['@id'])!!,
                    token,
                    'FILE',
                    [props.type.toString()],
                    userConnected?.company['@id']
                )
                    .then((response) => ({ result: response, error: null }))
                    .catch((error) => ({ error, result: null }))
                    .finally(() => setUploadingMedia(false));
                if (response.result) {
                    console.log('hereee', adminData[selectedGMDAT['@id']]);

                    const updatedProductType = { ...adminData[selectedGMDAT['@id']] };
                    if (updatedProductType.medias && updatedProductType.medias.length > 0) {
                        updatedProductType.medias = adminData[selectedGMDAT['@id']].medias.filter(
                            (item: string) => item !== dlgState.mediaId
                        );
                        updatedProductType.medias.push(response.result['@id']);
                    } else {
                        Object.assign(updatedProductType, { medias: [response.result['@id']] });
                    }
                    Object.assign(updatedProductType, { customFields: {}, identifiers: [] });
                    console.log('updateProductType', updatedProductType);
                    updateProductType(
                        GROUP_METADATA_AVATAR_TYPES,
                        selectedGMDAT.id,
                        // updatedProductType,
                        {
                            medias: updatedProductType.medias,
                            customFields: updatedProductType.customFields,
                            identifiers: updatedProductType.identifiers,
                        },
                        adminData[selectedGMDAT['@id']],
                        {
                            onSuccess: () => {
                                if (dlgState.mediaId) {
                                    deleteOldMedia(dlgState.mediaId);
                                } else {
                                    refresh();
                                    toggle();
                                    notify('app.productType.messages.success', 'success');
                                }
                            },
                            onFailure: (error: any) => {
                                console.log('ERROR', error);
                                notify('app.productType.messages.error', 'error');
                            },
                        }
                    );
                } else {
                    notify('app.productType.messages.error', 'error');
                }
            };
            reader.readAsDataURL(dlgState.selectedFile);
        }
    };
    const deleteOldMedia = (oldMedia: string) =>
        deleteMedia(MEDIAS, oldMedia, undefined, {
            onSuccess: () => {
                refresh();
                toggle();
                notify('app.productType.success.updated', 'success');
            },
        });
    const toggle = () => {
        setDlgState({ mediaId: '', fileName: undefined, selectedFile: undefined });
        props.toggle();
    };
    useEffect(searchForMedia, [fileSystemMapperUrl, notify, selectedGMDAT, t, token, props.isOpen, adminData]);
    return (
        <Dialog
            open={props.isOpen}
            onClose={toggle}
            fullWidth
            maxWidth='sm'
            data-testid='ProductTypeActionsAddMediaForm'
        >
            <DialogTitle disableTypography className={`${classes.dialogTitle} ${classes.container}`}>
                <UploadFileIcon color='primary' />
                <Typography color='primary' className={classes.dialogTitleText}>
                    {t(`app.productType.addEdit${props.type.toString()}`)}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <div className={classes.fileWrapper}>
                    <Input
                        className={classes.fileInput}
                        type='text'
                        placeholder={t(`app.productType.${props.type.toString()}`)}
                        value={dlgState.fileName}
                        disabled={uploadingMedia || updatingProductType || deletingMedia}
                        onClick={() => fileInput.current?.click()}
                        data-testid='form-input'
                    />
                    <input
                        type='file'
                        hidden
                        ref={fileInput}
                        accept={acceptedMT}
                        onChange={({ target: { files } }) => onFileChange(files)}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant='text' color='primary' onClick={toggle}>
                    {t('app.button.cancel')}
                </Button>
                <Button
                    variant='contained'
                    color='primary'
                    disabled={
                        !dlgState.selectedFile ||
                        searchingMedia ||
                        uploadingMedia ||
                        updatingProductType ||
                        deletingMedia
                    }
                    onClick={() => postMedia()}
                    data-testid='submit-button'
                >
                    {(searchingMedia || uploadingMedia || updatingProductType || deletingMedia) && (
                        <CircularProgress className={classes.circularProgress} color='primary' />
                    )}
                    {t('app.button.confirm')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ProductTypeActionsAddMediaForm;
