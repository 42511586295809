import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { FunctionField, List, Record, ReferenceField, useTranslate } from 'react-admin';
import { useDispatch } from 'react-redux';
import { types } from '../../pages/paths';
import {
    GROUP_METADATA_AVATAR_TYPES,
    MEDIA_TAG_INSTALLING_GUIDE,
    MEDIA_TAG_REFLEX_EMPTYING,
    MEDIA_TAG_REFLEX_MAINTENANCE,
    MEDIA_TAG_USER_BOOKLET,
    MEDIA_TAG_USER_GUIDE,
    METADATA_AVATAR_TYPE,
    METADATA_AVATAR_TYPES,
} from '../../providers/resources';
import { addToMetadataAvatarTypeData, selectType } from '../../redux/actions/types.actions';
import { tableStyles } from '../../theme/customTheme';
import { getNameWithoutPrefix } from '../../utils/resources/MetadataAvatarType';
import { MetadataAvatarType } from '../../utils/types';
import BackDiv from '../UI/BackDiv/BackDivContainer';
import Breadcrumbs from '../UI/Breadcrumbs/Breadcrumbs';
import DataGridCustom from '../UI/DataGrid';
import MediaCell from '../UI/MediaCell/MediaCell';
import ListActions from './actions/managementTypes';
import EnableApprovalPropertyField from './fields/EnableApprovalPropertyField';
import FiltersList from './filters/FiltersList';

const styles = () => ({ ...tableStyles() });
//@ts-ignore
const useStyles = makeStyles(styles);
const TypesList = () => {
    const t = useTranslate();
    const classes = useStyles();
    const dispatch = useDispatch();
    const listProps = {
        resource: METADATA_AVATAR_TYPES,
        hasCreate: true,
        hasEdit: true,
        hasList: true,
        hasShow: false,
        basePath: types,
        sort: { field: 'createdAt', order: 'DESC' },
        perPage: 10,
        filter: {},
        bulkActionButtons: false,
        exporter: false,
        empty: false,
    };
    const dataGridProps = {
        rowClick: (id: string, basePath: string, record: Record) => {
            dispatch(selectType(record));
            return null;
        },
        toFindSelected: {
            resource: 'types',
            key: 'selected',
        },
        hasBulkActions: null,
        classes: {
            checkbox: classes.checkbox,
            row: classes.list,
        },
    };
    return (
        <Grid container direction='column'>
            <Grid item>
                <Breadcrumbs items={[t('app.headers.type')]} />
            </Grid>
            <BackDiv>
                <Grid item>
                    <List
                        {...listProps}
                        actions={<ListActions />}
                        filters={<FiltersList />}
                        bulkActionButtons={false}
                        empty={false}
                        exporter={false}
                    >
                        <DataGridCustom {...dataGridProps}>
                            <FunctionField
                                label={t('app.types.productName')}
                                source='name'
                                sortable={true}
                                // @ts-ignore
                                render={(record: MetadataAvatarType) => `${getNameWithoutPrefix(record.name)}`}
                            />

                            <FunctionField
                                sortable={true}
                                label={t('app.types.approvalEnabled')}
                                // @ts-ignore
                                render={(record: MetadataAvatarType | undefined) =>
                                    //@ts-ignore
                                    record ? <EnableApprovalPropertyField record={record} /> : <span>{'...'}</span>
                                }
                            />

                            <ReferenceField
                                sortable={true}
                                label={t('app.types.productType')}
                                reference={GROUP_METADATA_AVATAR_TYPES}
                                source='groupMetadataAvatarType'
                                sortBy='groupMetadataAvatarType.name'
                                link={false}
                            >
                                <FunctionField
                                    sortable={true}
                                    // @ts-ignore
                                    render={(record: MetadataAvatarType) => `${getNameWithoutPrefix(record.name)}`}
                                />
                            </ReferenceField>
                            <FunctionField
                                label={t('app.types.installing_guide')}
                                render={(record: Record | undefined) => (
                                    <MediaCell
                                        record={record!}
                                        mediaTag={MEDIA_TAG_INSTALLING_GUIDE}
                                        objectType={METADATA_AVATAR_TYPE}
                                        reduxUpdater={addToMetadataAvatarTypeData}
                                        mediasObjectContainer='files'
                                        storeContainer='types'
                                    />
                                )}
                            />
                            <FunctionField
                                label={t('app.types.user_guide')}
                                render={(record: Record | undefined) => (
                                    <MediaCell
                                        record={record!}
                                        mediaTag={MEDIA_TAG_USER_GUIDE}
                                        objectType={METADATA_AVATAR_TYPE}
                                        reduxUpdater={addToMetadataAvatarTypeData}
                                        mediasObjectContainer='files'
                                        storeContainer='types'
                                    />
                                )}
                            />
                            <FunctionField
                                label={t('app.types.user_booklet')}
                                render={(record: Record | undefined) => (
                                    <MediaCell
                                        record={record!}
                                        mediaTag={MEDIA_TAG_USER_BOOKLET}
                                        objectType={METADATA_AVATAR_TYPE}
                                        reduxUpdater={addToMetadataAvatarTypeData}
                                        mediasObjectContainer='files'
                                        storeContainer='types'
                                    />
                                )}
                            />
                            {/*<FunctionField*/}
                            {/*    label={t('app.types.technical_sheet')}*/}
                            {/*    render={(record: Record | undefined) => (*/}
                            {/*        <TypeMedias record={record} mediaTag='technical_sheet' />*/}
                            {/*    )}*/}
                            {/*/>*/}
                            {/*<FunctionField*/}
                            {/*    label={t('app.types.routine_maintenance')}*/}
                            {/*    render={(record: Record | undefined) => (*/}
                            {/*        <TypeMedias record={record} mediaTag='routine_maintenance' />*/}
                            {/*    )}*/}
                            {/*/>*/}
                            <FunctionField
                                label={t('app.types.reflex_emtying')}
                                render={(record: Record | undefined) => (
                                    <MediaCell
                                        record={record!}
                                        mediaTag={MEDIA_TAG_REFLEX_EMPTYING}
                                        objectType={METADATA_AVATAR_TYPE}
                                        reduxUpdater={addToMetadataAvatarTypeData}
                                        mediasObjectContainer='files'
                                        storeContainer='types'
                                    />
                                )}
                            />
                            <FunctionField
                                label={t('app.types.reflex_maintenance')}
                                render={(record: Record | undefined) => (
                                    <MediaCell
                                        record={record!}
                                        mediaTag={MEDIA_TAG_REFLEX_MAINTENANCE}
                                        objectType={METADATA_AVATAR_TYPE}
                                        reduxUpdater={addToMetadataAvatarTypeData}
                                        mediasObjectContainer='files'
                                        storeContainer='types'
                                    />
                                )}
                            />
                        </DataGridCustom>
                    </List>
                </Grid>
            </BackDiv>
        </Grid>
    );
};

export default TypesList;
