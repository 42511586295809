import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import {
    useCreate,
    useDelete,
    useLoading,
    useNotify,
    useRefresh,
    useTranslate,
    useUpdate,
    useUpdateLoading,
} from 'react-admin';
import ProductTypeFormControls from './ProductTypeFormControls/ProductTypeFormControls';
import { GROUP_METADATA_AVATAR_TYPES, MEDIAS } from '../../../../../../providers/resources';
import { postUploadMedia } from '../../../../../../utils/medias';
import { extractIdFromURI } from '../../../../../../utils/resources/ids';
import { useSelector } from 'react-redux';
import { CustomReducerState } from '../../../../../../redux/reducers';
import SettingsIcon from '@material-ui/icons/Settings';
import NewIcon from '@material-ui/icons/FiberNew';
import useStyles from './styles';
import { getNameWithoutPrefix } from '../../../../../../utils/resources/MetadataAvatarType';

interface ProductTypeFormProps {
    isOpen: boolean;
    action: 'CREATE' | 'EDIT' | 'NONE';
    toggle: () => void;
}

const ProductTypeForm = (props: ProductTypeFormProps) => {
    const t = useTranslate();
    const classes = useStyles();
    const refresh = useRefresh();
    const loading = useLoading();
    const { startLoading, stopLoading } = useUpdateLoading();
    const notify = useNotify();
    const {
        rikutec: {
            users: { userConnected },
            groupsMetadataAvatarType: { selected: selectedGMDAT },
        },
    } = useSelector((state: CustomReducerState) => state);
    const token = localStorage.getItem('rikutec_token')!!;
    const [createProductType] = useCreate();
    const [updateProductType] = useUpdate();
    const [deleteMedia] = useDelete();
    const [oldMedia, setOldMedia] = useState<any>({});
    const deleteOldMedia = (mediaId: string) =>
        deleteMedia(MEDIAS, mediaId, undefined, {
            onSuccess: () => {
                notify(t('app.productType.messages.success'), 'success');
                refresh();
                shutDown();
            },
        });
    const fileSystemMapperUrl = `${process.env.REACT_APP_FILE_SYSTEM_MAPPER_ENTRYPOINT}`
        .concat('/smartConnectView/file_system_mappers')
        .concat('?lvl=5&tags.name=approval&isFolder=false')
        .concat('&parent.name=system&parent.parent.name=sc:folder-group_metadata_avatar_type:');
    const getOldMedia = () => {
        if (props.action === 'EDIT') {
            startLoading();
            fetch(fileSystemMapperUrl.concat(`${extractIdFromURI(selectedGMDAT['@id'])}`), {
                headers: { Authorization: `Bearer ${token}` },
            })
                .then((response) =>
                    response.json().then((media) => {
                        if (media['hydra:member'].length > 0) {
                            const fileMedia = selectedGMDAT.medias.find((item: string) =>
                                media['hydra:member'].some(
                                    (token: any) =>
                                        token.metadata['metadata.extra']['coreapi.mediaid'] === extractIdFromURI(item)
                                )
                            );
                            fileMedia &&
                                setOldMedia({
                                    mediaId: fileMedia ?? undefined,
                                    mediaName: fileMedia
                                        ? getNameWithoutPrefix(
                                              media['hydra:member'][0].metadata['metadata.cloud'].name.split('*_*')[1]
                                          )
                                        : undefined,
                                });
                        }
                    })
                )
                .catch(() => notify(t('app.productType.messages.error'), 'error'))
                .finally(() => stopLoading());
        }
    };
    const postMedia = (
        file: File,
        productType: any,
        tags: string[],
        action: 'ADD' | 'REPLACE',
        clearFileState?: (par: any) => void
    ) => {
        startLoading();
        const reader = new FileReader();
        reader.onload = async (e) => {
            notify(t('app.messages.loading'), 'info');
            const response = await postUploadMedia(
                e.target?.result as ArrayBuffer,
                file.name,
                'group_metadata_avatar_type',
                extractIdFromURI(productType['@id'])!!,
                token,
                'FILE',
                tags,
                userConnected?.company['@id']
            )
                .then((response) => ({ result: response, error: null }))
                .catch((error) => ({ error, result: null }))
                .finally(() => stopLoading());
            if (response.result) {
                const updatedProductType = { ...productType };
                if (updatedProductType.medias) {
                    updatedProductType.medias = productType.medias.filter((item: string) => item !== oldMedia.mediaId);
                    updatedProductType.medias.push(response.result['@id']);
                } else {
                    Object.assign(updatedProductType, { medias: [response.result['@id']] });
                }
                Object.assign(updatedProductType, { customFields: {}, identifiers: [] });
                updateProductType(GROUP_METADATA_AVATAR_TYPES, productType.id, updatedProductType, productType, {
                    onSuccess: () => {
                        clearFileState?.({ isOpen: false, fileName: '', selectedFile: undefined, oldMedia: undefined });
                        if (action === 'ADD') {
                            refresh();
                            notify(t('app.productType.messages.success'), 'success');
                            shutDown();
                        } else {
                            oldMedia && deleteOldMedia(oldMedia.mediaId);
                        }
                    },
                    onFailure: () => notify(t('app.productType.messages.error'), 'error'),
                });
            } else {
                notify(t('app.productType.messages.error'), 'error');
            }
        };
        reader.readAsDataURL(file);
    };
    const saveData = (data: any, file?: File, clearFileState?: (par: any) => void) => {
        if (props.action === 'EDIT' && selectedGMDAT) {
            if (file) {
                const updatedGMDAT = { ...selectedGMDAT };
                updatedGMDAT.name = data.name;
                postMedia(file, updatedGMDAT, ['approval'], 'REPLACE', clearFileState);
            } else {
                updateProductType(GROUP_METADATA_AVATAR_TYPES, selectedGMDAT.id, data, selectedGMDAT, {
                    onSuccess: () => {
                        refresh();
                        notify(t('app.productType.messages.success'), 'success');
                        shutDown();
                    },
                    onFailure: () => notify(t('app.productType.messages.error'), 'error'),
                });
            }
        } else if (props.action === 'CREATE') {
            Object.assign(data, { customFields: {}, identifiers: [] });
            createProductType(GROUP_METADATA_AVATAR_TYPES, data, {
                onSuccess: (response) => {
                    if (file) {
                        postMedia(file, response.data, ['approval'], 'ADD', clearFileState);
                    } else {
                        refresh();
                        notify(t('app.productType.messages.success'), 'success');
                        shutDown();
                    }
                },
                onFailure: () => notify(t('app.productType.messages.error'), 'error'),
            });
        }
    };
    const shutDown = () => {
        setOldMedia(undefined);
        props.toggle();
    };
    useEffect(
        getOldMedia,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.action, fileSystemMapperUrl, token, selectedGMDAT.id, notify, t]
    );
    return (
        <Dialog open={props.isOpen} onClose={shutDown} fullWidth maxWidth='sm' data-testid='ProductTypeForm'>
            <DialogTitle className={classes.header}>
                {props.action === 'CREATE' && (
                    <>
                        <NewIcon className={classes.headerIcon} />
                        <Typography className={classes.headerText}>{t('app.productType.create')}</Typography>
                    </>
                )}
                {props.action === 'EDIT' && (
                    <>
                        <SettingsIcon className={classes.headerIcon} />
                        <Typography className={classes.headerText}>{t('app.productType.edit')}</Typography>
                    </>
                )}
            </DialogTitle>
            <DialogContent className={classes.dialogContentRoot}>
                <ProductTypeFormControls
                    submitFn={saveData}
                    toggle={shutDown}
                    oldMedia={oldMedia}
                    action={props.action}
                    loading={loading}
                />
            </DialogContent>
        </Dialog>
    );
};

export default ProductTypeForm;
