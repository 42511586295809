import { MAINTENANCE, SLUDGE_EMPTYING, SPANC } from '../utils/rikutec';

const locale = {
    app: {
        common: {
            after: 'Après',
            before: 'Avant',
        },
        button: {
            confirm: 'Valider',
            submit: 'Soumettre',
            cancel: 'Annuler',
            go: {
                toThresholdPage: 'Voir les seuils',
                toAncsPage: 'Voir les ancs',
            },
        },
        ancs: {
            level1: 'Niveau 1',
            level2: 'Niveau 2',
            level3: 'Niveau 3',
            level4: 'Niveau 4',
            level5: 'Niveau 5',
            postalCode: 'Code postal',
            name: 'Désignation',
            articleNumber: 'Code produit',
            productTypology: 'Typologie produit',
            serialNumber: 'No de série',
            installingDate: "Date d'installation",
            maintenanceLastDate: 'Dernière maintenance',
            sludgeEmptyingLastDate: 'Dernière vidange',
            maintenanceThreshold: 'Niveau de maintenance',
            sludgeEmptyingThreshold: 'Niveau de vidange',
            sludgeEmptyingThresholdFilterDate: 'Filtre par période et événement traça',
            sludgeEmptyingThresholdFilterLastDate: 'Période par vidange',
            sludgeMaintenanceThresholdFilterLastDate: 'Période par maintenance',
            sludgeEmptyingThresholdOrMaintenanceFilterDate: 'Dernière vidange des boues',
            thresholdName: 'Libellé du niveau',
            thresholdMinValue: 'Valeur min',
            thresholdMaxValue: 'Valeur max',
            thresholdTriggerValue: 'Valeur de déclenchement',
            thresholdMinTriggerValue: 'Limite basse de l’intervalle',
            thresholdMaxTriggerValue: 'Limite haute de l’intervalle',
            thresholdMonthValue: 'Mois',
            addThreshold: 'Ajouter un niveau',
            processingCapacity: 'Capacité de traitement',
            sludgeStorageVolume: 'Volume de stockage de boues',
            totalUsefulVolume: 'Volume totale utile',
            nodeCurrentState: 'État actuel',
            nodeStateName: "Nom de l'état",
            nodeDateOfTakesPlace: "Date de l'état actuel",
            model: 'Modèle',
            weeks: 'semaines',
            hours: 'heures',
            days: 'Jours',
            years: 'Années',
            month: 'Mois',
            accessToFinger: 'Vue terrain',
            [MAINTENANCE]: 'Maintenance',
            [SLUDGE_EMPTYING]: 'Vidange',
            [SPANC]: 'Spanc',
            actions: {
                title: 'Actions',
                managementThresholds: 'Gestion des seuils',
                defineThresholds:
                    'Liste des niveaux d’alertes, basés sur les intervalles de temps depuis la dernière opération',
                export: 'Exporter',
                statistics: 'Statistiques',
                refresh: 'Actualiser',
                personalize: 'Personnaliser',
                editVP: 'Ajouter / Modifier un PV',
                editWC: 'Ajouter / Modifier un certificat de garantie',
            },
            error: {
                server: "Il a eu une erreur. Veuillez contacter l'administrateur",
                wrongMinAmount: 'Montant minimum incorrect',
                wrongMaxAmount: 'Montant maximum incorrect',
                requiredField: 'Champs requis',
                minMustExceedPreviousMax: 'La valeur doit être supérieure à la valuer du précédent maximum',
                maxMustExceedPreviousMin: 'La valeur doit être supérieure à la valeur duprécédente minimum',
                greaterThanZero: 'Ne peut pas être négatif',
            },
            metadataAvatarType: {
                name: 'Nom du type de produit',
            },
            help_text: {
                once_validated:
                    'Une fois validé pour voir le document envoyé cliquez sur le bouton dans la colonne “Vue terrain”',
            },
            success: {
                success: 'Les données ont été enregistrées',
                media_uploaded: 'Le média sélectionné a été téléchargé',
                media_updated: 'Le média sélectionné a été mis à jour',
            },
        },
        traceabilityNotesThreshold: {
            name: 'Seuil',
            operationType: "Type d'opération",
            objectType: 'Type de produit',
            activeEvent: 'Événement active',
            deleted: 'Le seuil a étè bien supprimé',
            actions: {
                title: 'Actions',
                addEvent: 'Ajouter un seuil',
                deleteEvent: 'Supprimer un seuil',
                editEvent: 'Modifier le seuil séléctionné',
                managementThresholds: 'Gestion des seuils',
                defineThresholds:
                    'Liste des niveaux d’alertes, basés sur les intervalles de temps depuis la dernière opération',
                export: 'Exporter',
                statistics: 'Statistiques',
                refresh: 'Actualiser',
                personalize: 'Personnaliser',
                delete: {
                    title: 'Supprimer le seuil?',
                    message: 'Êtes-vous sûr de vouloir supprimer le seuil?',
                },
            },
            error: {
                server: "Il a eu une erreur. Veillez contacter l'administrateur",
                uniqueMetadataAvatarType: 'Il existe une notification lié à cet objet type ',
                set: {
                    groupAvatar: "Le groupe de l'objet doit être défini",
                    avatar: "L'objet doit être défini",
                    metadataAvatarType: "Le type d'objet doit être défini ",
                },
            },
            maintenance: {
                name: 'Seuil de maintenance ',
            },
            emptying: {
                name: 'Seuil de vidange',
            },
        },
        sidebar: {
            anc: 'Suivi des ANC',
            every2Hours: 'Mise à jour toutes les 2 heures',
            realTime: 'Mise à jour en temps réel',
            product_type: 'Typologie produit',
            type: 'Type',
            threshold_settings: 'Paramètre de seuils',
        },
        types: {
            productName: 'Désignation',
            productType: 'Typologie Produit',
            alpha_id: 'Alpha Id',
            id: 'Id',
            name: 'Nom du type',
            processingCapacity: 'Capacité de traitement',
            sludgeStorageVolume: 'Volume dédié au stockage des boues',
            totalUsefulVolume: 'Volume totale utile',
            model: 'Modèle',
            itemCode: "Code d'article",
            typeSerialNumber: '4 premiers chiffres du numéro de série',
            create: 'Créer Type',
            edit: 'Modifier',
            empty: '<vide>',
            addEditInstallingGuide: 'Ajouter / Modifier guide de pose',
            addEditinstalling_guide: 'Ajouter / Modifier guide de pose',
            installing_guide: 'Guide de pose',
            user_booklet: "Livret de l'utilisateur",
            mediaFile: 'Charger un fichier',
            user_guide: 'Guide d’utilisation et d’entretien',
            reflex_emtying: 'Fiche Reflex Vidange',
            reflex_maintenance: 'Fiche Reflex Maintenance',
            technical_sheet: 'Fiche technique',
            routine_maintenance: 'Maintenance de routine',
            addReflexEmtying: 'Ajouter la Fiche Reflex Vidange',
            addReflexMaintenance: 'Ajouter la Fiche Reflex Maintenance',
            addInstallingGuide: 'Ajouter la guide de pose',
            addUserGuide: 'Ajouter la guide d’utilisation et d’entretien',
            addUserBooklet: "Ajouter le livret de l'utilisateur",
            enableApproval: "Activer l'agrément",
            disableApproval: "Desactiver l'agrément",
            approvalEnabled: 'Agrément active',
            approvalDisabled: 'Agrément desactivé',
            addTechnicalSheet: 'Ajouter la fiche technique',
            addRoutineMaintenance: 'Ajouter la maintenance de routine',
            addEditUserGuide: "Ajouter / Modifier le mode d'emploi",
            addEdituser_guide: "Ajouter / Modifier le mode d'emploi",
            addEditTechnicalSheet: 'Ajouter / Modifier la fiche technique',
            addEdittechnical_sheet: 'Ajouter / Modifier la fiche technique',
            addEditRoutineMaintenance: 'Ajouter / Modifier la Maintenance de routine',
            addEditroutine_maintenance: 'Ajouter / Modifier la Maintenance de routine',
            yes: 'Oui',
            no: 'Non',
            messages: {
                success: 'Les données ont été enregistrées',
                error: "Une erreur s'est produite",
            },
        },
        actions: {
            refresh: 'Actualiser',
            addItem: 'Créer',
            editItem: 'Modifier',
            deleteItem: 'Supprimer',
            title: 'Actions',
        },
        headers: {
            description: 'Description',
            additional_info: 'Information Additionnelle',
            info: 'Information',
            type: 'Type',
            selectAvatarTypeGroup: 'Sélectionnez le type de produit',
            confirmDelete: 'Supprimer',
            productTypeData: 'Type de produit Données',
            productType: 'Typologie produit',
            ancs: 'Suivi des Ancs',
            thresholds: 'Paramètre de seuils',
        },
        validation: {
            required: 'Champ requis',
        },
        messages: {
            confirmDelete: 'Voulez vous supprimer cette ligne?',
            confirmOperation: 'Vous êtes sur le point de supprimer un élément. Êtes-vous sur de vouloir continuer?',
            canSendApprovalLater: "Vous pouvez envoyer l'agrément plus tard",
            loading: 'Chargement en cours, veuillez patienter',
            error: "Quelque chose s'est mal passé, veuillez en informer l'administrateur",
            success: "L'opération a réussi",
        },
        productType: {
            create: 'Créer',
            edit: 'Modifier',
            name: 'Typologie produit',
            yes: 'Oui',
            no: 'Non',
            approval: 'Agrément',
            approvalFile: "Charger un fichier d'agrément",
            mediaFile: 'Charger un fichier',
            addApproval: 'Ajouter agrément',
            editApproval: 'Modifier agrément',
            addItem: 'Créer',
            editItem: 'Modifier',
            deleteItem: 'Supprimer',
            messages: {
                success: 'Les données ont été enregistrées',
                error: "Une erreur s'est produite",
            },
            success: {
                updated: 'La typologie produit a été mis à jour',
            },
            error: {
                server: "Il a eu une erreur. Veuillez contacter l'administrateur",
            },
        },
        errors: {
            updatingProperty: "Une erreur s'est produite lors de la mise à jour d'une propriété",
            creatingProperty: "Une erreur s'est produite lors de la création d'une propriété",
            updatingType: "Il y a eu une erreur lors de la mise à jour d'un type",
            creatingType: "Une erreur s'est produite lors de la création d'un type",
            gettingLanguage: "Une erreur s'est produite lors de la récupération du langage de propriété",
        },
        no: 'Non',
        yes: 'Oui',
        medias: {
            addApproval: 'Ajouter agrément',
            addTechnicalSheet: 'Ajouter la fiche technique',
            addRoutineMaintenance: 'Ajouter la maintenance de routine',
            addUserGuide: 'Ajouter la guide d’utilisation et d’entretien',
            addInstallingGuide: 'Ajouter guide de pose',
            mediaFile: 'Charger un fichier',
            addReflexEmptying: 'Ajouter la Fiche Reflex Vidange',
            addReflexMaintenance: 'Ajouter la Fiche Reflex Maintenance',
            addUserBooklet: "Ajouter le livret de l'utilisateur",
        },
    },
};

export default locale;
