import { THRESHOLD_LEVEL1_NAME, THRESHOLD_LEVEL2_NAME, THRESHOLD_LEVEL3_NAME } from '../../../../utils/rikutec';

export const thresholdsNameChoices = (translate: (n: string) => {}): any[] => [
    {
        id: THRESHOLD_LEVEL1_NAME,
        name: translate('app.ancs.level1'),
    },
    {
        id: THRESHOLD_LEVEL2_NAME,
        name: translate('app.ancs.level2'),
    },
    {
        id: THRESHOLD_LEVEL3_NAME,
        name: translate('app.ancs.level3'),
    },
    // {
    //     id: THRESHOLD_LEVEL4_NAME,
    //     name: translate("app.ancs.level4"),
    // },
    // {
    //     id: THRESHOLD_LEVEL5_NAME,
    //     name: translate("app.ancs.level5"),
    // },
];
