import React from 'react'
import {Typography} from "@material-ui/core";

type TypographyFormSubtitleProps = {
    title: string,
    noWrap?: boolean,
    color: 'initial'
        | 'inherit'
        | 'primary'
        | 'secondary'
        | 'textPrimary'
        | 'textSecondary'
        | 'error',
    variant?: 'h1'
        | 'h2'
        | 'h3'
        | 'h4'
        | 'h5'
        | 'h6'
        | 'subtitle1'
        | 'subtitle2'
        | 'body1'
        | 'body2'
        | 'caption'
        | 'button'
        | 'overline'
        | 'srOnly'
        | 'inherit'
}

const TypographyFormSubtitle = ({title, noWrap, color, variant}: TypographyFormSubtitleProps) => (
    <Typography variant={variant ? variant : "subtitle2"} noWrap={noWrap ? noWrap : false} color={color}>
        {title}
    </Typography>
)

export default TypographyFormSubtitle;