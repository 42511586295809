import React, { cloneElement } from 'react';
import { RefreshButton } from 'react-admin';
import ProductTypeActionsMenu from './ProductTypeActionsMenu/ProductTypeActionsMenu';
import { CardActions, makeStyles, Theme } from '@material-ui/core';
import { actionsStyles } from '../../../../theme/customTheme';

const styles = (theme: Theme) => ({
    ...actionsStyles(theme),
    menuListRoot: {
        padding: 0,
    },
    menuListItemRoot: {
        marginRight: 5,
    },
    menuRoot: {
        padding: '2px 10px 2px 5px',
    },
    menuIcon: {
        fontSize: 20,
    },
    menuTextRoot: {
        padding: 0,
        textTransform: 'uppercase',
    },
    menuText: {
        fontSize: '0.875rem',
        fontWeight: 'bold',
    },
    rootCheckbox: {
        padding: 0,
        //@ts-ignore
        marginRight: theme.spacing.unit,
    },
    multiSelectLabel: {
        color: theme.palette.primary.main,
        textTransform: 'uppercase',
        fontSize: 13,
        fontWeight: 500,
    },
    multiSelectLabelCheckbox: {
        padding: 6,
        color: theme.palette.primary.main,
    },
    formControlLabelRoot: {
        marginLeft: -5,
        marginRight: 5,
    },
    dialogTitle: {
        padding: '17px 20px 0 20px',
        paddingBottom: 15,
    },
    dialogTitleText: {
        textTransform: 'uppercase',
        fontWeight: 'bold',
        fontSize: 17,
    },
    container: {
        display: 'grid',
        gridTemplateColumns: '30px auto',
        alignItems: 'center',
        columnGap: '7px',
    },
    dialogActions: {
        marginRight: 20,
        marginBottom: 20,
    },
    button: {
        paddingLeft: 30,
        paddingRight: 30,
        marginLeft: 10,
    },
    circularProgress: {
        marginTop: '20px',
        marginBottom: '20px',
        overflowY: 'hidden',
    },
    dialogContent: {
        paddingBottom: 0,
    },
    dialogText: {
        color: 'black',
        fontSize: 13,
    },
    buttonMenu: {
        paddingLeft: 10,
    },
});
//@ts-ignore
const useStyles = makeStyles(styles);

const ProductTypeActionsToolbar = (props: any) => {
    const classes = useStyles();
    return (
        <CardActions className={classes.cardActions}>
            <ProductTypeActionsMenu />
            {props.filters && cloneElement(props.filters, { context: 'button' })}
            <RefreshButton />
        </CardActions>
    );
};

export default ProductTypeActionsToolbar;
