import { Identifier, Record } from 'react-admin';

export interface GroupMetadataAvatarType extends Record {
    name: string;
}

export interface MetadataAvatarType extends Record {
    name: string;
    alphaId: string;
}

export interface MetadataAvatarTypeProperty extends Record {
    name: string;
    definition: string;
    fieldType: string;
    option: string;
    order: number;
    isEncrypted: boolean;
    values: any[];
    metadataAvatarType: Identifier;
}

export interface AvatarProperty extends Record {
    name: string;
    definition: string;
    fieldType: string;
    option: string;
    order: number;
    isEncrypted: boolean;
    value: any;
    unit: string;
    languages: string[];
}

export interface MC extends Record {
    avatar: string;
    creationDate: string;
    pairedUp: boolean;
    yourls: string;
}

export interface Avatar extends Record {
    name: string;
    alphaId: string;
    metadataAvatarType: MetadataAvatarType | string;
    mcs: string[] | MC[];
    currentReferencing?: {
        standardReferencing?: {
            runner?: {
                node: Node;
                firstNode: Node;
            };
        };
    };
}

export interface Yourls extends Record {
    short: string;
    mc: string;
    mcUrl: string;
    finger: string;
    routeParameters: string[];
    creationDate: string;
    isActive: boolean;
}

export interface MCNote extends Record {
    createdAt: string;
    data: {
        customFields?: {
            tags: string[];
            thresholdColor: string;
            [key: string]: any;
        };
        [key: string]: any;
    };
    itemId: string;
}

export interface SourceBoard extends Record {
    id: string;
    productTypology: string;
    productTypeName: string;
    productTypeArticle: string;
    productSerialNumber: string;
    productPostalCode: string | null;
    productInstallationDate: string | null;
    productCurrentStatusName: string | null;
    productCurrentStatusDate: string | null;
    productLastMaintenanceDate: string | null;
    productLastEmptyingDate: string | null;
    productLastMaintenanceData: string | null;
    productLastEmptyingData: string | null;
    avatarId: string;
    metadataAvatarTypeId: string;
    companyId: string;
    name: string;
    description: string | null;
    latitude: string | null;
    longitude: string | null;
    elevation: string | null;
    street: string | null;
    additionalAddress: string | null;
    postalCode: string | null;
    city: string | null;
    country: string | null;
    addresstext: string | null;
    creationDate: string | null;
    descriptionpos: string | null;
    comment: string | null;
    supplements: string | null;
    alphaId: string;
    enabled: boolean;
    enableddate: string | null;
    identifiers: string | null;
    currentNode: string | null;
    locationId: string | null;
    locationPlaceId: string | null;
    updatedAt: string | null;
    lft: number;
    rgt: number;
    lvl: number;
    rootId: string;
    parentId: string | null;
    deleted: boolean;
}

export interface TraceabilityNotesThresholdExecutionsAppliedOnAtPropertyCreatedAtMetadata {
    id: number;
    max: number;
    min: number;
    color: string;
    name: string;
    durationType: string;
}

export interface TraceabilityNotesThresholdExecutions {
    id?: Identifier;
    businessProcessIds: string[];
    businessLogicMetadata: string[];
    metadataAvatarType: string | null;
    groupAvatar?: string | null;
    avatar?: string | null;
    appliedOn: string;
    appliedOnToLastItem: boolean;
    appliedOnAtProperty: string;
    appliedOnNoteAtTag: string;
    appliedOnAtPropertyCreatedAtComparingType: string;
    appliedOnAtPropertyCreatedAtMetadata: TraceabilityNotesThresholdExecutionsAppliedOnAtPropertyCreatedAtMetadata[];
}

export interface CompanyLogo {
    id: string;
    name: string;
}

export interface UserImage {
    id: string;
    name: string;
}

export interface Company {
    '@id': string;
    id: string;
    name: string;
    logo: CompanyLogo;
}

export interface User extends Record {
    id: string;
    name: string;
    firstName: string;
    lastName: string;
    username: string;
    company: Company;
    image?: UserImage;
    originId: string;
}

export interface Media extends Record {
    id: string;
    name: string;
    contentUrlTokenAccess: string;
    source: string;
}

export interface Node {
    id: string;
    name: string;
    extraDataClassmapper: string;
    extraData: any;
    dateOfTakesPlace: string;
    iconId: string;
    nextNodes: any[];
    configuration: {
        [key: string]: any;
        idForNodeHumanReadable: string;
        idWhenNodeWasAsAnArrayInRunnerTemplate: string;
    };
    nodeTypes: any;
}

export interface RunnerTemplate {
    id: string;
    name: string;
    description: string;
    isDeployed: boolean;
    creationDate: string;
    node: Node;
}

export interface TraceabilityHistory extends Record {
    createdAt: string;
    companyId: string;
    data: any[];
    itemId: string;
    avatarId: string;
    classMapper: string;
    role: string;
    id: 0;
    typeNoteId: string;
    itemResource: string;
    noteId: string;
    configuration: {
        [key: string]: any;
        idForNodeHumanReadable: string;
    };
}

export const ID_FOR_HUMAN_READABLE_IN_SERVICE = '0008';

export interface FileSystemMapper extends Record {}
