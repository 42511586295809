import { useTranslate, SelectInput } from 'react-admin';
import * as React from 'react';
import { Avatar, makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { THRESHOLD_LEVEL1_NAME, THRESHOLD_LEVEL2_NAME, THRESHOLD_LEVEL3_NAME } from '../../../utils/rikutec';
import { getColorByLevel } from '../../../utils/threshold/setPropertyByLevel';
import Grid from '@material-ui/core/Grid';
import { ReactNode } from 'react';

type ThresholdNameChoice = {
    id: string;
    name: string;
    color: ReactNode;
};

const useStyles = makeStyles(() => ({
    alignCenter: {
        alignSelf: 'center',
        marginRight: 12,
    },
}));

const ThresholdNameSelectInput = (props: { source: string; label: string }) => {
    const t = useTranslate();
    const classes = useStyles();

    return (
        <SelectInput
            size={'medium'}
            label={props.label}
            source={props.source}
            variant={'standard'}
            helperText={false}
            choices={[
                {
                    id: THRESHOLD_LEVEL1_NAME,
                    name: t('app.ancs.level1'),
                    color: (
                        <Avatar
                            style={{
                                backgroundColor: getColorByLevel(
                                    {
                                        target: {
                                            value: THRESHOLD_LEVEL1_NAME,
                                            name: THRESHOLD_LEVEL1_NAME,
                                        },
                                    },
                                    0,
                                    {}
                                )[0].value,
                            }}
                        >
                            {' '}
                            <Typography variant='h1' component='h2' />
                        </Avatar>
                    ),
                },
                {
                    id: THRESHOLD_LEVEL2_NAME,
                    name: t('app.ancs.level2'),
                    color: (
                        <Avatar
                            style={{
                                backgroundColor: getColorByLevel(
                                    {
                                        target: {
                                            value: THRESHOLD_LEVEL2_NAME,
                                            name: THRESHOLD_LEVEL2_NAME,
                                        },
                                    },
                                    0,
                                    {}
                                )[0].value,
                            }}
                        >
                            {' '}
                            <Typography variant='h1' component='h2' />
                        </Avatar>
                    ),
                },
                {
                    id: THRESHOLD_LEVEL3_NAME,
                    name: t('app.ancs.level3'),
                    color: (
                        <Avatar
                            style={{
                                backgroundColor: getColorByLevel(
                                    {
                                        target: {
                                            value: THRESHOLD_LEVEL3_NAME,
                                            name: THRESHOLD_LEVEL3_NAME,
                                        },
                                    },
                                    0,
                                    {}
                                )[0].value,
                            }}
                        >
                            {' '}
                            <Typography variant='h1' component='h2' />
                        </Avatar>
                    ),
                },
            ]}
            optionText={(choice: ThresholdNameChoice) => (
                <Grid container>
                    <Grid item className={classes.alignCenter}>
                        <Typography>{choice.name}</Typography>
                    </Grid>
                    <Grid item>{choice.color}</Grid>
                </Grid>
            )}
        />
    );
};

export default ThresholdNameSelectInput;
