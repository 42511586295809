import React, {useState} from "react";
import {makeStyles} from "@material-ui/core";
import TypographyFormTitle from "../../../../forms/typographyFormTitle";
import {useTranslate, useRefresh, useNotify, useMutation} from "react-admin";
import TypographyFormSubtitle from "../../../../forms/typographyFormSubtitle";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import {
    ActionFormButtonStyles,
    APP_BUTTON_COLOR_PRIMARY,
} from "../../../../../theme/customTheme";
import {useDispatch, useSelector} from "react-redux";
import {CustomReducerState} from "../../../../../redux/reducers";
import {TRACEABILITY_NOTES_THRESHOLD_EXECUTIONS} from "../../../../../providers/resources";
import {
    getCodeFromViolationMessage,
    getViolations,
    ViolationResolved,
} from "../../../../../utils/validations/parseValidations";
import {selectThresholds} from "../../../../../redux/actions/thresholds";

const useStyles = makeStyles(() => ({
    deleteIcon: {
        color: APP_BUTTON_COLOR_PRIMARY,
    },
    deleteIconDisabled: {
        color: "gray",
    },
    addButtonRoot: {
        ...ActionFormButtonStyles.root,
    },
    addButtonRootText: {
        ...ActionFormButtonStyles.root,
    },
    gridItemTitle: {
        marginTop: 4,
        marginBottom: 4,
    },
    buttonDelete: {
        backgroundColor: APP_BUTTON_COLOR_PRIMARY,
        paddingLeft: 21,
        paddingRight: 21,
        paddingTop: 10,
        paddingBottom: 10,
        marginBottom: 16,
        "&:hover": {
            backgroundColor: APP_BUTTON_COLOR_PRIMARY,
            paddingLeft: 21,
            paddingRight: 21,
            paddingTop: 10,
            paddingBottom: 10,
        },
    },
    textButtonDelete: {
        color: "white",
        fontWeight: "bold",
    },
}));

type ManagementThresholdsDeleteProps = {
    open: boolean;
    handleClose: () => void;
};

const ManagementThresholdsDeleteForm = ({
                                            open,
                                            handleClose,
                                        }: ManagementThresholdsDeleteProps) => {
    const translate = useTranslate();
    const [_open, setOpen] = useState<boolean>(open);

    const refresh = useRefresh();
    const dispatch = useDispatch();
    const notify = useNotify();

    const classes = useStyles();

    const {rikutec: {thresholds: {selected: thresholdSelected}}} = useSelector((state: CustomReducerState) => state)

    const _handleClose = () => {
        handleClose();
        setOpen(false);
    };

    const [removeTraceabilityNotesThresholdExecutions] = useMutation({
        type: "delete",
        resource: TRACEABILITY_NOTES_THRESHOLD_EXECUTIONS,
    });

    const deleteFn = () => {
        removeTraceabilityNotesThresholdExecutions(
            {
                payload: {
                    id: thresholdSelected?.id,
                },
            },
            {
                onSuccess: () => {
                    dispatch(selectThresholds.fn(undefined));
                    notify("app.traceabilityNotesThreshold.deleted", "info");
                    handleClose();
                    refresh();
                },
                onFailure: (...args: any[]) => {
                    const violations: ViolationResolved[] = getViolations(
                        args[0]
                    );

                    if (violations && violations.length > 0) {
                        const violation = violations[0];
                        notify(
                            `app.${getCodeFromViolationMessage(
                                violation?.message
                            )}`,
                            "warning"
                        );
                    } else {
                        notify(
                            "app.traceabilityNotesThreshold.error.server",
                            "warning"
                        );
                    }
                    handleClose();
                },
            }
        );
    };

    return (
        <Dialog
            open={_open && !!thresholdSelected}
            onClose={_handleClose}
            aria-labelledby="form-dialog-title"
            fullWidth
            maxWidth={"sm"}
        >
            <DialogContent>
                <Grid item className={classes.gridItemTitle}>
                    <TypographyFormTitle
                        title={translate(
                            "app.traceabilityNotesThreshold.actions.delete.title"
                        )}
                        noWrap={true}
                        color={"initial"}
                    />
                </Grid>

                <Grid item xs>
                    <TypographyFormSubtitle
                        title={translate(
                            "app.traceabilityNotesThreshold.actions.delete.message"
                        )}
                        noWrap={false}
                        color={"initial"}
                    />
                </Grid>

                <Grid container justifyContent={"flex-end"} spacing={2}>
                    <Grid item>
                        <Button onClick={_handleClose} color="primary">
                            {translate("app.button.cancel")}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            classes={{text: classes.textButtonDelete}}
                            onClick={deleteFn}
                            color="primary"
                            className={classes.buttonDelete}
                        >
                            {translate("app.button.confirm")}
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default ManagementThresholdsDeleteForm;
