import LinearProgress from '@material-ui/core/LinearProgress';
import * as React from 'react';
import { toFormatDate } from '../../utils/date';
import { Avatar, TraceabilityHistory } from '../../utils/types';

interface InstallationDateFieldProps {
    record?: Avatar;
    headerClassName?: string;
    loaded?: boolean;
    error?: any;
    historyNodeInService?: TraceabilityHistory;
}

const InstallationDateField = ({
    record,
    loaded,
    headerClassName,
    historyNodeInService,
    error,
}: InstallationDateFieldProps) => {
    return (
        <>
            {historyNodeInService && <span>{toFormatDate(historyNodeInService.createdAt)}</span>}
            {!historyNodeInService && loaded && <span />}
            {!loaded && !error && <LinearProgress />}
        </>
    );
};

export default InstallationDateField;
