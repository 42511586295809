import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as SquareIcon } from '../../home_black_24dp 1.svg';
import Toolbar from '@material-ui/core/Toolbar';
import {
    AppBar,
    Drawer,
    IconButton,
    ListItemIcon,
    makeStyles,
    MenuItem,
    MenuList,
    Tooltip,
    Typography,
} from '@material-ui/core';

import ScIconText from '../../theme/360scIconsvg';
import { Identifier, Record, ReduxState, useDataProvider, useTranslate } from 'react-admin';
import { CustomReducerState } from '../../redux/reducers';
import { User } from '../../utils/types';
import UserInformation from './userInformation';
import { APP_BAR_MAX_HEIGHT, APP_ITEM_SELECTED_BACKGROUND_COLOR_ON_LIST } from '../../theme/customTheme';
import { getImage, mediaCameFromToFileSystemMapperService, resolveMedia } from '../../utils/medias';
import { companyLogoUrlFound } from '../../redux/actions/users';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import SettingsIcon from '@material-ui/icons/Settings';
import { ancs, ancsEvery2Hours, product_types, thresholds, types } from '../../pages/paths';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    sideBarRoot: {
        height: '100vh',
        overflowY: 'hidden',
        width: 275,
        backgroundColor: '#f1f1f1',
    },
    containerImage: {
        height: '40px',
        width: '40px',
        marginLeft: 30,
        marginRight: 30,
    },
    imageRounded: {
        borderRadius: '50%',
        border: '1px solid white',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    imageIcon: {
        height: '100%',
    },
    iconRoot: {
        textAlign: 'center',
    },
    main: {
        flexGrow: 1,
        backgroundColor: theme.palette.grey[800],
        boxShadow: 'none',
        maxHeight: APP_BAR_MAX_HEIGHT,
    },
    divMain: {
        height: APP_BAR_MAX_HEIGHT,
    },
    title: {
        marginLeft: '150px',
        flex: 1,
    },
    badgeRoot: {
        position: 'unset',
    },
    badge: {
        top: 'unset',
        right: 'unset',
        left: '5px',
    },
    notificationButton: {
        color: theme.palette.common.white,
        textTransform: 'capitalize',
    },
    divBackButton: {
        backgroundColor: '#424242',
    },
    backArrow: {
        color: 'white',
    },
    iconMenu: {
        color: 'whitesmoke !important',
        marginRight: '14px',
    },
    rootMenuItem: {
        height: '35px',
        paddingLeft: '8px',
        '&$selectedMenuItem': {
            backgroundColor: APP_ITEM_SELECTED_BACKGROUND_COLOR_ON_LIST,
        },
        display: 'flex',
        justifyContent: 'flex-start',
    },
    rootListItemIcon: {
        color: theme.palette.primary.main,
        padding: 0,
        fontWeight: 800,
        display: 'inline-flex',
        minWidth: 'unset',
        marginRight: '8px',
    },
    body2Typography: {
        fontWeight: 400,
        maxWidth: '190px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    subtitle2Typography: {
        fontSize: 10,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
}));

const PlatformAppBar = ({ permissions }: { permissions: string[] }) => {
    const [sideBarIsOpen, setSideBarIsOpen] = useState(false);
    const translate = useTranslate();
    const {
        push,
        location: { pathname },
    } = useHistory();

    const schemaAndHostUrlRootServer: string | undefined = process.env.REACT_APP_SCHEMA_AND_HOST_URL_ROOT_SERVER;
    const companyLogoSrc360 = `images/logo_360sc.svg`;

    const dataProvider = useDataProvider();
    const dispatch = useDispatch();
    const companyLogoUrlFromUserState = useSelector((state: CustomReducerState) => state.rikutec.users.companyLogoUrl);

    const [companyLogoName, setCompanyLogoName] = useState<string | null>(null);
    const [companyName, setCompanyName] = useState<string | null>(null);
    const [companyLogoUrlInFileSystemMapper, setCompanyLogoUrlInFileSystemMapper] = useState<string | null>();

    const [userName, setUserName] = useState<string | null>(null);
    const [userImageSrc, setImageSrc] = useState<string | null>(
        `${schemaAndHostUrlRootServer}/images/default-user.svg`
    );
    const classes = useStyles();

    const userConnected: User | Record | null = useSelector(
        (state: ReduxState & CustomReducerState) => state.rikutec.users.userConnected
    );

    const resolveLogo = async (logoUri: Identifier) => {
        const { data } = await resolveMedia(logoUri, dataProvider);
        if (data && data.id && mediaCameFromToFileSystemMapperService(data) && !companyLogoUrlFromUserState) {
            const imageUrl = await getImage(data, userConnected?.company.id);
            imageUrl && dispatch(companyLogoUrlFound.fn(imageUrl));
        } else {
            setCompanyLogoName(userConnected?.company.logo.name);
        }
    };
    const loadLogoToDisplay = () => {
        if (userConnected) {
            setCompanyName(userConnected.company.name);
            setUserName(`${userConnected.firstName} ${userConnected.lastName}`);
            if (userConnected?.image?.name) {
                setImageSrc(`${schemaAndHostUrlRootServer}/files/${userConnected?.image?.name}`);
            }
            if (userConnected?.company?.logo && '@id' in userConnected?.company?.logo) {
                resolveLogo(userConnected?.company.logo['@id']).finally();
            }
        }
    };

    const updateCompanyLogo = () => {
        if (companyLogoUrlFromUserState && !companyLogoUrlInFileSystemMapper) {
            setCompanyLogoUrlInFileSystemMapper(companyLogoUrlFromUserState);
        }
    };

    const showAncs = () => {
        push(ancs);
        toggleSideBar();
    };

    const showAncsEvery2Hours = () => {
        push(ancsEvery2Hours);
        toggleSideBar();
    };

    const showThresholdView = () => {
        push(thresholds);
        toggleSideBar();
    };

    const showTypesView = () => {
        push(types);
        toggleSideBar();
    };

    const showProductTypes = () => {
        push(product_types);
        toggleSideBar();
    };

    useEffect(
        loadLogoToDisplay,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [userConnected, schemaAndHostUrlRootServer]
    );

    useEffect(updateCompanyLogo, [companyLogoUrlFromUserState, companyLogoUrlInFileSystemMapper]);

    const companyLogoSrc = companyLogoName
        ? `${schemaAndHostUrlRootServer}/files/${companyLogoName}`
        : `${schemaAndHostUrlRootServer}/${companyLogoSrc360}`;

    const MyLogoSvgImage = new Image();
    MyLogoSvgImage.src = 'data:image/svg+xml,' + escape(ScIconText);
    const toggleSideBar = () => setSideBarIsOpen(!sideBarIsOpen);

    return (
        <>
            <AppBar style={{ zIndex: 0 }} className={classes.main} data-testid='app-bar'>
                <Toolbar>
                    <IconButton
                        edge='start'
                        aria-label='menu'
                        id='left-icon'
                        className={classes.iconMenu}
                        onClick={toggleSideBar}
                        data-testid='icon-button-menu'
                    >
                        <MenuIcon />
                    </IconButton>
                    <img
                        alt={''}
                        height='50px'
                        src={MyLogoSvgImage.src}
                        // style={{ marginRight: 5 }}
                        style={{
                            height: '35px',
                            width: '77px',
                            position: 'absolute',
                            right: 15,
                        }}
                    />

                    <img
                        alt={''}
                        style={{
                            height: 35,
                            width: 'auto',
                            maxHeight: 35,
                            maxWidth: 70,
                        }}
                        src={!companyLogoUrlInFileSystemMapper ? companyLogoSrc : companyLogoUrlInFileSystemMapper}
                    />
                </Toolbar>
            </AppBar>
            <Drawer open={sideBarIsOpen} id='left-drawer' onClose={toggleSideBar} data-testid='drawer-menu'>
                <div className={classes.divBackButton}>
                    <IconButton onClick={toggleSideBar}>
                        <ArrowBackIcon className={classes.backArrow} />
                    </IconButton>
                </div>
                <div className={classes.sideBarRoot}>
                    <UserInformation companyName={companyName} userName={userName} userImageSrc={userImageSrc} />
                    <br />
                    <MenuList data-testid='ul-menu'>
                        {permissions?.some((item: string) => item === 'ROLE_VIEW_OBJECTS') &&
                            permissions?.some((item: string) => item === 'ROLE_READ_AVATAR') && (
                                <MenuItem
                                    id={'ancs_menuItem'}
                                    selected={pathname === ancs}
                                    onClick={showAncs}
                                    classes={{
                                        root: classes.rootMenuItem,
                                    }}
                                    data-testid='item-menu'
                                >
                                    <ListItemIcon className={classes.rootListItemIcon}>
                                        <SearchIcon />
                                    </ListItemIcon>
                                    <Tooltip title={translate('app.sidebar.anc')}>
                                        <div>
                                            <Typography
                                                variant='body2'
                                                classes={{ body2: classes.body2Typography }}
                                                style={{ flexGrow: 1 }}
                                                role='item-menu-text'
                                            >
                                                {translate('app.sidebar.anc')}
                                            </Typography>
                                            <Typography
                                                variant='subtitle2'
                                                classes={{ subtitle2: classes.subtitle2Typography }}
                                                style={{ flexGrow: 1 }}
                                                role='item-menu-text'
                                            >
                                                {translate('app.sidebar.realTime')}
                                            </Typography>
                                        </div>
                                    </Tooltip>
                                </MenuItem>
                            )}
                        {permissions?.some((item: string) => item === 'ROLE_VIEW_OBJECTS') &&
                            permissions?.some((item: string) => item === 'ROLE_READ_AVATAR') && (
                                <MenuItem
                                    id={'ancsEvery2Hours_menuItem'}
                                    selected={pathname === ancsEvery2Hours}
                                    onClick={showAncsEvery2Hours}
                                    classes={{
                                        root: classes.rootMenuItem,
                                    }}
                                    data-testid='item-menu'
                                >
                                    <ListItemIcon className={classes.rootListItemIcon}>
                                        <SearchIcon />
                                    </ListItemIcon>
                                    <Tooltip title={translate('app.sidebar.anc')}>
                                        <div>
                                            <Typography
                                                variant='body2'
                                                classes={{ body2: classes.body2Typography }}
                                                style={{ flexGrow: 1 }}
                                                role='item-menu-text'
                                            >
                                                {translate('app.sidebar.anc')}
                                            </Typography>
                                            <Typography
                                                variant='subtitle2'
                                                classes={{ subtitle2: classes.subtitle2Typography }}
                                                style={{ flexGrow: 1 }}
                                                role='item-menu-text'
                                            >
                                                {translate('app.sidebar.every2Hours')}
                                            </Typography>
                                        </div>
                                    </Tooltip>
                                </MenuItem>
                            )}
                        {permissions?.some((item: string) => item === 'ROLE_VIEW_GROUP_OBJECT_TYPES') &&
                            permissions?.some((item: string) => item === 'ROLE_READ_GROUP_OBJECT_TYPE') && (
                                <MenuItem
                                    selected={pathname.includes(product_types)}
                                    onClick={showProductTypes}
                                    classes={{
                                        root: classes.rootMenuItem,
                                    }}
                                    data-testid='item-menu'
                                >
                                    <ListItemIcon className={classes.rootListItemIcon}>
                                        <SquareIcon />
                                    </ListItemIcon>
                                    <Tooltip title={translate('app.sidebar.product_type')}>
                                        <Typography
                                            variant='body2'
                                            classes={{ body2: classes.body2Typography }}
                                            style={{ flexGrow: 1 }}
                                            role='item-menu-text'
                                        >
                                            {translate('app.sidebar.product_type')}
                                        </Typography>
                                    </Tooltip>
                                </MenuItem>
                            )}
                        {permissions?.some((item: string) => item === 'ROLE_VIEW_OBJECT_TYPES') &&
                            permissions?.some((item: string) => item === 'ROLE_READ_OBJECT_TYPE') && (
                                <MenuItem
                                    selected={pathname.includes(types)}
                                    onClick={showTypesView}
                                    classes={{
                                        root: classes.rootMenuItem,
                                    }}
                                    data-testid='item-menu'
                                >
                                    <ListItemIcon className={classes.rootListItemIcon}>
                                        <SquareIcon />
                                    </ListItemIcon>
                                    <Tooltip title={translate('app.sidebar.type')}>
                                        <Typography
                                            variant='body2'
                                            classes={{ body2: classes.body2Typography }}
                                            style={{ flexGrow: 1 }}
                                            role='item-menu-text'
                                        >
                                            {translate('app.sidebar.type')}
                                        </Typography>
                                    </Tooltip>
                                </MenuItem>
                            )}
                        <MenuItem
                            selected={pathname.includes(thresholds)}
                            onClick={showThresholdView}
                            classes={{
                                root: classes.rootMenuItem,
                            }}
                            data-testid='item-menu'
                        >
                            <ListItemIcon className={classes.rootListItemIcon}>
                                <SettingsIcon />
                            </ListItemIcon>
                            <Tooltip title={translate('app.sidebar.threshold_settings')}>
                                <Typography
                                    variant='body2'
                                    classes={{ body2: classes.body2Typography }}
                                    style={{ flexGrow: 1 }}
                                    role='item-menu-text'
                                >
                                    {translate('app.sidebar.threshold_settings')}
                                </Typography>
                            </Tooltip>
                        </MenuItem>
                    </MenuList>
                </div>
            </Drawer>
        </>
    );
};

export default PlatformAppBar;
