import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React, { useEffect } from 'react';
import { FunctionField, List, Record, ReduxState, useLoading, useTranslate } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import { product_types } from '../../../pages/paths';
import {
    GROUP_METADATA_AVATAR_TYPE,
    GROUP_METADATA_AVATAR_TYPES,
    MEDIA_TAG_APPROVAL,
} from '../../../providers/resources';
import {
    addToGroupMetadataAvatarTypeData,
    selectGroupMetadataAvatarType,
} from '../../../redux/actions/group.metadata.avatar.type.actions';
import { CustomReducerState } from '../../../redux/reducers';
import { tableStyles } from '../../../theme/customTheme';
import { getNameWithoutPrefix } from '../../../utils/resources/MetadataAvatarType';
import { GroupMetadataAvatarType } from '../../../utils/types';
import BackDiv from '../../UI/BackDiv/BackDivContainer';
import Breadcrumbs from '../../UI/Breadcrumbs/Breadcrumbs';
import DataGridCustom from '../../UI/DataGrid';
import MediaCell from '../../UI/MediaCell/MediaCell';
import ProductTypeActionsFilters from './ProductTypeActionsToolbar/ProductTypeActionsFilters/ProductTypeActionsFilters';
import ProductTypeActionsToolbar from './ProductTypeActionsToolbar/ProductTypeActionsToolbar';

const styles = () => ({ ...tableStyles() });
//@ts-ignore
const useStyles = makeStyles(styles);
const ProductTypeList = () => {
    const t = useTranslate();
    const classes = useStyles();
    const dispatch = useDispatch();
    const loading = useLoading();
    const selectedGMDAT: GroupMetadataAvatarType | Record | null = useSelector(
        (state: ReduxState & CustomReducerState) => {
            const selectedGroupMetadataAvatarTypeUri = (
                state.rikutec.groupsMetadataAvatarType.selected as GroupMetadataAvatarType
            )['@id'];
            return selectedGroupMetadataAvatarTypeUri &&
                selectedGroupMetadataAvatarTypeUri in state.admin.resources[GROUP_METADATA_AVATAR_TYPES].data
                ? state.admin.resources[GROUP_METADATA_AVATAR_TYPES].data[selectedGroupMetadataAvatarTypeUri]
                : null;
        }
    );

    const listProps = {
        resource: GROUP_METADATA_AVATAR_TYPES,
        hasCreate: true,
        hasEdit: true,
        hasList: true,
        hasShow: false,
        basePath: product_types,
        sort: { field: 'createdAt', order: 'ASC' },
        perPage: 10,
        filter: {
            serialization_groups: ['group_metadata_avatar_type_files'],
        },
    };
    // noinspection JSUnusedGlobalSymbols
    const dataGridProps = {
        rowClick: (id: string, basePath: string, record: Record) => {
            dispatch(selectGroupMetadataAvatarType(record));
            return null;
        },
        toFindSelected: {
            resource: 'groupsMetadataAvatarType',
            key: 'selected',
        },
        hasBulkActions: null,
        classes: {
            checkbox: classes.checkbox,
            row: classes.list,
        },
    };
    useEffect(() => {
        if (
            selectedGMDAT &&
            selectedGMDAT.hasOwnProperty('@id') &&
            !loading &&
            //eslint-disable-next-line
            selectedGMDAT.files !== selectedGMDAT.files
        ) {
            dispatch(selectGroupMetadataAvatarType(selectedGMDAT['@id']));
        }
    }, [loading, dispatch, selectedGMDAT]);
    return (
        <Grid container direction='column' data-testid='ProductTypeList'>
            <Grid item>
                <Breadcrumbs items={[t('app.headers.productType')]} />
            </Grid>
            <BackDiv>
                <Grid item>
                    <List
                        {...listProps}
                        filters={<ProductTypeActionsFilters />}
                        actions={<ProductTypeActionsToolbar />}
                        bulkActionButtons={false}
                    >
                        <DataGridCustom {...dataGridProps}>
                            <FunctionField
                                sortable={true}
                                sortBy='name'
                                source='name'
                                label={t('app.productType.name')}
                                render={(record: Record | undefined) => getNameWithoutPrefix(record?.['name'])}
                            />
                            <FunctionField
                                label={t('app.productType.approval')}
                                source='medias'
                                render={(record: Record | undefined) => (
                                    <MediaCell
                                        record={record!}
                                        mediaTag={MEDIA_TAG_APPROVAL}
                                        objectType={GROUP_METADATA_AVATAR_TYPE}
                                        reduxUpdater={addToGroupMetadataAvatarTypeData}
                                        mediasObjectContainer='medias'
                                        storeContainer='groupsMetadataAvatarType'
                                    />
                                )}
                            />
                        </DataGridCustom>
                    </List>
                </Grid>
            </BackDiv>
        </Grid>
    );
};

export default ProductTypeList;
