import {
    companyLogoUrlFound,
    CompanyLogoUrlFoundAction,
    resolvedUserConnected,
    ResolvedUserConnectedAction,
    tokenFound,
    TokenFoundAction,
} from "../actions/users";
import { getTokenData, TokenDataDecoded } from "../../utils/token";
import { User } from "../../utils/types";

export interface UsersState {
    userConnected: User | null;
    tokenData: TokenDataDecoded | null;
    companyLogoUrl: string | null;
}

const InitialState: UsersState = {
    userConnected: null,
    tokenData: null,
    companyLogoUrl: null,
};

const reducer = (state: UsersState = InitialState, action: any) => {
    switch (action.type) {
        case tokenFound.action: {
            const iAction: TokenFoundAction = action;
            const tokenData: TokenDataDecoded | null = getTokenData(
                iAction.payload.token
            );
            return {
                ...state,
                tokenData: {
                    ...tokenData,
                },
            };
        }
        case resolvedUserConnected.action: {
            const iAction: ResolvedUserConnectedAction = action;
            return {
                ...state,
                userConnected: {
                    ...iAction.payload.user,
                },
            };
        }
        case companyLogoUrlFound.action: {
            const iAction: CompanyLogoUrlFoundAction = action;
            return {
                ...state,
                companyLogoUrl: iAction.payload.companyLogoUrl,
            };
        }
        default:
            return state;
    }
};

export default reducer;
