const uniformParams = (params: any): any => {
    Object.keys(params).forEach((key: string) => {
        if (key.search('__') !== -1) {
            const [rootVar, innerVar] = key.split('__');
            params[`${rootVar}[${innerVar}]`] = params[key];
            delete params[key];
        }
    });

    return params;
};

export { uniformParams };
