const extractIdFromURI = (URI: string) => {
    if (!URI) return null;

    const mat = URI.split("/");

    return mat[mat.length - 1];
};

const getResourceURIFROMAnotherURI = (
    anotherURI: string,
    id: string,
    resource: string
): string | null => {
    const anotherUriMatched = anotherURI.match("(.*)\\/(\\w+)\\/(\\w+)");
    if (anotherUriMatched && anotherUriMatched.length > 0) {
        return `${
            // @ts-ignore
            anotherURI.match("(.*)\\/(\\w+)\\/(\\w+)")[1]
        }/${resource}/${id}`;
    }
    return null;
};

export { extractIdFromURI, getResourceURIFROMAnotherURI };
