import React from 'react';
import { List, FunctionField, useTranslate, ReferenceField, Record } from 'react-admin';
import { METADATA_AVATAR_TYPES, TRACEABILITY_NOTES_THRESHOLD_EXECUTIONS } from '../../providers/resources';
import { makeStyles } from '@material-ui/core';
import ActionList from './actions/managementThresholds/index';
import { thresholds } from '../../pages/paths';
import Chip from '@material-ui/core/Chip';
import { MetadataAvatarType, TraceabilityNotesThresholdExecutions } from '../../utils/types';
import { getNameWithoutPrefix } from '../../utils/resources/MetadataAvatarType';
import { useDispatch } from 'react-redux';
import { selectThresholds } from '../../redux/actions/thresholds';
import { divContainerList, tableStyles } from '../../theme/customTheme';
import { getNameTranslated } from '../../utils/threshold/setPropertyByLevel';
import Grid from '@material-ui/core/Grid';
import Breadcrumbs from '../UI/Breadcrumbs/Breadcrumbs';
import BackDiv from '../UI/BackDiv/BackDivContainer';
import DataGridCustom from '../UI/DataGrid';

//@ts-ignore
const useStyles = makeStyles(() => ({
    ...tableStyles(),
    field: {
        fontWeight: 'bold',
        textAlign: 'center',
    },
    chip: {
        marginRight: 3,
    },
    container: {
        ...divContainerList.main,
    },
    list: {
        with: '100%',
    },
}));

const listProps = {
    resource: TRACEABILITY_NOTES_THRESHOLD_EXECUTIONS,
    hasCreate: false,
    hasEdit: false,
    hasList: true,
    hasShow: false,
    basePath: thresholds,
    sort: { field: 'createdAt', order: 'DESC' },
    perPage: 10,
    filter: {},
    bulkActionButtons: false,
    exporter: false,
    empty: false,
};

const ThresholdsNotificationList = () => {
    const t = useTranslate();
    const classes = useStyles();
    const dispatch = useDispatch();

    // noinspection JSUnusedGlobalSymbols
    const dataGridProps = {
        rowClick: (id: string, basePath: string, record: Record) => {
            dispatch(selectThresholds.fn(record));
            return null;
        },
        toFindSelected: {
            resource: 'thresholds',
            key: 'selected',
        },
        hasBulkActions: null,
        classes: {
            checkbox: classes.checkbox,
            row: classes.list,
        },
    };

    return (
        <Grid container direction='column'>
            <Grid item>
                <Breadcrumbs items={[t('app.headers.thresholds')]} />
            </Grid>
            <BackDiv>
                <Grid item>
                    <List
                        {...listProps}
                        actions={<ActionList />}
                        bulkActionButtons={false}
                        empty={false}
                        exporter={false}
                    >
                        <DataGridCustom {...dataGridProps}>
                            <ReferenceField
                                link={false}
                                sortable={true}
                                label={t('app.ancs.productTypology')}
                                reference={METADATA_AVATAR_TYPES}
                                source='metadataAvatarType'
                                sortBy='metadataAvatarType.name'
                            >
                                <FunctionField
                                    sortable={true}
                                    // @ts-ignore
                                    render={(record: MetadataAvatarType) => `${getNameWithoutPrefix(record.name)}`}
                                />
                            </ReferenceField>

                            <FunctionField
                                label={t('app.traceabilityNotesThreshold.operationType')}
                                sortable
                                sortBy='appliedOnNoteAtTag'
                                // @ts-ignore
                                render={(record: TraceabilityNotesThresholdExecutions) => {
                                    return t(`app.ancs.${record.appliedOnNoteAtTag.toLowerCase()}`);
                                }}
                            />

                            <FunctionField
                                label={t('app.traceabilityNotesThreshold.activeEvent')}
                                sortable={false}
                                // @ts-ignore
                                render={(record: TraceabilityNotesThresholdExecutions) => {
                                    return record.appliedOnAtPropertyCreatedAtMetadata.map((item, index) => {
                                        return (
                                            <Chip
                                                className={classes.chip}
                                                label={t(getNameTranslated(item.name))}
                                                key={`function-field-${index}`}
                                            />
                                        );
                                    });
                                }}
                            />
                        </DataGridCustom>
                    </List>
                </Grid>
            </BackDiv>
        </Grid>
    );
};

export default ThresholdsNotificationList;
