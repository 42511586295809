import {withStyles} from '@material-ui/core/styles';
import compose from 'recompose/compose';
import BackDiv from './BackDiv';

const styles = () => ({
    backDiv: {
        borderTop: '2px solid #C4D600',
        width: '100%',
        overflow: 'auto',
        backgroundColor: 'white',
        position: 'relative'
    },
});

const enhance = compose(withStyles(styles));

export default enhance(BackDiv);
