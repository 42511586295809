import { useSafeSetState } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { resolveApiSchemaCreator } from '../redux/actions/hydraDataProvider';
import { CustomReducerState } from '../redux/reducers';

const useApiSchema = () => {
    const dispatch = useDispatch();
    const apiSchema = useSelector((state: CustomReducerState) => state.rikutec.hydraDataProvider.apiSchema);
    const [loading, setLoading] = useSafeSetState(apiSchema === null);

    useEffect(
        () => {
            if (!apiSchema) {
                dispatch(resolveApiSchemaCreator.fn());
            } else {
                setLoading(false);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [apiSchema]
    );

    return loading;
};

export default useApiSchema;
