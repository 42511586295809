import Delete from '@material-ui/icons/Delete';
import FiberNew from '@material-ui/icons/FiberNew';
import Settings from '@material-ui/icons/Settings';
import React, { useState } from 'react';
import { Identifier, Record, ReduxState, usePermissions, useTranslate } from 'react-admin';
import { useSelector } from 'react-redux';
import { METADATA_AVATAR_TYPES } from '../../../../providers/resources';
import { CustomReducerState } from '../../../../redux/reducers';
import { Company, MetadataAvatarType } from '../../../../utils/types';
import MenuButtonActions from '../../../UI/MenuButtonActions/MenuButtonActionsContainer';
import AddMediaForm from './AddMediaForm';
import DlgForm from './form/DlgForm';

export default function SimpleActionMenu() {
    const t = useTranslate();
    // const notify = useNotify();
    // const refresh = useRefresh();
    const { permissions } = usePermissions();

    const selectedType: MetadataAvatarType | Record | null = useSelector((state: ReduxState & CustomReducerState) => {
        const selectedMDATUri = (state.rikutec.types.selected as MetadataAvatarType)['@id'];
        return selectedMDATUri && selectedMDATUri in state.admin.resources[METADATA_AVATAR_TYPES].data
            ? state.admin.resources[METADATA_AVATAR_TYPES].data[selectedMDATUri]
            : null;
    });

    const selectedTypeUri: Identifier | null = useSelector((state: ReduxState & CustomReducerState) => {
        const selectTypeIndexFound =
            selectedType && 'id' in selectedType
                ? state.admin.resources[METADATA_AVATAR_TYPES].list.ids.findIndex((id) => id === selectedType.id)
                : -1;
        return selectTypeIndexFound >= 0
            ? // @ts-ignore
              state.admin.resources[METADATA_AVATAR_TYPES].list.ids[selectTypeIndexFound]
            : null;
    });
    const [dlgForm, setDlgForm] = useState<{ isOpen: boolean; action: 'CREATE' | 'EDIT' }>({
        isOpen: false,
        action: 'CREATE',
    });
    // const [askConfirmation, setAskConfirmation] = useState(false);
    // const [deleteOne, { loading }] = useDelete(
    //     METADATA_AVATAR_TYPES,
    //     (selectedType as MetadataAvatarType)?.id,
    //     selectedType,
    //     {
    //         onSuccess: () => {
    //             refresh();
    //             toggleAskConfirmation();
    //             notify(t('app.types.messages.success'), 'success');
    //         },
    //         onFailure: () => notify(t('app.types.messages.error'), 'error'),
    //     }
    // );
    const [mediaFormState, setMediaFormState] = useState<{
        isOpen: boolean;
        mediaType: 'installing_guide';
    }>({
        isOpen: false,
        mediaType: 'installing_guide',
    });

    const userConnectedCompany: Company | Record | undefined = useSelector(
        (state: ReduxState & CustomReducerState) => state.rikutec.users?.userConnected?.company
    );
    const toggleDlgFormCreate = () => setDlgForm((prevState) => ({ isOpen: !prevState.isOpen, action: 'CREATE' }));
    const toggleDlgFormEdit = () => setDlgForm((prevState) => ({ isOpen: !prevState.isOpen, action: 'EDIT' }));
    const cancelForm = () => setDlgForm({ isOpen: false, action: 'CREATE' });

    const [menuState, setMenuState] = useState(false);

    const handleToggle = () => {
        setMenuState(!menuState);
    };
    // const openInstallingGuideForm = () => {
    //     setMediaFormState({ isOpen: true, mediaType: 'installing_guide' });
    //     handleToggle();
    // };
    const toggleMediaForm = () => {
        setMediaFormState({ isOpen: false, mediaType: 'installing_guide' });
    };

    const menuActions = [
        {
            icon: <FiberNew />,
            text: t('app.actions.addItem'),
            onClick: toggleDlgFormCreate,
            disabled:
                permissions?.every((item: string) => item !== 'ROLE_VIEW_OBJECTS_CREATE') ||
                permissions?.every((item: string) => item !== 'ROLE_CREATE_OBJECT_TYPE') ||
                !userConnectedCompany,
        },
        {
            icon: <Settings />,
            text: t('app.actions.editItem'),
            onClick: toggleDlgFormEdit,
            disabled:
                // !ids.includes(selectedType.id) ||
                !selectedTypeUri ||
                permissions?.every((item: string) => item !== 'ROLE_VIEW_OBJECTS_EDIT') ||
                permissions?.every((item: string) => item !== 'ROLE_UPDATE_OBJECT_TYPE') ||
                !userConnectedCompany,
        },
        // {
        //     icon: <EditFileIcon />,
        //     text: t('app.types.addEditInstallingGuide'),
        //     onClick: openInstallingGuideForm,
        //     disabled:
        //         !selectedTypeUri ||
        //         !selectedMDAT ||
        //         permissions?.every((item: string) => item !== 'ROLE_FM_CLOUD_WRITE'),
        // },
        {
            icon: <Delete />,
            text: t('app.actions.deleteItem'),
            // onClick: toggleAskConfirmation,
            onClick: () => {},
            disabled: true,
        },
    ];

    return (
        <>
            <MenuButtonActions
                buttonLabel={t('app.actions.title')}
                overwriteButtonLabel
                menuActions={menuActions}
                menuState={menuState}
                onButtonClick={handleToggle}
            />

            <DlgForm isOpen={dlgForm.isOpen} cancel={cancelForm} action={dlgForm.action} />
            <AddMediaForm isOpen={mediaFormState.isOpen} type={mediaFormState.mediaType} toggle={toggleMediaForm} />
            {/*<ConfirmDlg isOpen={askConfirmation} toggle={toggleAskConfirmation} apply={deleteOne} deleting={loading} />*/}
        </>
    );
}
