import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { withContext } from 'recompose';
import { createHashHistory } from 'history';
import PropTypes from 'prop-types';

//@ts-ignore
import { AuthContext, DataProviderContext, Notification, Resource, TranslationProvider } from 'react-admin';

import { ThemeProvider } from '@material-ui/styles';
import createAdminStore from './redux/createAdminStore';

import authProvider from './providers/authProvider';
import dataProvider from './providers/dataProvider';
import i18nProvider from './providers/i18nProvider';

import theme from './theme/customTheme';
import {
    AVATAR_PROPERTY,
    AVATAR_TRACEABILITY_NOTES,
    AVATARS,
    SOURCE_BOARDS,
    FILES,
    GROUP_METADATA_AVATAR_TYPES,
    MC_NOTES,
    MEDIAS,
    METADATA_AVATAR_TYPE_PROPERTIES,
    METADATA_AVATAR_TYPES,
    RUNNER_TEMPLATES,
    TRACEABILITY_NOTES_THRESHOLD_EXECUTIONS,
    USERS,
    YOURLS,
} from './providers/resources';
import RootPage from './pages/RootPage';

const history = createHashHistory();

const App = () => (
    <Provider
        store={createAdminStore({
            authProvider,
            dataProvider,
            history,
        })}
    >
        <AuthContext.Provider value={authProvider}>
            <DataProviderContext.Provider value={dataProvider}>
                {/*<TranslationProvider locale='fr' i18nProvider={i18nProvider}>*/}
                <TranslationProvider i18nProvider={i18nProvider}>
                    <ThemeProvider theme={theme}>
                        <Resource name={AVATARS} intent={'registration'} />
                        <Resource name={GROUP_METADATA_AVATAR_TYPES} intent={'registration'} />
                        <Resource name={MC_NOTES} intent={'registration'} />
                        <Resource name={METADATA_AVATAR_TYPES} intent={'registration'} />
                        <Resource name={TRACEABILITY_NOTES_THRESHOLD_EXECUTIONS} intent={'registration'} />
                        <Resource name={METADATA_AVATAR_TYPE_PROPERTIES} intent={'registration'} />
                        <Resource name={YOURLS} intent={'registration'} />
                        <Resource name={AVATAR_PROPERTY} intent={'registration'} />
                        <Resource name={AVATAR_TRACEABILITY_NOTES} intent={'registration'} />
                        <Resource name={USERS} intent={'registration'} />
                        <Resource name={MEDIAS} intent={'registration'} />
                        <Resource name={FILES} intent={'registration'} />
                        <Resource name={RUNNER_TEMPLATES} intent={'registration'} />
                        <Resource name={SOURCE_BOARDS} intent={'registration'} />
                        <ConnectedRouter history={history}>
                            <RootPage />
                        </ConnectedRouter>
                        <Notification />
                    </ThemeProvider>
                </TranslationProvider>
            </DataProviderContext.Provider>
        </AuthContext.Provider>
    </Provider>
);

export default withContext(
    {
        authProvider: PropTypes.object,
        dataProvider: PropTypes.object,
    },
    () => ({ authProvider, dataProvider })
)(App);
