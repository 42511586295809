import React, {useEffect, useState} from "react";
import {Identifier, crudGetList, ReduxState} from "react-admin";
import {METADATA_AVATAR_TYPE_PROPERTIES} from "../../../providers/resources";
import {MetadataAvatarTypeProperty} from "../../../utils/types";
import {useDispatch, useSelector} from "react-redux";
import {extractIdFromURI} from "../../../utils/resources/ids";
import {registerMetadataAvatarTypePropertyRequestExecution} from "../../../redux/actions/sync";
import {CustomReducerState} from "../../../redux/reducers";

interface Props {
    name: string;
    metadataAvatarType: Identifier;
}

const MetadataAvatarTypeProperties = (props: Props) => {
    const dispatch = useDispatch();
    const [index] = useState<number>(Math.random());
    const metadataAvatarTypeProperty = useSelector((state: ReduxState) => {
        const resource = state.admin.resources[METADATA_AVATAR_TYPE_PROPERTIES];

        return Object.values(resource.data).find(
            // @ts-ignore
            (item: MetadataAvatarTypeProperty) => {
                // @ts-ignore
                if (
                    item &&
                    item.name === props.name &&
                    // @ts-ignore
                    extractIdFromURI(item.metadataAvatarType) ===
                    props.metadataAvatarType
                ) {
                    return {...item};
                }
                return null
            }
        );
    });
    const executionRequestSyncIndex = useSelector(
        (state: ReduxState & CustomReducerState) => {
            const key = `${props.name}-${props.metadataAvatarType}`;
            if (
                key in
                state.rikutec.sync.metadataAvatarTypePropertyRequestExecution
            ) {
                return state.rikutec.sync
                    .metadataAvatarTypePropertyRequestExecution[key];
            }

            return -1;
        }
    );

    useEffect(() => {
        if (index) {
            dispatch(
                registerMetadataAvatarTypePropertyRequestExecution.fn(
                    props.name,
                    props.metadataAvatarType,
                    index
                )
            );
        }
        if (executionRequestSyncIndex === index) {
            dispatch(
                crudGetList(
                    METADATA_AVATAR_TYPE_PROPERTIES,
                    {page: 1, perPage: 1},
                    {field: "id", order: "asc"},
                    {
                        name: props.name,
                        metadataAvatarType: props.metadataAvatarType,
                    }
                )
            );
        }
    }, [dispatch, executionRequestSyncIndex, index, props.metadataAvatarType, props.name]);


    const value = metadataAvatarTypeProperty &&
    metadataAvatarTypeProperty.values &&
    Array.isArray(metadataAvatarTypeProperty.values) &&
    metadataAvatarTypeProperty.values.length === 1
        ? metadataAvatarTypeProperty.values[0]
        : "";
    const unit = metadataAvatarTypeProperty &&
    metadataAvatarTypeProperty.unit ? metadataAvatarTypeProperty.unit : "";

    return (
        <>
            {`${value} ${unit}`.trim()}
        </>
    );
};

export default MetadataAvatarTypeProperties;
