import { all, call, spawn } from 'redux-saga/effects';
import { DataProvider } from '../../providers/dataProvider';
import { AuthProvider } from '../../providers/authProvider';
import hydraDataProviderSaga from './hydraDataProvider';
import usersSaga from './user';

const sagaReducer = (dataProvider: DataProvider, authProvider: AuthProvider) => {
    return function* rootSaga() {
        const sagas = [hydraDataProviderSaga(dataProvider, authProvider), usersSaga(dataProvider, authProvider)];

        yield all(
            sagas.map((saga) =>
                spawn(function* () {
                    while (true) {
                        try {
                            yield call(saga);
                            break;
                        } catch (e) {
                            console.log(e);
                        }
                    }
                })
            )
        );
    };
};

export default sagaReducer;
