import { ADD_TO_TYPE_DATA, SELECT_TYPE, TypesActions, TypesState, UNSELECT_TYPE } from '../actions/types.actions';

const initialState: TypesState = {
    data: {},
    selected: {},
};
export const typesReducer = (state = initialState, action: TypesActions) => {
    switch (action.type) {
        case SELECT_TYPE:
            return { ...state, selected: action.payload };
        case UNSELECT_TYPE:
            return { ...state, selected: {} };
        case ADD_TO_TYPE_DATA:
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.payload.item.id]: {
                        item: action.payload.item,
                        medias: {
                            ...state.data[action.payload.item.id]?.medias,
                            [action.payload.tag]: {
                                fsm: action.payload.fsm,
                            },
                        },
                    },
                },
            };
        default:
            return state;
    }
};
