import {LanguagesActions, REMOVE_LANGUAGE, STORE_LANGUAGE} from "../actions/languages.actions";

export type StoredLanguages = {
    data: any[]
}
const initialState: StoredLanguages = {
    data: []
}

const languagesReducer = (state = initialState, action: LanguagesActions) => {
    switch (action.type) {
        case STORE_LANGUAGE:
            return {...state, data: [...state.data, action.payload]}
        case REMOVE_LANGUAGE:
            return {...state, data: state.data.filter(item => item.id !== action.payload.id)}
        default:
            return state;
    }
}

export default languagesReducer;