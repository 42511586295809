import React from 'react';
import { DateInput, useTranslate } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    gridPaddingTopInherit: {
        paddingTop: 'inherit !important',
    },
}));
const PeriodInputEmptying = (props: { source: string; label: string }) => {
    const translate = useTranslate();
    const classes = useStyles();

    return (
        <Grid container spacing={2} alignItems={'center'}>
            <Grid item className={classes.gridPaddingTopInherit}>
                <DateInput
                    size={'medium'}
                    {...props}
                    label={translate('app.common.after')}
                    variant={'standard'}
                    // source={`${props.source}[createdAt_after]`}
                    // source={`${props.source}%5BcreatedAt_after%5D`}
                    source={`${props.source}__after`}
                />
            </Grid>

            <Grid item className={classes.gridPaddingTopInherit}>
                <DateInput
                    size={'medium'}
                    {...props}
                    variant={'standard'}
                    label={translate('app.common.before')}
                    // source={`${props.source}%5BcreatedAt_before%5D`}
                    source={`${props.source}__before`}
                />
            </Grid>
        </Grid>
    );
};

export default PeriodInputEmptying;
