export function getDataJson(
    urlToFetch: string,
    addAuthorization = false,
    headersAuthorization: undefined | any
) {
    return fetch(urlToFetch, {
        method: "GET",
        headers:
            addAuthorization && headersAuthorization
                ? headersAuthorization
                : { "content-type": "application/json" },
    }).then((response) => response.json());
}
