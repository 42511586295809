import { makeStyles, TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { useState } from 'react';
import {
    AutocompleteInput,
    NumberInput,
    ReferenceInput,
    required,
    SelectInput,
    useMutation,
    useNotify,
    useRefresh,
    useTranslate,
} from 'react-admin';

import { Form } from 'react-final-form';
import { METADATA_AVATAR_TYPES, TRACEABILITY_NOTES_THRESHOLD_EXECUTIONS } from '../../../../../providers/resources';
import { ThresholdsFormStyles } from '../../../../../theme/customTheme';
import { getNameWithoutPrefixFromJson } from '../../../../../utils/resources/MetadataAvatarType';
import { MAINTENANCE, SLUDGE_EMPTYING } from '../../../../../utils/rikutec';
import { DURATION_TYPE_DAY, DURATION_TYPE_MONTH } from '../../../../../utils/threshold/duractionType';
import {
    extractThresholdValues,
    InputManagementThreshold,
    thresholdColorField,
    thresholdDurationTypeField,
    thresholdMaxValueField,
    thresholdMinValueField,
    thresholdNameField,
    thresholdOperationTypeField,
} from '../../../../../utils/threshold/grouping';
import { getColorByLevel, ThresholdsColorReferenceByNameType } from '../../../../../utils/threshold/setPropertyByLevel';
import { TraceabilityNotesThresholdExecutions } from '../../../../../utils/types';
import {
    getCodeFromViolationMessage,
    getViolations,
    ViolationResolved,
} from '../../../../../utils/validations/parseValidations';
import { ButtonSubmit } from '../../../../forms/buttonSubmit';
import TypographyFormSubtitle from '../../../../forms/typographyFormSubtitle';
import TypographyFormTitle from '../../../../forms/typographyFormTitle';
import { thresholdsNameChoices } from '../util';

// @ts-ignore
const useStyles = makeStyles<any>(() => ({
    ...ThresholdsFormStyles,
}));

type ManagementThresholdsFormProps = {
    open: boolean;
    handleClose: () => void;
};
const LIMIT_ADDING = 3;

/* These are the validation rules */
const validationRules = (value: any, translate: any) => {
    const errors = {};
    const values = Object.entries(value);
    const minValues = values
        .filter((item) => item[0].includes('min'))
        .map((item) => [item[0] as string, item[1] as number]);
    const maxValues = values
        .filter((item) => item[0].includes('max'))
        .map((item) => [item[0] as string, item[1] as number]);
    minValues.forEach((item, index) => {
        if (index > 0) {
            const refVal = maxValues[index - 1]?.[1];
            Object.assign(errors, {
                [`min_${index}`]:
                    typeof refVal === 'number' && item[1] > refVal
                        ? undefined
                        : translate('app.ancs.error.minMustExceedPreviousMax'),
            });
        } else {
            if (typeof item[1] === 'number') {
                Object.assign(errors, {
                    [`min_${index}`]: item[1] >= 0 ? undefined : translate('app.ancs.error.greaterThanZero'),
                });
            } else {
                Object.assign(errors, {
                    [`min_${index}`]: translate('app.ancs.error.requiredField'),
                });
            }
        }
    });
    maxValues.forEach((item, index) => {
        Object.assign(errors, {
            [`max_${index}`]:
                typeof item[1] === 'number' && item[1] > minValues[index][1]
                    ? undefined
                    : translate('app.ancs.error.maxMustExceedPreviousMin'),
        });
    });
    return errors;
};

const ManagementThresholdsCreateForm = ({ open, handleClose }: ManagementThresholdsFormProps) => {
    const translate = useTranslate();
    const [_open, setOpen] = useState<boolean>(open);
    const [colorReferenceByName, setColorReferenceByName] = useState<ThresholdsColorReferenceByNameType>({});

    const refresh = useRefresh();
    const notify = useNotify();

    const [inputsManagementThresholds, setInputsManagementThresholds] = useState<InputManagementThreshold[]>([
        {
            name: '',
            max: -1,
            min: -1,
            durationType: DURATION_TYPE_DAY,
            color: '',
        },
        {
            name: '',
            max: -1,
            min: -1,
            durationType: DURATION_TYPE_DAY,
            color: '',
        },
    ]);

    const classes = useStyles();

    const _handleClose = () => {
        setOpen(false);
        handleClose();
    };

    const createNewInputManagementThresholds = (): InputManagementThreshold => {
        return {
            name: '',
            max: -1,
            min: -1,
            durationType: DURATION_TYPE_DAY,
            color: '',
        };
    };

    const addNewInputManagementThresholds = (newInput: InputManagementThreshold) => {
        const newInputsManagementThresholds = [...inputsManagementThresholds, newInput];
        setInputsManagementThresholds(newInputsManagementThresholds);
    };

    const removeInput = (position: number) => {
        setInputsManagementThresholds((prevState) => prevState.filter((_, index) => index !== position));
    };

    const [createTraceabilityNotesThresholdExecutions] = useMutation({
        type: 'create',
        resource: TRACEABILITY_NOTES_THRESHOLD_EXECUTIONS,
        payload: { data: {} },
    });

    const resolveInputsManagementThresholdsDataForSubmitting = (values: any) => {
        groupingInputValues(values);

        const traceabilityNotesThresholdExecutions: TraceabilityNotesThresholdExecutions = {
            businessProcessIds: ['UpdateThresholdColor'],
            businessLogicMetadata: [],
            metadataAvatarType: values.metadataAvatarType.id,
            appliedOn: 'note',
            appliedOnNoteAtTag: values[thresholdOperationTypeField],
            appliedOnAtProperty: 'createdAt',
            appliedOnAtPropertyCreatedAtComparingType: 'duration',
            appliedOnToLastItem: true,
            appliedOnAtPropertyCreatedAtMetadata: inputsManagementThresholds.map((inputsManagement, index) => {
                if (
                    inputsManagement &&
                    thresholdColorField in inputsManagement &&
                    !inputsManagement[thresholdColorField]
                ) {
                    // inputsManagement[thresholdColorField] = "#000000";
                    const colorReferenceByName: ThresholdsColorReferenceByNameType = getColorByLevel(
                        {
                            target: {
                                name: `text_input_${inputsManagement[thresholdNameField]}`,
                                value: inputsManagement[thresholdNameField],
                            },
                        },
                        index,
                        {}
                    );

                    inputsManagement[thresholdColorField] = colorReferenceByName[index].value;
                }

                return (
                    inputsManagement && {
                        ...inputsManagement,
                        id: index,
                        min: inputsManagement[thresholdMinValueField],
                        max: inputsManagement[thresholdMaxValueField],
                    }
                );
            }),
        };

        createTraceabilityNotesThresholdExecutions(
            { payload: { data: traceabilityNotesThresholdExecutions } },
            {
                onSuccess: () => {
                    _handleClose();
                    refresh();
                },
                onFailure: (...args: any[]) => {
                    const violations: ViolationResolved[] = getViolations(args[0]);
                    if (violations && violations.length > 0) {
                        const violation = violations[0];
                        // @ts-ignore
                        notify(
                            // @ts-ignore
                            `app.${getCodeFromViolationMessage(
                                // @ts-ignore
                                violation?.message
                            )}`,
                            'warning'
                        );
                    } else {
                        notify('app.traceabilityNotesThreshold.error.server', 'warning');
                    }
                },
            }
        );
    };

    const groupingInputValues = (values: { [key: string]: string | number }) => {
        const r = extractThresholdValues(values, [...inputsManagementThresholds]);

        setInputsManagementThresholds([...r]);
    };

    const onChangeNameValue = (
        _value: {
            target: { name: string; value: any };
        },
        index: number
    ) => {
        setColorReferenceByName(getColorByLevel(_value, index, colorReferenceByName));
    };

    return (
        <Dialog open={_open} onClose={_handleClose} aria-labelledby='form-dialog-title' fullWidth maxWidth={'md'}>
            <DialogContent>
                {/*// @ts-ignore*/}
                <div className={classes.gridItemTitle}>
                    <TypographyFormTitle
                        title={translate('app.traceabilityNotesThreshold.actions.managementThresholds')}
                        noWrap={true}
                        color={'initial'}
                    />
                </div>

                <Form
                    onSubmit={(values) => {
                        resolveInputsManagementThresholdsDataForSubmitting(values);
                    }}
                    validateOnBlur={false}
                    validate={(values) => validationRules(values, translate)}
                    render={({ handleSubmit, errors, values }) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid container direction={'column'}>
                                    <Grid item>
                                        <ReferenceInput
                                            validate={[required()]}
                                            variant={'standard'}
                                            label={translate('app.traceabilityNotesThreshold.objectType')}
                                            data-testid={`metadataAvatarType_referenceInput`}
                                            source='metadataAvatarType.id'
                                            reference={METADATA_AVATAR_TYPES}
                                            filterToQuery={(searchText: string) => ({
                                                name: searchText,
                                            })}
                                        >
                                            <AutocompleteInput
                                                translateChoice={false}
                                                fullWidth
                                                clearAlwaysVisible
                                                optionText={getNameWithoutPrefixFromJson}
                                                source='id'
                                                optionValue='@id'
                                            />
                                        </ReferenceInput>
                                    </Grid>

                                    <Grid item>
                                        <SelectInput
                                            variant={'standard'}
                                            data-testid='select_operation_type_input'
                                            required
                                            fullWidth
                                            source={thresholdOperationTypeField}
                                            optionText='name'
                                            optionValue='id'
                                            label={translate('app.traceabilityNotesThreshold.operationType')}
                                            defaultValue={SLUDGE_EMPTYING}
                                            choices={[
                                                {
                                                    id: MAINTENANCE,
                                                    name: translate('app.ancs.maintenanceThreshold'),
                                                },
                                                {
                                                    id: SLUDGE_EMPTYING,
                                                    name: translate('app.ancs.sludgeEmptyingThreshold'),
                                                },
                                            ]}
                                        />
                                    </Grid>

                                    <Grid item>
                                        <TypographyFormSubtitle
                                            title={translate('app.traceabilityNotesThreshold.actions.defineThresholds')}
                                            noWrap={false}
                                            color={'initial'}
                                        />
                                    </Grid>

                                    {inputsManagementThresholds.map((inputManagement, index) => (
                                        <Grid item key={`grid_container_form_${index}`}>
                                            <Grid
                                                container
                                                spacing={2}
                                                alignItems='center'
                                                // @ts-ignore
                                                className={classes.itemsGroup}
                                            >
                                                <Grid item xs>
                                                    <SelectInput
                                                        validate={[required()]}
                                                        variant={'standard'}
                                                        data-testid='select_name_input'
                                                        fullWidth
                                                        source={`${thresholdNameField}_${index}`}
                                                        optionText='name'
                                                        optionValue='id'
                                                        label={translate('app.ancs.thresholdName')}
                                                        defaultValue={''}
                                                        choices={thresholdsNameChoices(translate)}
                                                        onChange={(e: any) => {
                                                            onChangeNameValue(e, index);
                                                        }}
                                                        helperText={false}
                                                    />
                                                </Grid>

                                                <Grid item>
                                                    <TextField
                                                        value={colorReferenceByName[index]?.value}
                                                        disabled={true}
                                                        variant={'outlined'}
                                                        type={'color'}
                                                        fullWidth
                                                        label={''}
                                                        data-testid={`color_input`}
                                                        helperText={false}
                                                        // @ts-ignore
                                                        className={classes.inputColor}
                                                    />
                                                </Grid>

                                                <Grid item xs>
                                                    <NumberInput
                                                        variant={'standard'}
                                                        validate={[required()]}
                                                        fullWidth
                                                        label={translate('app.ancs.thresholdMinTriggerValue')}
                                                        source={`${thresholdMinValueField}_${index}`}
                                                        data-testid={`${thresholdMinValueField}_${index}`}
                                                        helperText={false}
                                                    />
                                                </Grid>

                                                <Grid item xs>
                                                    <NumberInput
                                                        variant={'standard'}
                                                        validate={[required()]}
                                                        fullWidth
                                                        type={'number'}
                                                        label={translate('app.ancs.thresholdMaxTriggerValue')}
                                                        source={`${thresholdMaxValueField}_${index}`}
                                                        data-testid={`${thresholdMaxValueField}_${index}`}
                                                        helperText={false}
                                                    />
                                                </Grid>

                                                <Grid item xs>
                                                    <SelectInput
                                                        variant={'outlined'}
                                                        disabled
                                                        fullWidth
                                                        source={`${thresholdDurationTypeField}_${index}`}
                                                        optionValue='id'
                                                        optionText='name'
                                                        label={''}
                                                        defaultValue={DURATION_TYPE_DAY}
                                                        choices={[
                                                            {
                                                                id: DURATION_TYPE_DAY,
                                                                name: translate('app.ancs.days'),
                                                            },
                                                            {
                                                                id: DURATION_TYPE_MONTH,
                                                                name: translate('app.ancs.month'),
                                                            },
                                                        ]}
                                                        helperText={false}
                                                    />
                                                </Grid>

                                                <Grid item>
                                                    <IconButton
                                                        key={`delete_button${index}`}
                                                        aria-label='delete'
                                                        onClick={() => {
                                                            delete errors?.[`${thresholdNameField}_${index}`];
                                                            delete errors?.[`${thresholdMinValueField}_${index}`];
                                                            delete errors?.[`${thresholdMaxValueField}_${index}`];
                                                            delete errors?.[`${thresholdDurationTypeField}_${index}`];
                                                            if (index < inputsManagementThresholds.length - 1) {
                                                                values[`${thresholdNameField}_${index}`] = values[
                                                                    `${thresholdNameField}_${index + 1}`
                                                                ]
                                                                    ? values[`${thresholdNameField}_${index + 1}`]
                                                                    : undefined;
                                                                delete values[`${thresholdNameField}_${index + 1}`];
                                                                values[`${thresholdMinValueField}_${index}`] = values[
                                                                    `${thresholdMinValueField}_${index + 1}`
                                                                ]
                                                                    ? values[`${thresholdMinValueField}_${index + 1}`]
                                                                    : undefined;
                                                                delete values[`${thresholdMinValueField}_${index + 1}`];
                                                                values[`${thresholdMaxValueField}_${index}`] = values[
                                                                    `${thresholdMaxValueField}_${index + 1}`
                                                                ]
                                                                    ? values[`${thresholdMaxValueField}_${index + 1}`]
                                                                    : undefined;
                                                                delete values[`${thresholdMaxValueField}_${index + 1}`];
                                                                values[
                                                                    `${thresholdDurationTypeField}_${index}`
                                                                ] = values[`${thresholdDurationTypeField}_${index + 1}`]
                                                                    ? values[
                                                                          `${thresholdDurationTypeField}_${index + 1}`
                                                                      ]
                                                                    : undefined;
                                                                delete values[
                                                                    `${thresholdDurationTypeField}_${index + 1}`
                                                                ];
                                                            } else {
                                                                delete values[`${thresholdNameField}_${index}`];
                                                                delete values[`${thresholdMinValueField}_${index}`];
                                                                delete values[`${thresholdMaxValueField}_${index}`];
                                                                delete values[`${thresholdDurationTypeField}_${index}`];
                                                            }
                                                            setColorReferenceByName((prevState) => {
                                                                const currentState = { ...prevState };
                                                                if (
                                                                    index < Object.entries(currentState).length - 1 &&
                                                                    currentState[index + 1]
                                                                ) {
                                                                    for (let i in currentState) {
                                                                        while (currentState[Number(i) + 1]) {
                                                                            currentState[i] =
                                                                                currentState[Number(i) + 1];
                                                                            delete currentState[Number(1) + 1];
                                                                            i = String(Number(i) + 1);
                                                                        }
                                                                    }
                                                                } else {
                                                                    delete currentState[index];
                                                                }
                                                                return currentState;
                                                            });
                                                            removeInput(index);
                                                        }}
                                                        disabled={inputsManagementThresholds.length === 1}
                                                    >
                                                        <DeleteIcon
                                                            fontSize='medium'
                                                            classes={{
                                                                root:
                                                                    inputsManagementThresholds.length === 1
                                                                        ? classes.deleteIconDisabled
                                                                        : classes.deleteIcon,
                                                            }}
                                                        />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ))}

                                    <Grid item>
                                        <Button
                                            size={'medium'}
                                            classes={{
                                                // @ts-ignore
                                                root: classes.addButtonRootText,
                                            }}
                                            onClick={() =>
                                                addNewInputManagementThresholds(createNewInputManagementThresholds())
                                            }
                                            color='primary'
                                            // @ts-ignore
                                            endIcon={<AddIcon classes={{ root: classes.addIcon }} />}
                                            variant={'outlined'}
                                            disabled={inputsManagementThresholds.length === LIMIT_ADDING}
                                        >
                                            {translate('app.ancs.addThreshold')}
                                        </Button>
                                    </Grid>

                                    <Grid item>
                                        {/*// @ts-ignore*/}
                                        <div
                                            style={{
                                                marginTop: '20px',
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                                marginBottom: '20px',
                                            }}
                                        >
                                            <Button
                                                color={'primary'}
                                                size={'medium'}
                                                variant='text'
                                                classes={{
                                                    // @ts-ignore
                                                    root: classes.addButtonRoot,
                                                    // @ts-ignore
                                                    text: classes.addButtonRootText,
                                                }}
                                                onClick={_handleClose}
                                                style={{ marginRight: 12 }}
                                            >
                                                {translate('app.button.cancel')}
                                            </Button>
                                            <ButtonSubmit
                                                text={translate('app.button.confirm')}
                                                disabled={Object.entries(errors!!).some((item) => item !== undefined)}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                            </form>
                        );
                    }}
                />
            </DialogContent>
        </Dialog>
    );
};

export default ManagementThresholdsCreateForm;
