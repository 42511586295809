import { withStyles } from '@material-ui/core/styles';
import { translate } from 'react-admin';
import compose from 'recompose/compose';
import DialogTemplateButtons from './DialogTemplateButtons';

const styles = (theme) => ({
    dialogActions: {},
    button: {
        marginLeft: 10,
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -7,
    },
    wrapper: {
        position: 'relative',
        margin: 0,
    },
});

const enhance = compose(withStyles(styles), translate);

export default enhance(DialogTemplateButtons);
