import { DialogContentText, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Delete, FiberNew, Settings } from '@material-ui/icons';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { cloneElement } from 'react';

const DialogTemplate = ({
    open,
    onClose,
    classes,
    icon,
    title,
    children,
    helpText,
    maxWidth,
    withCreateIcon,
    withEditIcon,
    withDeleteIcon,
    titleWithoutBottomPadding,
    step,
    totalSteps,
    dataTestSC,
    buttons,
    overflowHidden,
    contentStyle,
    scroll
}) => {
    const editIcon = <Settings data-test-sc='dialogTemplateEditIcon' />;
    const createIcon = <FiberNew data-test-sc='dialogTemplateCreateIcon' />;
    const deleteIcon = <Delete data-test-sc='dialogTemplateDeleteIcon' />;

    const iconToRender = icon
        ? icon
        : withEditIcon
        ? editIcon
        : withCreateIcon
        ? createIcon
        : withDeleteIcon
        ? deleteIcon
        : null;

    return (
        <Dialog
            open={open}
            onClose={onClose}
            classes={{
                paper: classes.paper,
            }}
            disableBackdropClick={true}
            scroll={scroll}
            maxWidth={maxWidth}
            fullWidth={true}
            data-test-sc={dataTestSC ? dataTestSC : 'dialogTemplate'}
        >
            {title && (
                <DialogTitle
                    disableTypography
                    noWrap
                    className={classnames({
                        [classes.dialogTitle]: !titleWithoutBottomPadding,
                        [classes.dialogTitleWithoutBottomPadding]: titleWithoutBottomPadding,
                    })}
                >
                    <div
                        className={classnames(classes.container, {
                            [classes.containerWithIcon]: iconToRender,
                            [classes.containerWithoutIcon]: !iconToRender,
                        })}
                    >
                        {iconToRender &&
                            cloneElement(iconToRender, {
                                ...iconToRender.props,
                                ...(iconToRender.props.hasOwnProperty('data-test-sc')
                                    ? {}
                                    : { 'data-test-sc': 'dialogTemplateIcon' }),
                                color: 'primary',
                                className: classes.icon,
                            })}
                        <Typography
                            color={'primary'}
                            className={classes.dialogTitleText}
                            data-test-sc='dialogTemplateTitle'
                        >
                            {`${title}${
                                step !== undefined && totalSteps !== undefined ? ` ${step + 1}/${totalSteps}` : ''
                            }`}
                        </Typography>
                    </div>
                </DialogTitle>
            )}
            <DialogContent
                classes={{ root: classes.dialogContent }}
                className={{
                    [classes.overflowHidden]: overflowHidden,
                }}
                {...(contentStyle ? { style: contentStyle } : {})}
            >
                {helpText && (
                    <DialogContentText className={classes.dialogText} data-test-sc='dialogTemplateHelpText'>
                        {helpText}
                    </DialogContentText>
                )}
                {children && children}
            </DialogContent>
            {buttons && cloneElement(buttons, { ...buttons.props, withRightMargin: true })}
        </Dialog>
    );
};

DialogTemplate.propTypes = {
    classes: PropTypes.any.isRequired,
    buttons: PropTypes.any,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    icon: PropTypes.element,
    children: PropTypes.element,
    helpText: PropTypes.string,
    maxWidth: PropTypes.string,
    scroll: PropTypes.string,
    withEditIcon: PropTypes.bool,
    withCreateIcon: PropTypes.bool,
    withDeleteIcon: PropTypes.bool,
    overflowHidden: PropTypes.bool,
    titleWithoutBottomPadding: PropTypes.bool,
    step: PropTypes.number,
    totalSteps: PropTypes.number,
    dataTestSC: PropTypes.any,
    contentStyle: PropTypes.any,
};

DialogTemplate.defaultProps = {
    onClick: () => null,
    maxWidth: 'sm',
    titleWithoutBottomPadding: false,
    scroll: 'paper',
};

export default DialogTemplate;
