import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import * as _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { compose } from 'recompose';

const Tabs = ({ classes, tabs, children, dataTestSC }) => {
    const [tabSelected, setTabSelected] = useState(0);

    const makeDividers = (amount) => {
        const percent = 100 / amount;
        let style = '';
        _.range(amount).map(() => (style += `${percent}% `));
        return style;
    };

    return (
        <Grid container alignContent='flex-start' data-test-sc={dataTestSC ? dataTestSC : 'infoBlock'}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className={classes.container} style={{ gridTemplateColumns: makeDividers(tabs.length) }}>
                    {tabs.map(({ label, dataTestSC }, index) => (
                        <Typography
                            key={index}
                            color='primary'
                            align={'center'}
                            className={classnames(classes.tabTitle, {
                                [classes.tabUnselected]: tabSelected !== index,
                                [classes.pointer]: tabs.length > 1,
                            })}
                            onClick={() => setTabSelected(index)}
                            noWrap
                            data-test-sc={dataTestSC ? dataTestSC : `tab-${index}`}
                        >
                            {label}
                        </Typography>
                    ))}
                </div>
            </Grid>

            {typeof children === 'function' ? children({ tabSelected }) : children}
        </Grid>
    );
};

Tabs.propTypes = {
    classes: PropTypes.object.isRequired,
    tabs: PropTypes.array,
    children: PropTypes.any,
    dataTestSC: PropTypes.string,
};

Tabs.defaultProps = {
    tabs: [],
};

const styles = () => ({
    tabTitle: {
        fontSize: 18,
        textTransform: 'capitalize',
        fontWeight: 500,
        // paddingTop: 9,
        paddingBottom: 9,
    },
    tabUnselected: {
        opacity: 0.5,
    },
    container: {
        display: 'grid',
        alignItems: 'center',
    },
    pointer: {
        cursor: 'pointer',
    },
});

const enhance = compose(withStyles(styles));

export default enhance(Tabs);
