import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { IconButton, makeStyles, useMediaQuery } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import theme from '../../../theme/customTheme';
import { useTranslate } from 'react-admin';
import ListIcon from '@material-ui/icons/List';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(() => ({
    topToolbar: {
        marginLeft: '0',
        marginRight: 'auto',
        // paddingTop: "8px",
        paddingBottom: '8px',
        flexDirection: 'row-reverse',
    },
    buttonFontWeightBold: {
        fontWeight: 'bold',
    },
    fontWeightBold: {
        fontWeight: 'bold',
    },
    checkboxRoot: {
        fontSize: '0.875rem',
        paddingBottom: 0,
        paddingTop: 0,
    },
}));

interface Props {
    toggleColumn: (column: string, enabled: boolean) => void;
    enabledColumns: {
        [key: string]: boolean;
    };
    classesProps: any;
}

const DynamicColumns = (props: Props) => {
    const translate = useTranslate();
    const classes = useStyles();
    const smallSize = useMediaQuery(theme.breakpoints.down('md'));

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onMenuClick = (column: string) => () => {
        const isEnabled = column in props.enabledColumns ? props.enabledColumns[column] : null;
        if (isEnabled === null) {
            console.error(`Column ${column} is not registered as a List's column`);
        }
        props.toggleColumn(column, !isEnabled);
        handleClose();
    };

    return (
        <>
            <Button
                color='primary'
                style={{ display: smallSize ? 'none' : 'flex', fontSize: '13px' }}
                className={props.classesProps.buttonMenu}
                aria-controls='simple-menu'
                onClick={handleClick}
                aria-haspopup='true'
            >
                <ListIcon />
                {translate('app.ancs.actions.personalize')}
            </Button>
            <IconButton
                style={{ display: smallSize ? 'block' : 'none' }}
                color='primary'
                aria-controls='simple-menu'
                className={props.classesProps.buttonMenu}
                aria-haspopup='true'
                onClick={handleClick}
                component='span'
            >
                <ListIcon />
            </IconButton>
            <Menu id='simple-menu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem onClick={onMenuClick('processingCapacity')}>
                    <Checkbox
                        disabled
                        checked={props.enabledColumns['processingCapacity']}
                        inputProps={{
                            'aria-label': 'disabled checked checkbox',
                        }}
                        classes={{ root: classes.checkboxRoot }}
                    />
                    <Typography noWrap variant={'button'} color={'primary'} className={classes.fontWeightBold}>
                        {' '}
                        {translate('app.ancs.processingCapacity')}
                    </Typography>
                </MenuItem>
                <MenuItem onClick={onMenuClick('sludgeStorageVolume')}>
                    <Checkbox
                        disabled
                        checked={props.enabledColumns['sludgeStorageVolume']}
                        inputProps={{
                            'aria-label': 'disabled checked checkbox',
                        }}
                        classes={{ root: classes.checkboxRoot }}
                    />
                    <Typography noWrap variant={'button'} color={'primary'} className={classes.fontWeightBold}>
                        {' '}
                        {translate('app.ancs.sludgeStorageVolume')}
                    </Typography>
                </MenuItem>
                <MenuItem onClick={onMenuClick('totalUsefulVolume')}>
                    <Checkbox
                        disabled
                        checked={props.enabledColumns['totalUsefulVolume']}
                        inputProps={{
                            'aria-label': 'disabled checked checkbox',
                        }}
                        classes={{ root: classes.checkboxRoot }}
                    />
                    <Typography noWrap variant={'button'} color={'primary'} className={classes.fontWeightBold}>
                        {' '}
                        {translate('app.ancs.totalUsefulVolume')}
                    </Typography>
                </MenuItem>
                <MenuItem onClick={onMenuClick('model')}>
                    <Checkbox
                        disabled
                        checked={props.enabledColumns['model']}
                        inputProps={{
                            'aria-label': 'disabled checked checkbox',
                        }}
                        classes={{ root: classes.checkboxRoot }}
                    />
                    <Typography
                        noWrap
                        variant={'button'}
                        color={'primary'}
                        className={classes.fontWeightBold}
                        align={'center'}
                    >
                        {' '}
                        {translate('app.ancs.model')}
                    </Typography>
                </MenuItem>
                <MenuItem onClick={onMenuClick('currentState')}>
                    <Checkbox
                        disabled
                        checked={props.enabledColumns['currentState']}
                        inputProps={{
                            'aria-label': 'disabled checked checkbox',
                        }}
                        classes={{ root: classes.checkboxRoot }}
                    />
                    <Typography noWrap variant={'button'} color={'primary'} className={classes.fontWeightBold}>
                        {' '}
                        {translate('app.ancs.nodeCurrentState')}
                    </Typography>
                </MenuItem>
            </Menu>
        </>
    );
};

export default DynamicColumns;
