import {Dialog, DialogTitle, makeStyles, Typography} from "@material-ui/core";
import {useTranslate} from "react-admin";
import Form from "./form";
import FiberNew from "@material-ui/icons/FiberNew";
import SettingsIcon from "@material-ui/icons/Settings";

const useStyle = makeStyles(() => ({
    dialogTitle: {
        padding: '17px 20px 0 20px',
        paddingBottom: 10,
    },
    container: {
        display: 'grid',
        gridTemplateColumns: '25px auto',
        alignItems: 'center',
        columnGap: '7px',
    },
    dialogTitleText: {
        textTransform: 'uppercase',
        fontWeight: 'bold',
        fontSize: 17,
    }
}))

const DlgForm = (props: { isOpen: boolean, cancel: () => void, action: 'CREATE' | 'EDIT' }) => {
    const t = useTranslate()
    const classes = useStyle()
    return (
        <Dialog open={props.isOpen} onClose={props.cancel} fullWidth maxWidth="sm">
            <DialogTitle disableTypography className={`${classes.dialogTitle} ${classes.container}`}>
                {props.action === 'CREATE' ? <FiberNew color="primary"/> : <SettingsIcon color="primary"/>}
                <Typography color="primary" className={classes.dialogTitleText}>
                    {t(`app.types.${props.action === 'CREATE' ? 'create' : 'edit'}`)}
                </Typography>
            </DialogTitle>
            <Form toggleDlg={props.cancel} action={props.action}/>
        </Dialog>
    )
}

export default DlgForm;
