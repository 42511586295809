import React, { useEffect, useState } from 'react';
import { required, TextInput, useTranslate } from 'react-admin';
import { CircularProgress } from '@material-ui/core';
import useStyles from './styles';
import { Form } from 'react-final-form';
import Button from '@material-ui/core/Button';
import { useSelector } from 'react-redux';
import { CustomReducerState } from '../../../../../../../redux/reducers';
import { getNameWithoutPrefix } from '../../../../../../../utils/resources/MetadataAvatarType';

interface ProductTypeFormControlsProps {
    submitFn: (data: any, file?: File, clearFile?: (par: any) => void) => any;
    toggle: () => void;
    loading: boolean;
    oldMedia: any;
    action: 'CREATE' | 'EDIT' | 'NONE';
}

const ProductTypeFormControls = (props: ProductTypeFormControlsProps) => {
    const t = useTranslate();
    const classes = useStyles();
    const {
        rikutec: {
            groupsMetadataAvatarType: { selected: selectedGMDAT },
        },
    } = useSelector((state: CustomReducerState) => state);
    const [dlgState, setDlgState] = useState<{
        isOpen: boolean;
        fileName: string;
        selectedFile: File | undefined;
        oldMedia: any;
    }>({
        isOpen: false,
        fileName: '',
        selectedFile: undefined,
        oldMedia: undefined,
    });
    useEffect(
        () =>
            props.oldMedia?.mediaName &&
            setDlgState((prevState) => ({
                ...prevState,
                fileName: props.oldMedia.mediaName,
            })),
        [props.oldMedia]
    );
    return (
        <Form
            initialValues={{
                name: (selectedGMDAT && props.action === 'EDIT' && getNameWithoutPrefix(selectedGMDAT.name)) || '',
            }}
            onSubmit={(values) => props.submitFn(values, dlgState.selectedFile, setDlgState)}
            render={({ handleSubmit, hasValidationErrors }) => (
                <form onSubmit={handleSubmit} data-testid='ProductTypeFormControls'>
                    <TextInput
                        className={classes.formControl}
                        source='name'
                        variant='standard'
                        type='text'
                        fullWidth
                        label={t('app.productType.name')}
                        validate={[required()]}
                        helperText={false}
                        disabled={props.loading}
                        data-testid='form-input'
                    />
                    {/*<Typography className={classes.helperMessage}>{t('app.messages.canSendApprovalLater')}</Typography>*/}
                    {/*<div className={classes.fileWrapper}>*/}
                    {/*    <Input*/}
                    {/*        className={classes.fileInput}*/}
                    {/*        type='text'*/}
                    {/*        placeholder={t('app.productType.approvalFile')}*/}
                    {/*        value={dlgState.fileName}*/}
                    {/*        disabled={*/}
                    {/*            props.loading ||*/}
                    {/*            permissions?.every((item: string) => item === 'ROLE_FM_CLOUD_READ') ||*/}
                    {/*            permissions?.every((item: string) => item === 'ROLE_FM_CLOUD_WRITE')*/}
                    {/*        }*/}
                    {/*        onClick={() => fileInput.current?.click()}*/}
                    {/*        data-testid='form-input'*/}
                    {/*    />*/}
                    {/*    <Typography>*/}
                    {/*        {permissions?.every((item: string) => item === 'ROLE_FM_CLOUD_READ') ||*/}
                    {/*        permissions?.every((item: string) => item === 'ROLE_FM_CLOUD_WRITE')*/}
                    {/*            ? 'Not allowed'*/}
                    {/*            : ''}*/}
                    {/*    </Typography>*/}
                    {/*    <input*/}
                    {/*        type='file'*/}
                    {/*        hidden*/}
                    {/*        ref={fileInput}*/}
                    {/*        accept={acceptedMT}*/}
                    {/*        onChange={({ target: { files } }) => onFileChange(files)}*/}
                    {/*    />*/}
                    {/*</div>*/}
                    <div
                        style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-end', marginBottom: '20px' }}
                    >
                        <Button
                            variant='text'
                            color='primary'
                            onClick={() => {
                                setDlgState({
                                    oldMedia: undefined,
                                    selectedFile: undefined,
                                    fileName: '',
                                    isOpen: false,
                                });
                                props.toggle();
                            }}
                            disabled={props.loading}
                            className={classes.buttons}
                        >
                            {t('app.button.cancel')}
                        </Button>
                        <Button
                            variant='contained'
                            color='primary'
                            type='submit'
                            disabled={props.loading || hasValidationErrors}
                            className={classes.buttons}
                            data-testid='product-type-submit-button'
                        >
                            {props.loading && <CircularProgress className={classes.circularProgress} color='primary' />}
                            {t('app.button.confirm')}
                        </Button>
                    </div>
                </form>
            )}
        />
    );
};

export default ProductTypeFormControls;
