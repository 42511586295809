export const STORE_LANGUAGE = 'add_language_to_store';
export const REMOVE_LANGUAGE = 'remove_language_from_store';

export type LanguagesActions = {
    type: typeof STORE_LANGUAGE | typeof REMOVE_LANGUAGE;
    payload: any;
}

export const storeLanguage = (data: any): LanguagesActions => ({
    type: STORE_LANGUAGE,
    payload: data
})

export const removeLanguage = (data: any): LanguagesActions => ({
    type: REMOVE_LANGUAGE,
    payload: data
})