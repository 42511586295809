import { resolvedApiSchemaCreator } from '../actions/hydraDataProvider';

export interface HydraDataProviderState {
    apiSchema: any;
}

const reducer = (state: HydraDataProviderState = { apiSchema: null }, action: any) => {
    if (action.type === resolvedApiSchemaCreator.action) {
        return {
            apiSchema: {
                [action.payload.dataProviderKey]: action.payload.apiSchema,
                ...(state.apiSchema ? state.apiSchema : {}),
            },
        };
    }

    return state;
};

export default reducer;
