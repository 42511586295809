import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

ReactDOM.render(<App />, document.getElementById('root'));

// serviceWorkerRegistration.register();

// @ts-ignore
if (module.hot) {
    // @ts-ignore
    module.hot.accept();
}
