import React from 'react';
import { redirect_to_product_types } from '../../pages/paths';
import Authentication from '../auth/Authentication';
import { User } from '../../utils/types';
import { useSelector } from 'react-redux';
import { CustomReducerState } from '../../redux/reducers';
import ProductTypeList from './ProductTypeList/ProductTypeList';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';
import { ContentContainer } from '../../theme/customTheme';

const containerStyles = ContentContainer();
//@ts-ignore
const useStyles = makeStyles(() => ({ ...containerStyles }));
const ProductTypePage = () => {
    const classes = useStyles();
    const userConnected: User | null = useSelector((state: CustomReducerState) => state.rikutec.users.userConnected);
    return (
        <Authentication redirectTo={redirect_to_product_types}>
            {userConnected && (
                <>
                    <Grid
                        container
                        className={classes.root}
                        spacing={2}
                        data-cy-sc='product-type'
                        data-testid='ProductTypePage'
                    >
                        <Grid item xs={12} className={classes.gridItem}>
                            <ProductTypeList />
                        </Grid>
                    </Grid>
                </>
            )}
        </Authentication>
    );
};

export default ProductTypePage;
