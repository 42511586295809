// @ts-ignore
import { hydraDataProvider } from '@api-platform/admin';
import { parseHydraDocumentation as baseParseHydraDocumentation } from '@api-platform/api-doc-parser';
import ssBaseParseHydraDocumentation  from './customParseHydraDocumentation';
// @ts-ignore
import { DataProvider as RADataProvider, GetListParams } from 'react-admin';
import customFetchHydra from './fetchHydra';
import { uniformParams } from '../utils/providers/dataprovider';
import { uniformFilter } from '../utils/providers/ssDataProvider';
import { SS_RESOURCE_LIST } from './resources';

const entrypoint = `${process.env.REACT_APP_API_ENTRYPOINT}`;
const ssEntrypoint = `${process.env.REACT_APP_API_SS_ENTRYPOINT}`;
// const ssToken =
//     'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE3MDMwODE4NDIsImV4cCI6MTcwMzExNDI0MiwidXNlcm5hbWUiOiJqbGFjb3N0YV8zNjAiLCJ1c2VyX2lkIjo0LCJlbWFpbCI6ImpvcmdlLWx1aXNAMzYwc2MuaW8iLCJpc19hbm9ueW1vdXMiOmZhbHNlLCJjb21wYW55X25hbWUiOiIzNjBzYyIsImNvbXBhbnlfaWQiOiI1OGM2NzVmYzI3YWRhIiwiY29tcGFueV9pZF9mYWN0dXJhdGlvbiI6ImZpbmFvIiwiY29tcGFueV9jYXRlZ29yaWVzIjpbMTAyNCwxLDMyXSwicm9sZXMiOltdfQ.W5JdzWhJXlmbHEDzSHx3IiwbuawwtquONbEY0tfM1nI82BtKh8PUG-Wh13WQ9YG_btOHfPeJlLeK1xHzCKh5SNwPLREgC93e6zFZi3-noZUOt6M0gqgkR-XtM6CIZO5pDe1nC9NAj4WD0pX7UBF1uDfMvCyONwTnwnNrFg52jxfl1RvBQ-7sTwsy0HKOzv8mE4Sn4sUd_c8vd0-TBxeLIrnR61BHXE9qWNtdi9QdjYBp01uo0El08xUglI3frIDBr9gEL_l8xPfH4CNuVsXp_0fXOXB1FT-nnZY9YzoCpWCMGUFbhov6CLrirESXtFFph1y9ExdccbgVaOK71pX5v0KS04OYhmnoKSHVmCV0qXRrk78V2c5I91VsJnHaPTZc4TfDhZ7YnFSLSW6ILs5yCozFglDrxs3VfbYiMI9dbtWf_gHpMW5ZqrnSg8rBNjWszFZjKWKTEg8K6xgr7gPlup58cGxwZmrQ7jyDRCh09V9K4_2zJVFMWQxnV_1V9Gbd0QnSCQxd57U_pikT8eBXm2Dt4N-AolmZtC1v2TkGQ9BLkPzxeNVFccR5KM3ktrDiwvYcxFD_1h0DAeSulmkIRnMrnvyGcb9jVfdLjqQBrccykeM10jq_U7Y3x7BXvMHcLKeUPbhnceJLLF_HGZTwqMcksTlffxCrcrupmqtbffk';
const getToken = () => {
    return localStorage.getItem(
        // @ts-ignore
        process.env.REACT_APP_LOCALSTORAGE_TOKEN_VARIABLE
    );
};

// @ts-ignore
const fetchHydra = (url: string, options: { [key: string]: any } = {}) => {
    return (
        customFetchHydra(url, {
            ...options,
            headers: {
                // @ts-ignore
                Authorization: `Bearer ${getToken()}`,
                // 'X-Custom-Auth': `Bearer fetchHydra`
            },
        })
            // @ts-ignore
            .catch((e: any) => {
                return Promise.reject(e);
            })
    );
};

const ssFetchHydra = (url: string, options: { [key: string]: any } = {}) => {
    return (
        customFetchHydra(url, {
            ...options,
            headers: {
                // @ts-ignore
                Authorization: `Bearer ${getToken()}`,
                // 'X-Custom-Auth': `Bearer fetchHydra`
            },
        })
            // @ts-ignore
            .catch((e: any) => {
                return Promise.reject(e);
            })
    );
};

const parseHydraDocumentation = (url: string, options: RequestInit = {}) => {
    return baseParseHydraDocumentation(url, {
        ...options,
        headers: () => ({
            // @ts-ignore
            Authorization: `Bearer ${getToken()}`,
            // 'X-Custom-Auth': `Bearer parseHydraDocumentation`
        }),
    });
};

const ssParseHydraDocumentation = (url: string, options: RequestInit = {}) => {
    return ssBaseParseHydraDocumentation(url, {
        ...options,
        headers: () => ({
            // @ts-ignore
            // Authorization: `Bearer ${ssToken}`,
            Authorization: `Bearer ${getToken()}`,
            // 'X-Custom-Auth': `Bearer parseHydraDocumentation`
        }),
    });
};

const dataProvider = hydraDataProvider(
    entrypoint,
    fetchHydra,
    parseHydraDocumentation,
    true // useEmbedded parameter
);

const ssDataProvider = hydraDataProvider(
    ssEntrypoint,
    ssFetchHydra,
    ssParseHydraDocumentation,
    true // useEmbedded parameter
);

export type DataProvider = RADataProvider & {
    introspect: (_resource: string) => Promise<{ data: any }[]>;
};

const PatchGetListDataProvider: DataProvider = {
    ...dataProvider,
    // ...ssDataProvider,
    getList: (resource: string, params: GetListParams) => {
        const customFilters = uniformParams(params.filter ? params.filter : {});

        if (SS_RESOURCE_LIST.includes(resource)) {
            const filters = uniformFilter(params.filter);
            return ssDataProvider.getList(resource, {
                ...params,
                filter: {
                    ...customFilters,
                    ...filters,
                },
            });
        }

        return dataProvider.getList(resource, {
            ...params,
            filter: {
                ...customFilters,
            },
        });
    },
    introspect: (_resource: string) => {
        return Promise.all([ssDataProvider.introspect(_resource), dataProvider.introspect(_resource)]);
    },
};

export default PatchGetListDataProvider;
