import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { APP_BUTTON_COLOR_PRIMARY, APP_COLOR_BACKGROUND } from '../../theme/customTheme';

type ButtonSubmitProps = {
    text: string;
    disabled?: boolean;
};

const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: APP_BUTTON_COLOR_PRIMARY,
        paddingLeft: 21,
        paddingRight: 21,
        paddingTop: 10,
        paddingBottom: 10,
        '&:hover': {
            backgroundColor: APP_BUTTON_COLOR_PRIMARY,
            paddingLeft: 21,
            paddingRight: 21,
            paddingTop: 10,
            paddingBottom: 10,
        },
        '&:disabled': {
            // color: APP_BUTTON_COLOR_PRIMARY,
            backgroundColor: APP_COLOR_BACKGROUND,
        },
    },
    text: {
        color: 'white',
        fontWeight: 'bold',
    },
}));

export const ButtonSubmit = (props: ButtonSubmitProps) => {
    const classes = useStyles();
    const isDisabled = props.disabled ? props.disabled : false;
    return (
        <Button
            classes={{ text: classes.text }}
            disabled={isDisabled}
            className={classes.root}
            type={'submit'}
            size={'medium'}
        >
            {props.text}
        </Button>
    );
};
