import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { APP_COLOR_PRIMARY } from '../../theme/customTheme';

const useStyles = makeStyles(() => ({
    root: {
        color: APP_COLOR_PRIMARY,
        fontWeight: 'bold',
    },
}));

type TypographyFormTitleProps = {
    title: string;
    noWrap?: boolean;
    color: 'initial' | 'inherit' | 'primary' | 'secondary' | 'textPrimary' | 'textSecondary' | 'error';
};

const TypographyFormTitle = ({ title, noWrap, color }: TypographyFormTitleProps) => {
    const classes = useStyles();

    return (
        <Typography classes={{ root: classes.root }} noWrap={noWrap ? noWrap : false} color={color} variant={'button'}>
            {title}
        </Typography>
    );
};

export default TypographyFormTitle;
