import PropTypes from 'prop-types';
import React, { Children, cloneElement, ReactElement, useEffect, useState } from 'react';
import { GetListResult, Record, useDataProvider, useRecordContext } from 'react-admin';
import { AVATAR_TRACEABILITY_NOTES } from '../../providers/resources';
import { extractIdFromURI } from '../../utils/resources/ids';
import { getNodes } from '../../utils/traceability';
import { Avatar, ID_FOR_HUMAN_READABLE_IN_SERVICE, TraceabilityHistory } from '../../utils/types';

interface InstallationDateAvatarPropertyReferenceFieldProps {
    label: string;
    children?: ReactElement;
    headerClassName?: string;
}

const InstallationDateAvatarPropertyReferenceField = (props: InstallationDateAvatarPropertyReferenceFieldProps) => {
    if (React.Children.count(props.children) !== 1) {
        throw new Error('<InstallationDateAvatarPropertyReferenceField> only accepts a single child');
    }

    const [historyNodeInService, setHistoryNodeInService] = useState<TraceabilityHistory | undefined>(undefined);
    const [loaded, setLoaded] = useState<boolean>(false);
    const record: Avatar = useRecordContext(props);
    const dataProvider = useDataProvider();

    useEffect(() => {
        const nodes = record.currentReferencing?.standardReferencing?.runner?.firstNode
            ? getNodes(record.currentReferencing?.standardReferencing?.runner?.firstNode)
            : [];
        console.log('nodes', nodes);

        if (record) {
            dataProvider
                .getList(AVATAR_TRACEABILITY_NOTES, {
                    filter: {
                        'type-item': 'traceability',
                        avatarId: extractIdFromURI(record.id as string),
                    },
                    sort: {
                        field: 'createdAt',
                        order: 'DESC',
                    },
                    pagination: {
                        page: 1,
                        perPage: 15,
                    },
                })
                .then((result: GetListResult<Record>) => {
                    if (result && 'data' in result && result.data.length > 0) {
                        const inServiceNode: Record | undefined = result.data.find(
                            (historyItem) =>
                                (historyItem as TraceabilityHistory).configuration.idForNodeHumanReadable ===
                                ID_FOR_HUMAN_READABLE_IN_SERVICE
                        );
                        setHistoryNodeInService(inServiceNode as TraceabilityHistory);
                        setLoaded(true);
                    }
                })
                .catch((error: any) => {
                    console.error('Error getting ', AVATAR_TRACEABILITY_NOTES, 'filtered by ', record.id as string);
                    setLoaded(false);
                });
        }
        // eslint-disable-next-line
    }, [record]);

    return (
        <InstallationDateAvatarPropertyReferenceFieldView
            record={record}
            loaded={loaded}
            headerClassName={props.headerClassName}
            error={null}
            // @ts-ignore
            historyNodeInService={historyNodeInService}
        >
            {props.children}
        </InstallationDateAvatarPropertyReferenceFieldView>
    );
};

InstallationDateAvatarPropertyReferenceField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    children: PropTypes.element,
};

export default InstallationDateAvatarPropertyReferenceField;

export const InstallationDateAvatarPropertyReferenceFieldView = (props: any) => {
    return cloneElement(Children.only(props.children), {
        record: props.record,
        headerClassName: props.headerClassName,
        loaded: props.loaded,
        error: props.error,
        historyNodeInService: props.historyNodeInService,
    });
};

InstallationDateAvatarPropertyReferenceFieldView.propTypes = {
    children: PropTypes.element,
    headerClassName: PropTypes.string,
    record: PropTypes.object.isRequired,
    historyNodeInService: PropTypes.object.isRequired,
    loaded: PropTypes.bool,
    error: PropTypes.object,
};
