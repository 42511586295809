import React, { useEffect, useRef, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { ReduxState, useDelete, useNotify, useRefresh, useTranslate, useUpdate } from 'react-admin';
import { ReactComponent as UploadFileIcon } from '../../../../upload_file_black_24dp.svg';
import { CircularProgress, Input, makeStyles } from '@material-ui/core';
import { postUploadMedia } from '../../../../utils/medias';
import { extractIdFromURI } from '../../../../utils/resources/ids';
import { MEDIAS, METADATA_AVATAR_TYPES } from '../../../../providers/resources';
import { useSelector } from 'react-redux';
import { CustomReducerState } from '../../../../redux/reducers';
import { getNameWithoutPrefix } from '../../../../utils/resources/MetadataAvatarType';

interface AddMediaFormProps {
    isOpen: boolean;
    type: 'installing_guide';
    toggle: () => void;
}

const useStyles = makeStyles(() => ({
    dialogTitle: {
        padding: '17px 20px 0 20px',
        paddingBottom: 10,
    },
    container: {
        display: 'grid',
        gridTemplateColumns: '25px auto',
        alignItems: 'center',
        columnGap: '7px',
    },
    dialogTitleText: {
        textTransform: 'uppercase',
        fontWeight: 'bold',
        fontSize: 17,
    },
    fileWrapper: {
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'center',
    },
    fileInput: {
        width: '100%',
    },
    filePreview: {
        marginTop: '15px',
        width: '100%',
    },
    circularProgress: {
        width: '16px !important',
        height: '16px !important',
        marginRight: '7px',
    },
    buttonsContainer: {
        marginTop: '20px',
        textAlign: 'right',
    },
    buttons: {
        '&:first-child': {
            marginRight: '7px',
        },
    },
    helperMessage: {
        fontWeight: 'bold',
        marginTop: '15px',
        marginBottom: '5px',
    },
}));
const AddMediaForm = (props: AddMediaFormProps) => {
    const fileInput = useRef<HTMLInputElement>(null);
    const [dlgState, setDlgState] = useState<{
        mediaId: string;
        fileName: string | undefined;
        selectedFile: File | undefined;
    }>({
        mediaId: '',
        fileName: '',
        selectedFile: undefined,
    });
    const {
        rikutec: {
            users: { userConnected },
            types: { selected: selectedType },
        },
    } = useSelector((state: CustomReducerState) => state);
    const {
        admin: {
            resources: {
                metadata_avatar_types: { data: adminData },
            },
        },
    } = useSelector((state: ReduxState) => state);
    const t = useTranslate();
    const classes = useStyles();
    const refresh = useRefresh();
    const notify = useNotify();
    const [uploadingMedia, setUploadingMedia] = useState(false);
    const [searchingMedia, setSearchingMedia] = useState(false);
    const [updateType, { loading: updatingType }] = useUpdate();
    const [deleteMedia, { loading: deletingMedia }] = useDelete();
    const acceptedMT = 'application/pdf';
    const token = localStorage.getItem('rikutec_token')!!;
    const fileSystemMapperUrl = `${process.env.REACT_APP_FILE_SYSTEM_MAPPER_ENTRYPOINT}`
        .concat('/smartConnectView/file_system_mappers')
        .concat(`?lvl=5&tags.name=${props.type.toString()}&isFolder=false`)
        .concat('&parent.name=system&parent.parent.name=sc:folder-metadata_avatar_type:');
    const searchForMedia = () => {
        if (props.isOpen) {
            setSearchingMedia(true);
            fetch(fileSystemMapperUrl.concat(`${extractIdFromURI(selectedType['@id'])}`), {
                headers: { Authorization: `Bearer ${token}` },
            })
                .then((response) =>
                    response.json().then((media) => {
                        if ('hydra:member' in media && media['hydra:member'].length > 0) {
                            const fileMedia = adminData[selectedType['@id']].files?.find((item: string) =>
                                media['hydra:member'].some(
                                    (token: any) =>
                                        token.metadata['metadata.extra']['coreapi.mediaid'] === extractIdFromURI(item)
                                )
                            );
                            fileMedia &&
                                setDlgState((prevState) => ({
                                    ...prevState,
                                    mediaId: fileMedia ?? undefined,
                                    fileName: fileMedia
                                        ? getNameWithoutPrefix(
                                              media['hydra:member']
                                                  .find(
                                                      (item: any) =>
                                                          item.metadata['metadata.extra']['coreapi.mediaid'] ===
                                                          extractIdFromURI(fileMedia)
                                                  )
                                                  .metadata['metadata.cloud'].name.split('*_*')[1]
                                          )
                                        : undefined,
                                }));
                        }
                        setSearchingMedia(false);
                    })
                )
                .catch(() => {
                    notify(t('app.types.messages.error'), 'error');
                    setSearchingMedia(false);
                });
        }
    };
    const onFileChange = (fileList: FileList | null) => {
        fileList &&
            fileList.length > 0 &&
            setDlgState((prevState) => ({
                ...prevState,
                fileName: fileList[0].name,
                selectedFile: fileList[0],
            }));
    };
    const postMedia = () => {
        if (dlgState.selectedFile) {
            setUploadingMedia(true);
            const reader = new FileReader();
            reader.onload = async (e) => {
                notify(t('app.messages.loading'), 'info');
                const response = await postUploadMedia(
                    e.target?.result as ArrayBuffer,
                    dlgState.selectedFile!!.name,
                    'metadata_avatar_type',
                    extractIdFromURI(selectedType['@id'])!!,
                    token,
                    'FILE',
                    [props.type.toString()],
                    userConnected?.company['@id']
                )
                    .then((response) => ({ result: response, error: null }))
                    .catch((error) => ({ error, result: null }))
                    .finally(() => setUploadingMedia(false));
                if (response.result) {
                    const updatedType = { ...adminData[selectedType['@id']] };
                    if (updatedType.files && updatedType.files.length > 0) {
                        updatedType.files = adminData[selectedType['@id']].files.filter(
                            (item: string) => item !== dlgState.mediaId
                        );
                        updatedType.files.push(response.result['@id']);
                    } else {
                        Object.assign(updatedType, { files: [response.result['@id']] });
                    }
                    Object.assign(updatedType, { customFields: {}, identifiers: [] });
                    updateType(METADATA_AVATAR_TYPES, selectedType.id, updatedType, adminData[selectedType['@id']], {
                        onSuccess: () => {
                            if (dlgState.mediaId) {
                                deleteMedia(MEDIAS, dlgState.mediaId, undefined);
                            }
                            toggle();
                            notify(t('app.types.messages.success'), 'success');
                            refresh();
                        },
                        onFailure: () => notify(t('app.types.messages.error'), 'error'),
                    });
                } else {
                    notify(t('app.types.messages.error'), 'error');
                }
            };
            reader.readAsDataURL(dlgState.selectedFile);
        }
    };
    const toggle = () => {
        setDlgState({ mediaId: '', fileName: undefined, selectedFile: undefined });
        props.toggle();
    };

    useEffect(() => setDlgState({ selectedFile: undefined, fileName: '', mediaId: '' }), [selectedType]);
    useEffect(searchForMedia, [fileSystemMapperUrl, notify, selectedType, t, token, props.isOpen, adminData]);

    return (
        <Dialog open={props.isOpen} onClose={toggle} fullWidth maxWidth='sm' data-testid='AddMediaForm'>
            <DialogTitle disableTypography className={`${classes.dialogTitle} ${classes.container}`}>
                <UploadFileIcon color='primary' />
                <Typography color='primary' className={classes.dialogTitleText}>
                    {t(`app.types.addEdit${props.type.toString()}`)}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <div className={classes.fileWrapper}>
                    <Input
                        className={classes.fileInput}
                        type='text'
                        placeholder={t(`app.types.${props.type.toString()}`)}
                        value={dlgState.fileName}
                        disabled={uploadingMedia || updatingType || deletingMedia}
                        onClick={() => fileInput.current?.click()}
                    />
                    <input
                        type='file'
                        hidden
                        ref={fileInput}
                        accept={acceptedMT}
                        onChange={({ target: { files } }) => onFileChange(files)}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant='text' color='primary' onClick={toggle}>
                    {t('app.button.cancel')}
                </Button>
                <Button
                    variant='contained'
                    color='primary'
                    disabled={
                        !dlgState.selectedFile || searchingMedia || uploadingMedia || updatingType || deletingMedia
                    }
                    onClick={() => postMedia()}
                >
                    {(searchingMedia || uploadingMedia || updatingType || deletingMedia) && (
                        <CircularProgress className={classes.circularProgress} color='primary' />
                    )}
                    {t('app.button.confirm')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddMediaForm;
