import {
    Button,
    ClickAwayListener,
    Fade,
    ListItemIcon,
    ListItemText,
    MenuItem,
    MenuList,
    Paper,
    Popper,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PropTypes from 'prop-types';
import React, { cloneElement, Fragment, useState } from 'react';

const hasRoles = (roles = [], permissions = [], allOfRolesInPermissions = true) => {
    if (allOfRolesInPermissions)
        return roles.every(
            (role) => -1 !== permissions.findIndex((prole) => prole.toLowerCase() === role.toLowerCase())
        );
    return roles.reduce((found, role) => {
        return found ? found : permissions.includes(role);
    }, false);
};

const filterMenuByPermissions = (menuList, permissions) =>
    menuList.filter((item) => {
        if (item.hasOwnProperty('renderComponent')) return true;

        return hasRoles(item.roles, permissions, true);
    });

const MenuButtonActions = ({
    classes,
    disabled,
    onButtonClick,
    menuState,
    buttonLabel,
    overwriteButtonLabel,
    menuActions,
    buttonProps,
    translate,
    permissions,
    dataTestSCMenuButton,
}) => {
    const filteredMenuActions = filterMenuByPermissions(menuActions, permissions);
    const [anchorEl, setAnchorEl] = useState(null);

    return (
        <Fragment>
            <Button
                color={'primary'}
                disabled={disabled || filteredMenuActions.length === 0}
                onClick={(event) => {
                    const { currentTarget } = event;
                    onButtonClick();
                    setAnchorEl(currentTarget);
                }}
                className={classes.buttonMenu}
                {...(buttonProps ? buttonProps : {})}
                data-test-sc={dataTestSCMenuButton ? dataTestSCMenuButton : 'menuButtonActionsButton'}
            >
                <MoreVertIcon className={classes.icons} data-test-sc='menuButtonActionsIcon' />
                {overwriteButtonLabel ? buttonLabel : translate('app.main_buttons.actions')}
            </Button>
            <Popper
                open={menuState}
                anchorEl={anchorEl}
                transition
                placement={'bottom-start'}
                style={{ zIndex: 10 }}
                data-test-sc='menuButtonActionsPopper'
            >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper>
                            <ClickAwayListener onClickAway={onButtonClick}>
                                <MenuList
                                    className={classes.menuListRoot}
                                    data-test-sc='menuButtonActionsMenuList'
                                    data-testid='menuButtonActionsMenuList'
                                >
                                    {filteredMenuActions.map((item, index) =>
                                        !item.hasOwnProperty('renderComponent') ? (
                                            <MenuItem
                                                key={index}
                                                onClick={() => {
                                                    item.onClick();
                                                    onButtonClick();
                                                }}
                                                className={classes.menuRoot}
                                                disabled={
                                                    typeof item.disabled === 'function'
                                                        ? item.disabled(item.roles, permissions)
                                                        : item.disabled
                                                }
                                                data-test-sc={item.dataTestSC ? item.dataTestSC : 'menuItem'}
                                            >
                                                <ListItemIcon className={classes.menuListItemRoot}>
                                                    {cloneElement(item.icon, {
                                                        ...item.icon.props,
                                                        color: 'primary',
                                                        className: classes.menuIcon,
                                                    })}
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={item.text}
                                                    className={classes.menuTextRoot}
                                                    primaryTypographyProps={{
                                                        className: classes.menuText,
                                                        color: 'primary',
                                                    }}
                                                    data-test-sc={
                                                        item.dataTestSC ? `${item.dataTestSC}Text` : 'menuItemText'
                                                    }
                                                />
                                            </MenuItem>
                                        ) : (
                                            item.renderComponent
                                        )
                                    )}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Fade>
                )}
            </Popper>
        </Fragment>
    );
};

MenuButtonActions.propTypes = {
    classes: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    menuState: PropTypes.bool.isRequired,
    onButtonClick: PropTypes.func.isRequired,
    buttonLabel: PropTypes.string,
    overwriteButtonLabel: PropTypes.string,
    menuActions: PropTypes.array,
    buttonProps: PropTypes.object,
    translate: PropTypes.any,
    anchorEl: PropTypes.any,
    permissions: PropTypes.array,
    dataTestSC: PropTypes.string,
    dataTestSCMenuButton: PropTypes.string,
};

MenuButtonActions.defaultProps = {
    disabled: false,
    menuState: false,
    menuActions: [],
};

export default MenuButtonActions;
