import {
    ADD_TO_GROUP_METADATA_AVATAR_TYPE_DATA,
    GroupMetadataAvatarTypeActions,
    GroupMetadataAvatarTypeState,
    SELECT_GROUP_METADATA_AVATAR_TYPE,
    UNSELECT_GROUP_METADATA_AVATAR_TYPE,
} from '../actions/group.metadata.avatar.type.actions';

const initialState: GroupMetadataAvatarTypeState = {
    data: {},
    selected: {},
};

const groupMetadataAvatarTypeReducer = (
    state: GroupMetadataAvatarTypeState = initialState,
    action: GroupMetadataAvatarTypeActions
) => {
    switch (action.type) {
        case SELECT_GROUP_METADATA_AVATAR_TYPE:
            return { ...state, selected: action.payload };
        case UNSELECT_GROUP_METADATA_AVATAR_TYPE:
            return { ...state, selected: {} };
        case ADD_TO_GROUP_METADATA_AVATAR_TYPE_DATA:
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.payload.item.id]: {
                        item: action.payload.item,
                        medias: {
                            ...state.data[action.payload.item.id]?.medias,
                            [action.payload.tag]: {
                                fsm: action.payload.fsm,
                            },
                        },
                    },
                },
            };
        default:
            return state;
    }
};

export default groupMetadataAvatarTypeReducer;
