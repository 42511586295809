import {
    selectThresholds,
    SelectThresholdsAction,
} from "../actions/thresholds";
import {TraceabilityNotesThresholdExecutions} from "../../utils/types";

export interface ThresholdsState {
    selected: TraceabilityNotesThresholdExecutions | undefined;
}

const InitialState: ThresholdsState = {
    selected: undefined,
};

export const getThresholdsSelected = (
    state: any
): TraceabilityNotesThresholdExecutions | undefined => {
    if (
        state.rikutec.thresholds.selected &&
        Object.keys(state.rikutec.thresholds.selected).length > 0
    ) {
        // @ts-ignore
        return Object.values(state.rikutec.thresholds.selected)[0];
    }
    return undefined;
};

const reducer = (state: ThresholdsState = InitialState, action: any) => {
    switch (action.type) {
        case selectThresholds.action: {
            const iAction: SelectThresholdsAction = action;
            return {
                ...state,
                selected: iAction.payload ? {...iAction.payload} : null
            };
        }
        default:
            return state;
    }
};

export default reducer;
