import { fetchJsonLd, Parameter } from '@api-platform/api-doc-parser';
import { RequestInitExtended } from '@api-platform/api-doc-parser/lib/cjs/hydra/types';
import get from 'lodash.get';

//eslint-disable-next-line
export default (resourceUrl: string, options: RequestInitExtended = {}): Promise<{ parameters: Parameter[] }> => {
    return fetchJsonLd(resourceUrl, Object.assign({ itemsPerPage: 0 }, options)).then((d) => ({
        // @ts-ignore
        parameters: get(d, 'body.hydra:search.hydra:mapping') as Parameter[],
    }));
};
