import {AutocompleteInput, Filter, ReferenceInput, required, useTranslate} from "react-admin"
import {getNameWithoutPrefixFromJson} from "../../../utils/resources/MetadataAvatarType";
import * as React from "react";
import {METADATA_AVATAR_TYPES} from "../../../providers/resources";

const FiltersList = (props: any) => {
    const t = useTranslate()
    return (
        <Filter {...props}>
            <ReferenceInput
                validate={[required()]}
                variant={'standard'}
                label={t('app.traceabilityNotesThreshold.objectType')}
                data-testid={`metadataAvatarType_referenceInput`}
                source='id'
                reference={METADATA_AVATAR_TYPES}
                filterToQuery={(searchText: string) => ({
                    name: searchText,
                })}
            >
                <AutocompleteInput
                    translateChoice={false}
                    fullWidth
                    clearAlwaysVisible
                    optionText={getNameWithoutPrefixFromJson}
                    source='id'
                    optionValue='@id'
                />
            </ReferenceInput>
        </Filter>
    )
}

export default FiltersList