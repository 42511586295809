import { saveAncsLastNoteFound, SaveAncsLastNoteFoundAction, SELECT_ANC } from '../actions/ancs';
import { Avatar, MCNote } from '../../utils/types';

export interface AncsState {
    helps: {
        lastNotes: {
            // The key is the 'saveIdentification-avatarId' searched and the value the Note found
            [key: string]: {
                tags: string;
                notes: {
                    [key: string]: MCNote;
                };
            };
        };
    };
    selected: Avatar | object;
}

const InitialState: AncsState = {
    helps: {
        lastNotes: {},
    },
    selected: {},
};

const reducer = (state: AncsState = InitialState, action: any) => {
    switch (action.type) {
        case saveAncsLastNoteFound.action: {
            const iAction: SaveAncsLastNoteFoundAction = action;
            return {
                ...state,
                helps: {
                    ...state.helps,
                    lastNotes: {
                        ...state.helps.lastNotes,
                        [`${iAction.payload.saveIdentification}-${iAction.payload.avatarId}`]: {
                            tags: iAction.payload.tags,
                            notes: iAction.payload.notes,
                        },
                    },
                },
            };
        }
        case SELECT_ANC:
            return {
                ...state,
                selected: action.payload,
            };
        default:
            return { ...state };
    }
};

export default reducer;
