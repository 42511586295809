import React from "react";
import ThresholdsList from "../components/thresholds/list";
import Authentication from "../components/auth/Authentication";
import {redirect_to_thresholds} from "./paths";
import {User} from "../utils/types";
import {useSelector} from "react-redux";
import {CustomReducerState} from "../redux/reducers";
import Grid from "@material-ui/core/Grid";
import {ContentContainer} from "../theme/customTheme";
import {makeStyles} from "@material-ui/core";

const containerStyles = ContentContainer()
//@ts-ignore
const useStyles = makeStyles(() => ({...containerStyles}))
const ThresholdsPage = () => {
    const classes = useStyles()
    const userConnected: User | null = useSelector(
        (state: CustomReducerState) => state.rikutec.users.userConnected
    );

    return (
        <Authentication redirectTo={redirect_to_thresholds}>
            {userConnected && (
                <>
                    <Grid container className={classes.root} spacing={2} data-cy-sc="types">
                        <Grid item xs={12} className={classes.gridItem}>
                            <ThresholdsList/>
                        </Grid>
                    </Grid>
                </>
            )}
        </Authentication>
    );
};

export default ThresholdsPage;
