import React from 'react';
// import {AvatarAncList} from "../components/avatarAnc/list";
import { AvatarAncList } from '../components/avatarAnc/list';
import Authentication from '../components/auth/Authentication';
import { redirect_to_ancs_every_2_hours } from './paths';
import { User } from '../utils/types';
import { useSelector } from 'react-redux';
import { CustomReducerState } from '../redux/reducers';
import Grid from '@material-ui/core/Grid';
import { ContentContainer } from '../theme/customTheme';
import { makeStyles } from '@material-ui/core';

const containerStyles = ContentContainer();
//@ts-ignore
const useStyles = makeStyles(() => ({ ...containerStyles }));

const AvatarAncPage = () => {
    const classes = useStyles();
    const userConnected: User | null = useSelector((state: CustomReducerState) => state.rikutec.users.userConnected);

    return (
        <Authentication redirectTo={redirect_to_ancs_every_2_hours}>
            {userConnected && (
                <>
                    <Grid container className={classes.root} spacing={2} data-cy-sc='types'>
                        <Grid item xs={12} className={classes.gridItem}>
                            <AvatarAncList />
                        </Grid>
                    </Grid>
                </>
            )}
        </Authentication>
    );
};

export default AvatarAncPage;
