import { decodeToken as decodeTokenLib } from "jsontokens";

export interface TokenDataDecoded {
    company_categories: any;
    company_id: string;
    company_id_facturation: string;
    user_id: string;
    username: string;
    roles: any;
}

export const getTokenData = (token: string): TokenDataDecoded | null => {
    const tokenData = decodeTokenLib(token);
    //@ts-ignore
    return tokenData.payload;
};
