import {
    COLOR_LEVEL_1,
    COLOR_LEVEL_2,
    COLOR_LEVEL_3,
    LEVEL_1,
    LEVEL_2,
    LEVEL_3,
    THRESHOLD_LEVEL1_NAME,
    THRESHOLD_LEVEL2_NAME,
    THRESHOLD_LEVEL3_NAME,
} from '../rikutec';

export type ThresholdsColorReferenceByNameType = {
    [key: number]: {
        name: string;
        value: string;
    };
};

export const getColorByLevel = (
    _value: {
        target: { name: string; value: any };
    },
    index: number,
    currentColorReferenceByName: ThresholdsColorReferenceByNameType = {}
): ThresholdsColorReferenceByNameType => {
    let result: ThresholdsColorReferenceByNameType;

    switch (_value.target.value) {
        case THRESHOLD_LEVEL1_NAME:
            result = {
                ...currentColorReferenceByName,
                [index]: {
                    name: _value.target.name,
                    value: COLOR_LEVEL_1,
                },
            };
            break;
        case THRESHOLD_LEVEL2_NAME:
            result = {
                ...currentColorReferenceByName,
                [index]: {
                    name: _value.target.name,
                    value: COLOR_LEVEL_2,
                },
            };
            break;
        case THRESHOLD_LEVEL3_NAME:
            result = {
                ...currentColorReferenceByName,
                [index]: {
                    name: _value.target.name,
                    value: COLOR_LEVEL_3,
                },
            };
            break;
        // case THRESHOLD_LEVEL4_NAME:
        //     result = {
        //         ...currentColorReferenceByName,
        //         [index]: {
        //             name: _value.target.name,
        //             value: COLOR_LEVEL_4,
        //         },
        //     };
        //     break;
        // case THRESHOLD_LEVEL5_NAME:
        //     result = {
        //         ...currentColorReferenceByName,
        //         [index]: {
        //             name: _value.target.name,
        //             value: COLOR_LEVEL_5,
        //         },
        //     };
        //     break;
        default:
            result = {
                ...currentColorReferenceByName,
                [index]: {
                    name: '',
                    value: '',
                },
            };
            break;
    }
    return result;
};

export const getNameByLevel = (id: string): string => {
    let result: string = '';
    switch (id) {
        case LEVEL_1:
            result = THRESHOLD_LEVEL1_NAME;
            break;
        case LEVEL_2:
            result = THRESHOLD_LEVEL2_NAME;
            break;
        case LEVEL_3:
            result = THRESHOLD_LEVEL3_NAME;
            break;
        // case LEVEL_4:
        //     result = THRESHOLD_LEVEL4_NAME;
        //     break;
        // case LEVEL_5:
        //     result = THRESHOLD_LEVEL5_NAME;
        //     break;
    }
    return result;
};

export const getLevelByName = (name: string) => {
    let result: string = '';
    switch (name) {
        case THRESHOLD_LEVEL1_NAME:
            result = LEVEL_1;
            break;
        case THRESHOLD_LEVEL2_NAME:
            result = LEVEL_2;
            break;
        case THRESHOLD_LEVEL3_NAME:
            result = LEVEL_3;
            break;
        // case THRESHOLD_LEVEL4_NAME:
        //     result = LEVEL_4;
        //     break;
        // case THRESHOLD_LEVEL5_NAME:
        //     result = LEVEL_5;
        //     break;
    }
    return result;
};

export const getNameTranslated = (name: string): string => {
    let result: string = '';
    switch (name) {
        case THRESHOLD_LEVEL1_NAME:
            result = 'app.ancs.level1';
            break;
        case THRESHOLD_LEVEL2_NAME:
            result = 'app.ancs.level2';
            break;
        case THRESHOLD_LEVEL3_NAME:
            result = 'app.ancs.level3';
            break;
        // case THRESHOLD_LEVEL4_NAME:
        //     result = 'app.ancs.level4';
        //     break;
        // case THRESHOLD_LEVEL5_NAME:
        //     result = 'app.ancs.level5';
        //     break;
    }
    return result;
};
