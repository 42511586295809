import { Node } from '../types';

export const getNodes = (startNode: Node) => {
    let nodesTemp = [];
    let result = [];
    const visited = {};

    if (
        startNode.hasOwnProperty('isStartNodeBridgeToStartProcess') &&
        // @ts-ignore
        startNode.isStartNodeBridgeToStartProcess === true
    ) {
        startNode =
            Array.isArray(startNode.nextNodes) && startNode.nextNodes.length > 0 ? startNode.nextNodes[0] : null;
    }

    if (startNode && startNode.hasOwnProperty('nextNodes')) {
        result.push(startNode);
        nodesTemp.push(startNode);
    }

    while (nodesTemp.length > 0) {
        // @ts-ignore
        let node: Node = nodesTemp.shift();
        if (node.configuration.idWhenNodeWasAsAnArrayInRunnerTemplate in visited) continue;
        // @ts-ignore
        visited[node.configuration.idWhenNodeWasAsAnArrayInRunnerTemplate] = true;

        // @ts-ignore
        let nextNodes = node.nextNodes && node.nextNodes.length > 0 ? node.nextNodes : null;

        if (nextNodes) {
            nextNodes.forEach((nextN: Node) => {
                nodesTemp.push(nextN);
                result.push(nextN);
            });
        }
    }
    return result;
};
