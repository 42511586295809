import { CircularProgress, makeStyles } from '@material-ui/core';
import { useLoading } from 'react-admin';
import PlatformAppBar from '../../header/appBar';
import Grid from '@material-ui/core/Grid';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { State } from '../../../redux/reducers';

const useStyles = makeStyles(() => ({
    wrapper: {
        // Avoid IE bug with Flexbox, see #467
        display: 'flex',
        flexDirection: 'column',
    },
    main: {
        position: 'relative',
        WebkitBoxSizing: 'border-box',
        MozBoxSizing: 'border-box',
        boxSizing: 'border-box',
        padding: '94px 24px 0 24px', //for place the client company icon
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: '#f1f1f1',
    },
    mainWithoutAppbar: {
        position: 'relative',
        WebkitBoxSizing: 'border-box',
        MozBoxSizing: 'border-box',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: '#f1f1f1',
    },
    body: {
        position: 'relative',
        WebkitBoxSizing: 'border-box',
        MozBoxSizing: 'border-box',
        boxSizing: 'border-box',
        display: 'flex',
        flex: '1 1 auto',
        //paddingLeft: "249px", //left sidebar width and padding left
        /*overflowY: 'hidden',
        overflowX: 'hidden',*/
    },
    content: {
        position: 'relative',
        flex: 1,
        padding: '0',
        overflowX: 'hidden',
    },
    loader: {
        position: 'fixed',
        top: '2px',
        right: '0',
        margin: 16,
        marginRight: '3px',
        zIndex: 1200,
    },
    leftContainer: {
        position: 'relative',
        top: '0',
        left: '0',
        width: '220px',
        height: '85vh',
        marginRight: '5px',
    },
    leftSidebar: {
        position: 'fixed',
        top: '100',
        left: '0',
        width: '220px',
        marginRight: '5px',
        marginLeft: '24px',

        height: '85vh',
    },
    drawerSideContainer: {
        position: 'absolute',
    },
    divBackButton: {
        backgroundColor: '#424242',
    },
    backArrow: {
        color: 'white',
    },
}));
const Layout: FC = (props) => {
    const classes = useStyles();
    const loading = useLoading();

    const roles = useSelector((state: { rikutec: State }) => state.rikutec.users.tokenData?.roles);

    return (
        <div className={classes.wrapper}>
            <div className={classes.main}>
                <PlatformAppBar permissions={roles ? Object.values(roles) : []} />
                <Grid container>
                    <Grid item xs zeroMinWidth>
                        {props.children}
                    </Grid>
                </Grid>
                {loading && (
                    <CircularProgress style={{ color: 'white' }} size={30} thickness={2} className={classes.loader} />
                )}
            </div>
        </div>
    );
};

export default Layout;
