export const REFRESH_TOKEN_ROUTE_PARAMS = 'refreshToken';
export const REDIRECT_TO_QUERY_PARAMS = 'redirectTo';

export const index = `${process.env.REACT_APP_PREFIX}c/:${REFRESH_TOKEN_ROUTE_PARAMS}`;
export const help_no_token_word = 'no-token';
export const index_no_token = `${process.env.REACT_APP_PREFIX}c/${help_no_token_word}`;
export const ancs = `${process.env.REACT_APP_PREFIX}p/ancs`;
export const ancsEvery2Hours = `${process.env.REACT_APP_PREFIX}p/ancs_every_2_hours`;
export const redirect_to_ancs = 'ancs';
export const redirect_to_ancs_every_2_hours = 'ancs_every_2_hours';
export const thresholds = `${process.env.REACT_APP_PREFIX}p/thresholds`;
export const redirect_to_thresholds = 'thresholds';
export const types = `${process.env.REACT_APP_PREFIX}p/types`;
export const redirect_to_types = 'types';
export const product_types = `${process.env.REACT_APP_PREFIX}p/product_types`;
export const redirect_to_product_types = 'product_types';
