import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(() => ({
    fileWrapper: {
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'center',
        marginBottom:'15px'
    },
    fileInput: {
        width: '100%',
    },
    filePreview: {
        marginTop: '15px',
        width: '100%'
    },
    circularProgress: {
        width: '16px !important',
        height: '16px !important',
        marginRight: '7px'
    },
    buttonsContainer: {
        marginTop: '20px',
        textAlign: 'right'
    },
    formControl: {
        margin: '0 0 15px',
        width: '100%'
    },
    buttons: {
        '&:first-child': {
            marginRight: '7px'
        }
    },
    helperMessage: {
        fontWeight: 'bold',
        marginBottom: '5px'
    }
}))

export default useStyles