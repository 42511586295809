import React from 'react';
import { DateInput, RadioButtonGroupInput, useTranslate } from 'react-admin';
import { MAINTENANCE, SLUDGE_EMPTYING } from '../../../utils/rikutec';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    gridPaddingTopInherit: {
        paddingTop: 'inherit !important',
    },
}));
const PeriodInput = (props: { source: string; label: string }) => {
    const translate = useTranslate();
    const classes = useStyles();

    return (
        <Grid container spacing={2} alignItems={'center'}>
            <Grid item className={classes.gridPaddingTopInherit}>
                <DateInput
                    size={'medium'}
                    {...props}
                    label={translate('app.common.after')}
                    variant={'standard'}
                    // source={`${props.source}[createdAt_after]`}
                    // source={`${props.source}%5BcreatedAt_after%5D`}
                    source={`${props.source}__createdAt_after`}
                />
            </Grid>

            <Grid item className={classes.gridPaddingTopInherit}>
                <DateInput
                    size={'medium'}
                    {...props}
                    variant={'standard'}
                    label={translate('app.common.before')}
                    // source={`${props.source}%5BcreatedAt_before%5D`}
                    source={`${props.source}__createdAt_before`}
                />
            </Grid>

            <Grid item>
                <RadioButtonGroupInput
                    size={'medium'}
                    helperText={false}
                    label={''}
                    // source={`${props.source}%5Btags%5D`}
                    source={encodeURI(`${props.source}__tags`)}
                    choices={[
                        {
                            id: SLUDGE_EMPTYING,
                            name: translate(`app.ancs.${SLUDGE_EMPTYING}`),
                        },
                        {
                            id: MAINTENANCE,
                            name: translate(`app.ancs.${MAINTENANCE}`),
                        },
                    ]}
                />
            </Grid>
        </Grid>
    );
};

export default PeriodInput;
