import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
    Typography,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { ValidationErrors } from 'final-form';
import { Identifier } from 'ra-core';
import React, { useEffect, useState } from 'react';
import {
    BooleanInput,
    Datagrid,
    FunctionField,
    List,
    Record,
    ReduxState,
    TextInput,
    useDataProvider,
    useLoading,
    useMutation,
    useNotify,
    useRefresh,
    useTranslate,
} from 'react-admin';
import { Form as RFForm } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { useGetPropertyLanguages } from '../../../../../hooks/useGetPropertyLanguages';
import {
    GROUP_METADATA_AVATAR_TYPES,
    LANGUAGES,
    METADATA_AVATAR_TYPE_PROPERTIES,
    METADATA_AVATAR_TYPES,
} from '../../../../../providers/resources';
import {
    selectGroupMetadataAvatarType,
    unselectGroupMetadataAvatarType,
} from '../../../../../redux/actions/group.metadata.avatar.type.actions';
import { CustomReducerState } from '../../../../../redux/reducers';
import {
    ENABLE_APPROVAL_PROPERTY_NAME,
    enableAppovalProperty,
    itemCodeProperty,
    typeSerialNumberProperty,
} from '../../../../../utils/metadataAvatarTypeProperty';
import { getNameWithoutPrefix } from '../../../../../utils/resources/MetadataAvatarType';
import { Company, GroupMetadataAvatarType, MetadataAvatarType } from '../../../../../utils/types';

const useStyles = makeStyles(() => ({
    dialogContentRoot: {
        overflowX: 'hidden',
        padding: '0 20px 0px 20px !important',
    },
    formControl: {
        margin: '0 0 15px',
        width: '100%',
    },
    dialogActions: {
        marginBottom: 20,
        marginTop: 15,
    },
    buttons: {
        '&:first-child': {
            marginRight: '7px',
        },
    },
    dataRow: {
        cursor: 'pointer',
    },
    circularProgress: {
        width: '16px !important',
        height: '16px !important',
        marginRight: '7px',
    },
}));
const TOTAL_LANGUAGE_LOADED = 18;
const Form = (props: { action: 'CREATE' | 'EDIT'; toggleDlg: () => void }) => {
    const t = useTranslate();
    const classes = useStyles();
    const dispatch = useDispatch();

    const selectedGMAT: GroupMetadataAvatarType | Record | null = useSelector(
        (state: ReduxState & CustomReducerState) => {
            const selectedGMATUri = (state.rikutec.groupsMetadataAvatarType.selected as GroupMetadataAvatarType)['@id'];
            return selectedGMATUri && selectedGMATUri in state.admin.resources[GROUP_METADATA_AVATAR_TYPES].data
                ? state.admin.resources[GROUP_METADATA_AVATAR_TYPES].data[selectedGMATUri]
                : null;
        }
    );
    const selectedMDAT: MetadataAvatarType | Record | null = useSelector((state: ReduxState & CustomReducerState) => {
        const selectedMDATUri = (state.rikutec.types.selected as MetadataAvatarType)['@id'];
        return selectedMDATUri && selectedMDATUri in state.admin.resources[METADATA_AVATAR_TYPES].data
            ? state.admin.resources[METADATA_AVATAR_TYPES].data[selectedMDATUri]
            : null;
    });

    const userConnectedCompany: Company | Record | undefined = useSelector(
        (state: ReduxState & CustomReducerState) => state.rikutec.users?.userConnected?.company
    );

    const [dlgIsOpen, setDlgIsOpen] = useState(false);
    const [productTypeName, setProductTypeName] = useState('');

    const [isCreatingLanguage, setIsCreatingLanguage] = useState<boolean>(false);
    const [dataToCreateMDAT, setDataToCreateMDAT] = useState<any>();

    const notify = useNotify();
    const refresh = useRefresh();
    const loading = useLoading();
    const [postMDAT] = useMutation({ type: 'create', resource: METADATA_AVATAR_TYPES });
    const [updateMDAT] = useMutation({ type: 'update', resource: METADATA_AVATAR_TYPES });
    const [postProperty] = useMutation({
        type: 'create',
        resource: METADATA_AVATAR_TYPE_PROPERTIES,
    });
    const [updateProperty] = useMutation({
        type: 'update',
        resource: METADATA_AVATAR_TYPE_PROPERTIES,
    });
    const [postLanguage] = useMutation({ type: 'create', resource: LANGUAGES });
    const [languages, setLanguages] = useGetPropertyLanguages();
    const dataProvider = useDataProvider();
    const [properties, setProperties] = useState<any[]>([]);
    const [loadingFormState, setLoadingFormState] = useState(false);

    const validationRules = (values: any): ValidationErrors => {
        const errors: ValidationErrors = {};

        (!values.hasOwnProperty('name') || values.name === '') &&
            Object.assign(errors, { name: t('app.validation.required') });

        (!values.hasOwnProperty('productType') || values.productType === '') &&
            Object.assign(errors, { productType: t('app.validation.required') });

        (!values.hasOwnProperty('processingCapacity') || values.processingCapacity === '') &&
            Object.assign(errors, { processingCapacity: t('app.validation.required') });

        (!values.hasOwnProperty('sludgeStorageVolume') || values.sludgeStorageVolume === '') &&
            Object.assign(errors, { sludgeStorageVolume: t('app.validation.required') });

        // (!values.hasOwnProperty('totalUsefulVolume') || values.totalUsefulVolume === '') &&
        //     Object.assign(errors, { totalUsefulVolume: t('app.validation.required') });

        (!values.hasOwnProperty('model') || values.model === '') &&
            Object.assign(errors, { model: t('app.validation.required') });

        (!values.hasOwnProperty('productCode') || values['productCode'] === '') &&
            Object.assign(errors, { productCode: t('app.validation.required') });

        (!values.hasOwnProperty('typeSerialNumber') || values['typeSerialNumber'] === '') &&
            Object.assign(errors, { typeSerialNumber: t('app.validation.required') });

        return errors;
    };
    const rowStyle = (record: Record) => ({
        cursor: 'pointer',
        backgroundColor: selectedGMAT && selectedGMAT.id === record.id ? 'lightgrey' : 'unset',
    });
    const rowClick = (id: Identifier, basePath: string, record: Record): string => {
        dispatch(selectGroupMetadataAvatarType(record));
        return '';
    };
    const toggleDlg = () => setDlgIsOpen(!dlgIsOpen);
    const closeForm = () => {
        if (!loading) {
            refresh();
            props.toggleDlg();
        }
    };
    const saveProperty = (
        propertyName: string,
        propertyValue: string,
        propertyUnit: string,
        metadataAvatarTypeId: string,
        fieldType?: string
    ) => {
        const property = properties.find((item) => item.name === propertyName);
        if (property) {
            property.values[0] !== propertyValue &&
                updateProperty(
                    {
                        payload: {
                            id: property['@id'],
                            data: {
                                name: propertyName,
                                values: [propertyValue],
                                unit: propertyUnit,
                                private: false,
                                fieldType: fieldType ? fieldType : 'string',
                                option: 'pre_filled',
                                metadataAvatarType: metadataAvatarTypeId,
                                languages: Object.entries(languages)
                                    .filter((item) => item[0] === propertyName)
                                    .map((item) => Object.values(item[1]).map((token) => token))[0],
                            },
                        },
                    },
                    {
                        onSuccess: () => closeForm(),
                        onFailure: () => {
                            setLoadingFormState(false);
                            notify(t('app.errors.updatingProperty'), 'error');
                        },
                    }
                );
        } else {
            postProperty(
                {
                    payload: {
                        data: {
                            name: propertyName,
                            values: [propertyValue],
                            unit: propertyUnit,
                            private: false,
                            fieldType: fieldType ? fieldType : 'string',
                            option: 'pre_filled',
                            metadataAvatarType: metadataAvatarTypeId,
                            languages: Object.entries(languages)
                                .filter((item) => item[0] === propertyName)
                                .map((item) => Object.values(item[1]).map((token) => token))[0],
                        },
                    },
                },
                {
                    onSuccess: () => closeForm(),
                    onFailure: () => {
                        setLoadingFormState(false);
                        notify(t('app.errors.creatingProperty'), 'error');
                    },
                }
            );
        }
    };
    const saveMDAT = (data: any, typeId: string = '') => {
        if (typeId !== '' && selectedGMAT) {
            updateMDAT(
                {
                    payload: {
                        id: typeId,
                        data: {
                            ...selectedMDAT,
                            groupMetadataAvatarType: (selectedGMAT as GroupMetadataAvatarType)['@id'],
                            name: data.name,
                        },
                    },
                },
                {
                    onSuccess: () => {
                        notify(t('app.types.messages.success'), 'success');
                        closeForm();
                    },
                    onFailure: () => {
                        setLoadingFormState(false);
                        notify(t('app.errors.updatingType'), 'error');
                    },
                }
            );
        } else {
            if (!selectedGMAT) {
                console.error('group metadataavatar type not selected');
                return;
            }
            postMDAT(
                {
                    payload: {
                        data: {
                            fingers: [process.env.REACT_APP_FINGER_ID],
                            quantityMCs: 1,
                            groupMetadataAvatarType: (selectedGMAT as GroupMetadataAvatarType)['@id'],
                            name: data.name,
                            alphaId: uuidv4(),
                        },
                    },
                },
                {
                    onSuccess: (response) => {
                        saveProperty('processingCapacity', data.processingCapacity, 'EH', response.data['@id']);
                        saveProperty('sludgeStorageVolume', data.sludgeStorageVolume, 'L', response.data['@id']);
                        saveProperty('model', data.model, '', response.data['@id']);
                        saveProperty('productCode', data.productCode, '', response.data['@id']);
                        saveProperty('typeserialnumber', data.typeSerialNumber, '', response.data['@id']);
                        saveProperty(
                            ENABLE_APPROVAL_PROPERTY_NAME,
                            data.enableApproval,
                            '',
                            response.data['@id'],
                            'boolean'
                        );
                        dispatch(unselectGroupMetadataAvatarType());
                    },
                    onFailure: () => {
                        setLoadingFormState(false);
                        notify(t('app.errors.creatingType'), 'error');
                    },
                }
            );
        }
    };
    const applyGMAT = () => {
        setProductTypeName(getNameWithoutPrefix((selectedGMAT as GroupMetadataAvatarType).name));
        toggleDlg();
    };
    const cancelGMAT = () => {
        toggleDlg();
    };
    const createMDAT = async (data: any) => {
        setIsCreatingLanguage(true);

        // Processing capacity
        if (languages.processingCapacity.en === '') {
            postLanguage(
                {
                    payload: {
                        data: {
                            twoLetters: 'en',
                            value: 'Processing capacity',
                        },
                    },
                },
                {
                    onSuccess: (response) => {
                        setLanguages((prevState) => ({
                            ...prevState,
                            processingCapacity: {
                                ...prevState.processingCapacity,
                                en: response.data['@id'],
                            },
                        }));
                    },
                    onFailure: () => {
                        setLoadingFormState(false);
                        notify(t('app.types.messages.error'), 'error');
                    },
                }
            );
        }
        if (languages.processingCapacity.es === '') {
            postLanguage(
                {
                    payload: {
                        data: {
                            twoLetters: 'es',
                            value: 'Capacidad de procesamiento',
                        },
                    },
                },
                {
                    onSuccess: (response) =>
                        setLanguages((prevState) => ({
                            ...prevState,
                            processingCapacity: {
                                ...prevState.processingCapacity,
                                es: response.data['@id'],
                            },
                        })),
                    onFailure: () => {
                        setLoadingFormState(false);
                        notify(t('app.types.messages.error'), 'error');
                    },
                }
            );
        }
        if (languages.processingCapacity.fr === '') {
            postLanguage(
                { payload: { data: { twoLetters: 'fr', value: 'Capacité de traitement' } } },
                {
                    onSuccess: (response) =>
                        setLanguages((prevState) => ({
                            ...prevState,
                            processingCapacity: {
                                ...prevState.processingCapacity,
                                fr: response.data['@id'],
                            },
                        })),
                    onFailure: () => {
                        setLoadingFormState(false);
                        notify(t('app.types.messages.error'), 'error');
                    },
                }
            );
        }

        // Sludge Storage Volume
        if (languages.sludgeStorageVolume.en === '') {
            postLanguage(
                {
                    payload: {
                        data: {
                            twoLetters: 'en',
                            value: 'Sludge Storage Volume',
                        },
                    },
                },
                {
                    onSuccess: (response) =>
                        setLanguages((prevState) => ({
                            ...prevState,
                            sludgeStorageVolume: {
                                ...prevState.sludgeStorageVolume,
                                en: response.data['@id'],
                            },
                        })),
                    onFailure: () => {
                        setLoadingFormState(false);
                        notify(t('app.types.messages.error'), 'error');
                    },
                }
            );
        }
        if (languages.sludgeStorageVolume.es === '') {
            postLanguage(
                {
                    payload: {
                        data: {
                            twoLetters: 'es',
                            value: 'Volumen dedicado al almacenamiento de lodos',
                        },
                    },
                },
                {
                    onSuccess: (response) =>
                        setLanguages((prevState) => ({
                            ...prevState,
                            sludgeStorageVolume: {
                                ...prevState.sludgeStorageVolume,
                                es: response.data['@id'],
                            },
                        })),
                    onFailure: () => {
                        setLoadingFormState(false);
                        notify(t('app.types.messages.error'), 'error');
                    },
                }
            );
        }
        if (languages.sludgeStorageVolume.fr === '') {
            postLanguage(
                {
                    payload: {
                        data: {
                            twoLetters: 'fr',
                            value: 'Volume dédié au stockage des boues',
                        },
                    },
                },
                {
                    onSuccess: (response) =>
                        setLanguages((prevState) => ({
                            ...prevState,
                            sludgeStorageVolume: {
                                ...prevState.sludgeStorageVolume,
                                fr: response.data['@id'],
                            },
                        })),
                    onFailure: () => {
                        setLoadingFormState(false);
                        notify(t('app.types.messages.error'), 'error');
                    },
                }
            );
        }

        // Model
        if (languages.model.en === '') {
            postLanguage(
                {
                    payload: {
                        data: {
                            twoLetters: 'en',
                            value: 'Model',
                        },
                    },
                },
                {
                    onSuccess: (response) =>
                        setLanguages((prevState) => ({
                            ...prevState,
                            model: { ...prevState.model, en: response.data['@id'] },
                        })),
                    onFailure: () => {
                        setLoadingFormState(false);
                        notify(t('app.types.messages.error'), 'error');
                    },
                }
            );
        }
        if (languages.model.es === '') {
            postLanguage(
                {
                    payload: {
                        data: {
                            twoLetters: 'es',
                            value: 'Modelo',
                        },
                    },
                },
                {
                    onSuccess: (response) =>
                        setLanguages((prevState) => ({
                            ...prevState,
                            model: {
                                ...prevState.model,
                                es: response.data['@id'],
                            },
                        })),
                    onFailure: () => {
                        setLoadingFormState(false);
                        notify(t('app.types.messages.error'), 'error');
                    },
                }
            );
        }
        if (languages.model.fr === '') {
            postLanguage(
                {
                    payload: {
                        data: {
                            twoLetters: 'fr',
                            value: 'Modèle',
                        },
                    },
                },
                {
                    onSuccess: (response) => {
                        setLanguages((prevState) => ({
                            ...prevState,
                            model: { ...prevState.model, fr: response.data['@id'] },
                        }));
                    },
                    onFailure: () => {
                        setLoadingFormState(false);
                        notify(t('app.types.messages.error'), 'error');
                    },
                }
            );
        }

        // Item code
        if (languages.productCode.en === '') {
            postLanguage(
                {
                    payload: {
                        data: {
                            twoLetters: 'en',
                            value: itemCodeProperty.en,
                        },
                    },
                },
                {
                    onSuccess: (response) =>
                        setLanguages((prevState) => ({
                            ...prevState,
                            productCode: { ...prevState.productCode, en: response.data['@id'] },
                        })),
                    onFailure: () => {
                        setLoadingFormState(false);
                        notify(t('app.types.messages.error'), 'error');
                    },
                }
            );
        }
        if (languages.productCode.es === '') {
            postLanguage(
                {
                    payload: {
                        data: {
                            twoLetters: 'es',
                            value: itemCodeProperty.es,
                        },
                    },
                },
                {
                    onSuccess: (response) =>
                        setLanguages((prevState) => ({
                            ...prevState,
                            productCode: {
                                ...prevState.productCode,
                                es: response.data['@id'],
                            },
                        })),
                    onFailure: () => {
                        setLoadingFormState(false);
                        notify(t('app.types.messages.error'), 'error');
                    },
                }
            );
        }
        if (languages.productCode.fr === '') {
            postLanguage(
                {
                    payload: {
                        data: {
                            twoLetters: 'fr',
                            value: itemCodeProperty.fr,
                        },
                    },
                },
                {
                    onSuccess: (response) => {
                        setLanguages((prevState) => ({
                            ...prevState,
                            productCode: { ...prevState.productCode, fr: response.data['@id'] },
                        }));
                    },
                    onFailure: () => {
                        setLoadingFormState(false);
                        notify(t('app.types.messages.error'), 'error');
                    },
                }
            );
        }

        //TYPE SERIAL NUMBER
        if (languages.typeserialnumber.en === '') {
            postLanguage(
                {
                    payload: {
                        data: {
                            twoLetters: 'en',
                            value: typeSerialNumberProperty.en,
                        },
                    },
                },
                {
                    onSuccess: (response) =>
                        setLanguages((prevState) => ({
                            ...prevState,
                            typeserialnumber: { ...prevState.typeserialnumber, en: response.data['@id'] },
                        })),
                    onFailure: () => {
                        setLoadingFormState(false);
                        notify(t('app.types.messages.error'), 'error');
                    },
                }
            );
        }
        if (languages.typeserialnumber.es === '') {
            postLanguage(
                {
                    payload: {
                        data: {
                            twoLetters: 'es',
                            value: typeSerialNumberProperty.es,
                        },
                    },
                },
                {
                    onSuccess: (response) =>
                        setLanguages((prevState) => ({
                            ...prevState,
                            typeserialnumber: {
                                ...prevState.typeserialnumber,
                                es: response.data['@id'],
                            },
                        })),
                    onFailure: () => {
                        setLoadingFormState(false);
                        notify(t('app.types.messages.error'), 'error');
                    },
                }
            );
        }
        if (languages.typeserialnumber.fr === '') {
            postLanguage(
                {
                    payload: {
                        data: {
                            twoLetters: 'fr',
                            value: typeSerialNumberProperty.fr,
                        },
                    },
                },
                {
                    onSuccess: (response) => {
                        setLanguages((prevState) => ({
                            ...prevState,
                            typeserialnumber: { ...prevState.typeserialnumber, fr: response.data['@id'] },
                        }));
                    },
                    onFailure: () => {
                        setLoadingFormState(false);
                        notify(t('app.types.messages.error'), 'error');
                    },
                }
            );
        }

        //ENABLE APPROVAL
        if (languages.enableApproval.en === '') {
            postLanguage(
                {
                    payload: {
                        data: {
                            twoLetters: 'en',
                            value: enableAppovalProperty.en,
                        },
                    },
                },
                {
                    onSuccess: (response) =>
                        setLanguages((prevState) => ({
                            ...prevState,
                            enableApproval: { ...prevState.enableApproval, en: response.data['@id'] },
                        })),
                    onFailure: () => {
                        setLoadingFormState(false);
                        notify(t('app.types.messages.error'), 'error');
                    },
                }
            );
        }
        if (languages.enableApproval.es === '') {
            postLanguage(
                {
                    payload: {
                        data: {
                            twoLetters: 'es',
                            value: enableAppovalProperty.es,
                        },
                    },
                },
                {
                    onSuccess: (response) =>
                        setLanguages((prevState) => ({
                            ...prevState,
                            enableApproval: {
                                ...prevState.enableApproval,
                                es: response.data['@id'],
                            },
                        })),
                    onFailure: () => {
                        setLoadingFormState(false);
                        notify(t('app.types.messages.error'), 'error');
                    },
                }
            );
        }
        if (languages.enableApproval.fr === '') {
            postLanguage(
                {
                    payload: {
                        data: {
                            twoLetters: 'fr',
                            value: enableAppovalProperty.fr,
                        },
                    },
                },
                {
                    onSuccess: (response) => {
                        setLanguages((prevState) => ({
                            ...prevState,
                            enableApproval: { ...prevState.enableApproval, fr: response.data['@id'] },
                        }));
                    },
                    onFailure: () => {
                        setLoadingFormState(false);
                        notify(t('app.types.messages.error'), 'error');
                    },
                }
            );
        }

        setDataToCreateMDAT(data);
    };
    const editMDAT = (data: any) => {
        saveProperty('processingCapacity', data.processingCapacity, 'EH', (selectedMDAT as MetadataAvatarType)['@id']);
        saveProperty('sludgeStorageVolume', data.sludgeStorageVolume, 'L', (selectedMDAT as MetadataAvatarType)['@id']);
        // saveProperty('totalUsefulVolume', data.totalUsefulVolume, 'L', (selectedMDAT as MetadataAvatarType)['@id']);
        saveProperty('model', data.model, '', (selectedMDAT as MetadataAvatarType)['@id']);
        saveProperty('productCode', data.productCode, '', (selectedMDAT as MetadataAvatarType)['@id']);
        saveProperty(
            ENABLE_APPROVAL_PROPERTY_NAME,
            data.enableApproval,
            '',
            (selectedMDAT as MetadataAvatarType)['@id'],
            'boolean'
        );

        // @ts-ignore
        saveMDAT(data, (selectedMDAT as MetadataAvatarType).id);
    };
    const handleSubmit = (data: any) => {
        setLoadingFormState(true);

        if (selectedMDAT && (selectedMDAT as MetadataAvatarType).id && props.action === 'EDIT') {
            editMDAT(data);
        } else {
            createMDAT(data).finally();
        }
    };

    const dismissOperation = () => {
        dispatch(unselectGroupMetadataAvatarType());
        props.toggleDlg();
    };

    const getOldValues = () => {
        if (props.action === 'EDIT' && (selectedMDAT as MetadataAvatarType).id) {
            dataProvider
                .getList(
                    METADATA_AVATAR_TYPE_PROPERTIES,
                    {
                        filter: { metadataAvatarType: (selectedMDAT as MetadataAvatarType).id },
                        pagination: { page: 1, perPage: 10 },
                        sort: { order: '', field: '' },
                    },
                    {
                        onSuccess: (response) => {
                            setProperties(response.data);
                        },
                        onFailure: () => {
                            setLoadingFormState(false);
                            notify(t('app.types.messages.error'), 'error');
                        },
                    }
                )
                .finally();
            dataProvider
                .getOne(
                    GROUP_METADATA_AVATAR_TYPES,
                    { id: (selectedMDAT as MetadataAvatarType).groupMetadataAvatarType },
                    {
                        onSuccess: (response) => {
                            setProductTypeName(getNameWithoutPrefix(response.data.name));
                            dispatch(selectGroupMetadataAvatarType(response.data));
                        },
                        onFailure: () => {
                            setLoadingFormState(false);
                            notify(t('app.types.messages.error'), 'error');
                        },
                    }
                )
                .finally();
        }
    };
    //eslint-disable-next-line
    useEffect(getOldValues, []);

    useEffect(() => {
        if (isCreatingLanguage && dataToCreateMDAT) {
            let languagesLoaded = 0;
            Object.keys(languages).forEach((varLanguageKey: string) => {
                // @ts-ignore
                Object.keys(languages[varLanguageKey]).forEach((languageKey: string) => {
                    // @ts-ignore
                    if (languages[varLanguageKey][languageKey] !== '') languagesLoaded += 1;
                });
            });

            if (languagesLoaded === TOTAL_LANGUAGE_LOADED) {
                setIsCreatingLanguage(false);
                saveMDAT({ ...dataToCreateMDAT });
                setDataToCreateMDAT(null);
            }
        }
        //eslint-disable-next-line
    }, [isCreatingLanguage, languages, dataToCreateMDAT]);

    return (
        <RFForm
            keepDirtyOnReinitialize={true}
            initialValues={{
                name:
                    (props.action === 'EDIT' && getNameWithoutPrefix((selectedMDAT as MetadataAvatarType).name)) || '',
                productType: productTypeName,
                processingCapacity:
                    (properties.length > 0 &&
                        properties.find((item) => item.name === 'processingCapacity')?.values[0]) ||
                    '',
                sludgeStorageVolume:
                    (properties.length > 0 &&
                        properties.find((item) => item.name === 'sludgeStorageVolume')?.values[0]) ||
                    '',
                // totalUsefulVolume:
                //     (properties.length > 0 &&
                //         properties.find((item) => item.name === 'totalUsefulVolume')?.values[0]) ||
                //     '',
                model: (properties.length > 0 && properties.find((item) => item.name === 'model')?.values[0]) || '',
                productCode:
                    (properties.length > 0 && properties.find((item) => item.name === 'productCode')?.values[0]) || '',
                typeSerialNumber:
                    (properties.length > 0 && properties.find((item) => item.name === 'typeserialnumber')?.values[0]) ||
                    '',
                enableApproval:
                    (properties.length > 0 && properties.find((item) => item.name === 'enableApproval')?.values[0]) ||
                    '',
            }}
            onSubmit={handleSubmit}
            validate={(values) => validationRules(values)}
            render={({ handleSubmit: onSubmit, invalid }) => (
                <form onSubmit={onSubmit}>
                    <DialogContent className={classes.dialogContentRoot} data-testid='type-form'>
                        <TextInput
                            source='name'
                            variant='standard'
                            type='text'
                            fullWidth
                            label={t('app.types.name')}
                            helperText={false}
                            className={classes.formControl}
                            disabled={loading}
                            required
                            data-testid='type-input-name'
                        />
                        <TextInput
                            source='productType'
                            variant='standard'
                            type='text'
                            value={productTypeName}
                            fullWidth
                            label={t('app.types.productType')}
                            helperText={false}
                            className={classes.formControl}
                            onClick={() => setDlgIsOpen(true)}
                            disabled={loading}
                            required
                            data-testid='type-input-product-type'
                        />
                        <TextInput
                            source={'productCode'}
                            variant='standard'
                            type='text'
                            fullWidth
                            label={t('app.types.itemCode')}
                            helperText={false}
                            className={classes.formControl}
                            disabled={loading}
                            required
                            data-testid='type-input-itemCode'
                        />
                        <TextInput
                            source='processingCapacity'
                            variant='standard'
                            type='text'
                            fullWidth
                            label={t('app.types.processingCapacity')}
                            helperText={false}
                            className={classes.formControl}
                            disabled={loading}
                            required
                            data-testid='type-input-processing-capacity'
                        />
                        <TextInput
                            source='sludgeStorageVolume'
                            variant='standard'
                            type='text'
                            fullWidth
                            label={t('app.types.sludgeStorageVolume')}
                            helperText={false}
                            className={classes.formControl}
                            disabled={loading}
                            required
                            data-testid='type-input-sludge-volume'
                        />
                        <TextInput
                            source='typeSerialNumber'
                            variant='standard'
                            type='text'
                            fullWidth
                            label={t('app.types.typeSerialNumber')}
                            helperText={false}
                            className={classes.formControl}
                            disabled={loading}
                            required
                            data-testid='type-input-type-serial-number'
                        />
                        <TextInput
                            source='model'
                            variant='standard'
                            type='text'
                            fullWidth
                            label={t('app.types.model')}
                            helperText={false}
                            className={classes.formControl}
                            disabled={loading}
                            required
                            data-testid='type-input-model'
                        />
                        <BooleanInput
                            className={classes.formControl}
                            disabled={loading}
                            label={t('app.types.enableApproval')}
                            source='enableApproval'
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            type='reset'
                            variant='text'
                            color='primary'
                            className={classes.buttons}
                            disabled={loading}
                            onClick={dismissOperation}
                        >
                            {t('app.button.cancel')}
                        </Button>
                        <Button
                            type='submit'
                            variant='contained'
                            color='primary'
                            disabled={loadingFormState || invalid}
                            className={classes.buttons}
                        >
                            {loading && <CircularProgress className={classes.circularProgress} color='primary' />}
                            {t('app.button.confirm')}
                        </Button>
                    </DialogActions>
                    <Dialog open={dlgIsOpen} onClose={() => setDlgIsOpen(false)} fullWidth maxWidth='sm'>
                        <DialogTitle>
                            <Typography color='primary'>{t('app.headers.selectAvatarTypeGroup')}</Typography>
                        </DialogTitle>
                        <DialogContent>
                            <List
                                resource={GROUP_METADATA_AVATAR_TYPES}
                                basePath=''
                                actions={false}
                                filter={{ wcCompanyId: userConnectedCompany?.id }}
                                bulkActionButtons={false}
                            >
                                <Datagrid rowStyle={rowStyle} rowClick={rowClick}>
                                    <FunctionField
                                        source='name'
                                        render={(record: Record | undefined) => getNameWithoutPrefix(record?.name)}
                                    />
                                </Datagrid>
                            </List>
                        </DialogContent>
                        <DialogActions>
                            <Button variant='text' color='primary' onClick={cancelGMAT}>
                                {t('app.button.cancel')}
                            </Button>
                            <Button variant='contained' color='primary' onClick={applyGMAT}>
                                {t('app.button.confirm')}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </form>
            )}
        />
    );
};

export default Form;
