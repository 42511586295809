import { FileSystemMapper, MetadataAvatarType } from '../../utils/types';
import { MEDIA_TAGS } from '../../providers/resources';

export const SELECT_TYPE = 'select_type';
export const UNSELECT_TYPE = 'unselect_type';
export const ADD_TO_TYPE_DATA = 'add_to_type_data';

export interface TypesState {
    data: any;
    selected: any | null;
}

export interface TypesActions {
    type: typeof SELECT_TYPE | typeof UNSELECT_TYPE | typeof ADD_TO_TYPE_DATA;
    payload: any;
}

export const selectType = (data: any): TypesActions => ({
    type: SELECT_TYPE,
    payload: data,
});

export const addToMetadataAvatarTypeData = (
    item: MetadataAvatarType,
    tag: MEDIA_TAGS,
    fsm: FileSystemMapper
): TypesActions => ({
    type: ADD_TO_TYPE_DATA,
    payload: {
        item,
        tag,
        fsm,
    },
});
